import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  onSaveAndContinueConfirm(): void;
  onSaveAndReturnHomeConfirm(): void;
}
export const SaveActivityProgressConfirmationModal: FC<Props> = ({
  onClose,
  onSaveAndContinueConfirm,
  onSaveAndReturnHomeConfirm,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Save Progress</div>
    <div>Select an option below.</div>
    <ButtonGroup className="mt-9 border border-white w-3/5">
      <button className="text-white" onClick={onSaveAndReturnHomeConfirm}>
        Save and Return Home
      </button>
      <button
        className="bg-white text-blue-400"
        onClick={onSaveAndContinueConfirm}
      >
        Save and Continue Entry
      </button>
    </ButtonGroup>
  </CloseableCard>
);
