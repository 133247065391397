import React, { useCallback, useState, useEffect, PropsWithChildren } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import {
  DropdownOption,
  DropdownOptionItemProps,
  DropdownProps,
} from 'types/Dropdown';
import { Row } from 'components/Row/Row';
import { CheckboxRadioInput } from 'components/CheckboxRadioInput/CheckboxRadioInput';

export interface DropdownCheckboxInputProps<T = string | number>
  extends Pick<
  DropdownProps<T>,
    | 'label'
    | 'className'
    | 'readOnly'
    | 'readOnlyDisabled'
    | 'placeholder'
    | 'options'
  > {
  selected?: T[];
  onChange(optionIds: T[]): void;
}

export const DropdownCheckboxInput = <T extends unknown = string | number>({
  options,
  selected = [],
  onChange,
  ...rest
}: PropsWithChildren<DropdownCheckboxInputProps<T>>) => {
  const [disableClickOutside, setDisableClickOutside] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOptionSelection = useCallback((toggledOptionId: T) => {
    setDisableClickOutside(true);
    if (selected.length) {
      selected.includes(toggledOptionId)
        ? onChange(selected.filter((id) => id !== toggledOptionId))
        : onChange([...selected, toggledOptionId]);
    } else {
      onChange([toggledOptionId]);
    }
  }, [onChange, selected]);

  const handleDropdownOpen = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  useEffect(() => {
    // Enable onClickOutside whenever a selection is made
    setDisableClickOutside(false);
  }, [selected]);

  const selectedLabel = selected?.length
    ? `${selected.length} Selected`
    : undefined;

  const DropdownOptionItem = ({
    index,
    style,
    data,
  }: DropdownOptionItemProps<DropdownOption<T>>) => {
    const option = data[index];
    return (
      <Row
        style={style}
        className="dropdown-li px-4 py-2.5 font-sf-pro-medium text-sm cursor-pointer bg-white"
      >
        <CheckboxRadioInput
          label={String(option.value)}
          checked={selected?.includes(option.id)}
          onChange={() => handleOptionSelection(option.id)}
          radioSizeClassname="sm"
        />
      </Row>
    );
  };

  return (
    <Dropdown
      options={options}
      selectedLabel={selectedLabel}
      open={open}
      onOpenChange={handleDropdownOpen}
      optionItem={DropdownOptionItem}
      disableClickOutside={disableClickOutside}
      {...rest}
    />
  );
};
