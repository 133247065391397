import { useMemo } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { dateObjectToISODate } from 'shared/lib/utils/formatDate';
import { validateDateValue } from 'shared/lib/utils/validateDateFormat';

export interface Props {
  startDate: Date;
  endDate: Date;
  filterQuarterlyReport?: boolean;
  activityList: Activity[];
}

export function useActivityDateRangeFilter({
  startDate,
  endDate,
  filterQuarterlyReport,
  activityList,
}: Props): Activity[] {
  const isoStartDate = dateObjectToISODate(startDate);
  const isoEndDate = dateObjectToISODate(endDate);

  return useMemo(() => {
    return activityList.filter(
      ({ entryDate, complete, markedForQuarterlyReport }) => {
        if (filterQuarterlyReport && (!complete || !markedForQuarterlyReport)) {
          return false;
        }

        if (!validateDateValue(entryDate)) {
          return false;
        }

        // ISO dates can be compared lexically
        return entryDate >= isoStartDate && entryDate <= isoEndDate;
      },
    );
  }, [isoStartDate, isoEndDate, filterQuarterlyReport, activityList]);
}
