import React, { FC } from 'react';
import { Row } from 'components/Row/Row';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { SnackbarPositions } from 'enums/SnackbarPositions';
import { SnackbarIcon } from 'components/SnackbarIcon/SnackbarIcon';
import { xIcon } from 'assets/images';

export interface SnackbarProps {
  open?: boolean;
  severity?: SnackbarSeverityKind;
  text?: string;
  position?: SnackbarPositions;
  onClose?: () => void;
}

const snackbarBgColorMappgings = new Map<SnackbarSeverityKind, string>([
  [SnackbarSeverityKind.SUCCESS, 'bg-green'],
  [SnackbarSeverityKind.ERROR, 'bg-red-500'],
  [SnackbarSeverityKind.SPINNER, 'bg-blue-500'],
]);

const positionStyleMappings = new Map<SnackbarPositions, string>([
  [
    SnackbarPositions.TOP,
    'top-12 left-2/4 right-auto transform -translate-x-2/4',
  ],
  [
    SnackbarPositions.BOTTOM,
    'bottom-12 left-2/4 right-auto transform -translate-x-2/4',
  ],
]);

export const Snackbar: FC<SnackbarProps> = ({
  open,
  text,
  severity = SnackbarSeverityKind.SUCCESS,
  onClose,
  position = SnackbarPositions.TOP,
}) => {
  const canClose = typeof onClose === 'function';
  return (
    <div
      className={`${positionStyleMappings.get(
        position,
      )} ${snackbarBgColorMappgings.get(
        severity,
      )} fixed flex items-center max-w-md py-2 px-4 rounded text-white ${
        open
          ? 'animate-fade-in'
          : 'transition-opacity duration-300 ease-out opacity-0'
      }`}
    >
      <Row className="w-full justify-between items-center">
        <Row className="items-center">
          <SnackbarIcon icon={severity} />
          <div className="ml-3 break-words max-w-xs">{text}</div>
        </Row>
        <div
          className={`cursor-pointer pl-4 ${canClose ? '' : 'hidden'}`}
          onClick={onClose}
        >
          <img src={xIcon} alt="Close Icon" className="w-2.5 h-auto" />
        </div>
      </Row>
    </div>
  );
};
