import { Row } from 'components/Row/Row';
import React, { ComponentPropsWithoutRef, FC } from 'react';

export const NewActivityButton: FC<ComponentPropsWithoutRef<'button'>> = ({
  onClick,
}) => (
  <button
    className="rounded-tl bg-blue-400 py-8 px-6 no-print"
    onClick={onClick}
  >
    <Row className="justify-between items-center text-lg font-lato-bold uppercase text-white">
      <div className="text-2xl pr-5">+</div>
      <div>New Activity</div>
    </Row>
  </button>
);
