import { County } from 'shared/lib/types/County';
import { apiAxios } from 'api/apiAxios';

export async function getCountiesInActivityRegion(
  activityId: number,
): Promise<County[]> {
  const res = await apiAxios.get<County[]>(
    `activity/${activityId}/regionCounties`,
  );

  return res.data;
}
