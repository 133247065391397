import React, { FC } from 'react';
import { inactiveListIcon } from 'assets/images';
import { activeListIcon } from 'assets/images';
import { NavigationItem } from 'components/NavigationItem/NavigationItem';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

export const ListNavigationItem: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const matchesActivityListRoute = matchPath(location.pathname, {
    path: activityListRoute,
  });
  return (
    <NavigationItem
      imageSrc={matchesActivityListRoute ? activeListIcon : inactiveListIcon}
      imageAlt="List Icon"
      label="List"
      onClick={() => {
        history.push(activityListRoute);
      }}
    />
  );
};
