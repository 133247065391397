import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityFileIconKind } from 'enums/ActivityFileIconKind';
import {
  docIcon,
  otherFileIcon,
  mp3Icon,
  mp4Icon,
  pdfIcon,
  imageIcon,
} from 'assets/images';
import { getFileIconFromExtension } from 'utils/getFileIconFromExtension';

interface Props extends ComponentPropsWithoutRef<'div'> {
  fileExtension: string;
}

const FILE_ICON_MAP = new Map<ActivityFileIconKind, string>([
  [ActivityFileIconKind.PDF, pdfIcon],
  [ActivityFileIconKind.DOC, docIcon],
  [ActivityFileIconKind.MP3, mp3Icon],
  [ActivityFileIconKind.MP4, mp4Icon],
  [ActivityFileIconKind.OTHER, otherFileIcon],
  [ActivityFileIconKind.IMAGE, imageIcon],
]);

export const FileIcon: FC<Props> = ({ fileExtension, ...rest }) => {
  const fileIcon = getFileIconFromExtension(fileExtension);
  return (
    <div {...rest}>
      <img
        src={FILE_ICON_MAP.get(fileIcon)}
        alt={`${fileIcon} File Extension Icon`}
      />
    </div>
  );
};
