import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';
import { Button } from 'components/Button/Button';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  onPrintConfirm(): void;
}
export const PrintActivityModal: FC<Props> = ({
  onClose,
  onPrintConfirm,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Print Activity</div>
    <div>Print out this activity</div>

    <Button
      className="bg-white text-blue-400 mt-9 py-3.5 font-sf-pro-medium text-sm"
      onClick={onPrintConfirm}
    >
      Print Activity
    </Button>
  </CloseableCard>
);
