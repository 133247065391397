import React, { ComponentPropsWithoutRef, FC } from 'react';
import { meatballsBlueIcon, meatballsGrayIcon } from 'assets/images';

interface Props extends ComponentPropsWithoutRef<'button'> {
  active?: boolean;
}
export const MeatballsButton: FC<Props> = ({ active, ...rest }) => (
  <button {...rest}>
    <img
      src={active ? meatballsBlueIcon : meatballsGrayIcon}
      alt="Meatballs Icon"
    />
  </button>
);
