import React, { FC } from 'react';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { TextInput } from 'components/TextInput/TextInput';
import { ActivityAudienceTypeRadioInput } from 'components/ActivityAudienceTypeRadioInput/ActivityAudienceTypeRadioInput';
import { ActivityAudienceFormProps } from 'types/ActivityAudienceFormProps';

export const ActivityAudienceForm: FC<ActivityAudienceFormProps> = ({
  activityAudienceCategories,
  audience,
  otherAudienceType,
  onActivityAudienceChanged,
  onOtherActivityAudienceChanged,
}) => (
  <div>
    <ActivityAudienceTypeRadioInput
      selected={audience ?? []}
      onChange={onActivityAudienceChanged}
      activityAudienceCategories={activityAudienceCategories}
    />
    <ActivityFormInputLabel className="mt-12">
      Other Audience Types
    </ActivityFormInputLabel>
    <TextInput
      value={otherAudienceType ?? ''}
      onChange={(e) => onOtherActivityAudienceChanged(e.target.value)}
      placeholder="Type here..."
      containerClassName="w-3/4"
    />
  </div>
);
