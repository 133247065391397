import React, { FC, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { User } from 'shared/lib/types/User';
import {
  usersRoute,
  userCreateRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { getUsers } from 'api/user/getUsers';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import { UserManagementCardList } from 'components/UserManagementCardList/UserManagementCardList';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput';
import { useHistory } from 'react-router';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import { useSearch } from 'hooks/useSearch';
import { useNameSearch } from 'hooks/useUserNameSearch';
import { matchPath, useLocation } from 'react-router-dom';

interface Props {
  userId?: number;
}

const leftColClassName = 'pr-5 w-2/6';
const rightColClassName = 'w-4/6';

export const UsersManagementPage: FC<Props> = ({ userId, children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const { onSearchChanged, search } = useSearch();
  const filteredUsers = useNameSearch({ users, search });
  const history = useHistory();
  const location = useLocation();
  const matchesUserCreateRoute = matchPath(location.pathname, {
    path: userCreateRoute,
  });

  // Load app users
  useAsyncEffect(async (isCancelled) => {
    const users = await getUsers();
    if (!isCancelled() && users) {
      setUsers(users);
    }
  }, []);

  return (
    <Column className="h-screen">
      <Row className="w-100 justify-between mb-8 mt-6">
        <div className={leftColClassName}>
          <SearchTextInput
            onSearchChange={onSearchChanged}
            searchValue={search}
          />
        </div>
        <div className={rightColClassName}>
          <CircularActionButton
            icon={
              matchesUserCreateRoute
                ? CircularActionIconKind.X_ACTIVE
                : CircularActionIconKind.PLUS
            }
            onClick={() =>
              history.push(
                matchesUserCreateRoute ? usersRoute : userCreateRoute,
              )
            }
          />
        </div>
      </Row>
      <Row className="w-100 justify-between h-full">
        <UserManagementCardList
          activeUserId={userId}
          className={leftColClassName}
          users={filteredUsers}
        />
        <div className={rightColClassName}>{children}</div>
      </Row>
    </Column>
  );
};
