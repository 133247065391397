import { Activity } from 'shared/lib/types/Activity';
import { apiAxios } from 'api/apiAxios';

export async function getActivityFromId(
  activityId: number,
): Promise<Activity | null> {
  const res = await apiAxios.get(`/activity/${activityId}`).catch(() => {
    return null;
  });

  return res?.data ?? null;
}
