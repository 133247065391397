import React, {
  FC,
  forwardRef,
  ComponentPropsWithoutRef,
  useMemo,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import { validateDateValue } from 'shared/lib/utils/validateDateFormat';
import { DatePickerWithIcon } from 'components/DatePickerWithIcon/DatePickerWithIcon';

interface ForwardRefProps {
  value?: Date;
  onClick?(): void;
}

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  selected: Date | string;
  minDate?: Date;
  maxDate?: Date;
  onChange(date: Date): void;
  label: string;
}

export const DatePickerInput: FC<Props> = ({
  onChange,
  label,
  className,
  selected,
  minDate,
  maxDate,
}) => {
  const selectedDate =
    typeof selected === 'string'
      ? validateDateValue(selected)
        ? parseISO(selected)
        : null
      : selected;

  const CustomInput = useMemo(() => {
    return forwardRef<HTMLButtonElement, ForwardRefProps>(
      ({ value, onClick }, ref) => (
        <DatePickerWithIcon
          value={value}
          onClick={onClick}
          ref={ref}
          label={label}
        />
      ),
    );
  }, [label]);

  return (
    <div className={className}>
      <DatePicker
        onChange={onChange}
        selected={selectedDate}
        customInput={<CustomInput />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};
