import { useMemo } from 'react';
import { User } from 'shared/lib/types/User';
import { startsWithIgnoreCase } from 'shared/lib/utils/startsWithIgnoreCase';
import formatName from 'shared/lib/utils/formatName';

interface UserNameSearchParams {
  users: User[];
  search: string;
}

export function useNameSearch({ users, search }: UserNameSearchParams): User[] {
  return useMemo(() => {
    return users.filter(
      (user) =>
        startsWithIgnoreCase(user.firstName, search) ||
        startsWithIgnoreCase(user.lastName, search) ||
        startsWithIgnoreCase(formatName(user), search),
    );
  }, [users, search]);
}
