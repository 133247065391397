import React, { FC, useCallback } from 'react';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { UserManagementForm } from 'components/UserManagementForm/UserManagementForm';
import { FillableUserFormProps } from 'types/FillableUserFormProps';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { createUser } from 'api/user/createUser';
import { useHistory } from 'react-router-dom';
import { formatUserDetailRoute } from 'shared/lib/constants/routes/administratorRoutes';
import getErrorMessage from 'utils/getErrorMessage';

export const CreateUser: FC = () => {
  const { snackbar } = useSnackbarContext();
  const history = useHistory();
  const handleSubmit = useCallback(
    async (data: FillableUserFormProps) => {
      snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
      try {
        const createdUser = await createUser(data);

        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'User created successfully!',
        });
        history.push(formatUserDetailRoute(createdUser.id));
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [history, snackbar],
  );
  return (
    <UsersManagementPage>
      <PageSegmentHeading>New User</PageSegmentHeading>
      <UserManagementForm onSubmit={handleSubmit} />
    </UsersManagementPage>
  );
};
