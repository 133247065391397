import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { Row } from 'components/Row/Row';
import { ReadOnlyField } from 'components/ReadOnlyField/ReadOnlyField';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { Activity } from 'shared/lib/types/Activity';
import { formatDuration } from 'shared/lib/utils/formatDuration';
import { TechnicalAssistanceMode } from 'shared/lib/constants/activity/TechnicalAssistanceMode';
import { ListWithCounter } from 'components/ListWithCounter/ListWithCounter';
import { User } from 'shared/lib/types/User';
import formatName from 'shared/lib/utils/formatName';
import { formatUserNameAndRegion } from 'utils/formatUserNameAndRegion';
import { sortUsersByRegion } from 'utils/sortUsersByRegion';

interface Props
  extends Omit<ComponentPropsWithoutRef<'div'>, 'title'>,
    Pick<
      Activity,
      | 'title'
      | 'entryDate'
      | 'duration'
      | 'travelInvolved'
      | 'followUp'
      | 'technicalAssistanceMode'
    > {
  activityCollaborators: User[];
  author?: User;
}

export const ActivityOverviewDetail: FC<Props> = ({
  title,
  entryDate,
  duration,
  travelInvolved,
  followUp,
  technicalAssistanceMode,
  activityCollaborators,
  author,
  ...props
}) => (
  <ActivityDetailSegment heading="Overview" {...props}>
    <Row className="mt-1.5 items-stretch">
      <ReadOnlyField label="Activity Title" value={title} className="flex-1" />
      <ReadOnlyField label="Entry Date" value={entryDate} className="flex-1" />
    </Row>
    <Row className="mt-3.5">
      <ReadOnlyField
        label="Duration"
        value={duration ? formatDuration(duration) : '--'}
        className="flex-1"
      />
      <ReadOnlyField
        label="Travel Involved"
        value={travelInvolved ? formatDuration(travelInvolved) : '--'}
        className="flex-1"
      />
    </Row>
    <Row className="mt-3.5">
      <ReadOnlyField
        label="Author"
        value={author ? formatName(author) : '--'}
        className="flex-1"
      />
    </Row>
    <ActivityFormInputLabel className="mt-7">
      Follow-up to Activity
    </ActivityFormInputLabel>
    <div className="detail-text">{followUp ? 'Follow-up' : 'No Follow-up'}</div>
    <ActivityFormInputLabel className="mt-7">
      Technical Assistance Mode
    </ActivityFormInputLabel>
    <div className="detail-text">
      {technicalAssistanceMode === TechnicalAssistanceMode.ON_SITE
        ? 'On-Site'
        : 'Electronic'}
    </div>
    <ListWithCounter
      className="mt-7"
      label="Collaborators"
      list={sortUsersByRegion(activityCollaborators).map((user) => ({
        id: user.id,
        value: formatUserNameAndRegion(user),
      }))}
    />
  </ActivityDetailSegment>
);
