import { createContext, useContext } from 'react';
import { SnackbarProps } from 'components/Snackbar/Snackbar';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { SnackbarPositions } from 'enums/SnackbarPositions';

interface SnackbarShowProps extends Omit<SnackbarProps, 'open' | 'onClose'> {
  timeout?: number | false;
}

interface SnackbarUsageProps {
  show: (props: SnackbarShowProps) => void;
  hide: () => void;
}

export interface SnackbarContextValue {
  open: boolean;
  severity?: SnackbarSeverityKind;
  text?: string;
  position?: SnackbarPositions;
  snackbar: SnackbarUsageProps;
}

export const SnackbarContext = createContext<SnackbarContextValue>({
  open: false,
  text: '',
  severity: SnackbarSeverityKind.SUCCESS,
  position: SnackbarPositions.TOP,
  snackbar: {
    show() {},
    hide() {},
  },
});

export function useSnackbarContext() {
  return useContext(SnackbarContext);
}
