import { useCallback, useEffect, useReducer } from 'react';
import { County } from 'shared/lib/types/County';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { TechnicalAssistanceMode } from 'shared/lib/constants/activity/TechnicalAssistanceMode';
import { sort } from 'shared/lib/utils/sort';
import { ActivityFilter } from 'types/ActivityFilter';
import { ActivityFilterOtherOption } from 'enums/ActivityFilterOtherOption';

export interface Props {
  countyList: County[];
  schoolDistrictList: SchoolDistrict[];
}

export interface ActivityFilterFormReturnValues {
  activityFilter: ActivityFilter;
  filteredCounties: County[];
  filteredSchoolDistricts: SchoolDistrict[];
  onStatewideFilterChanged(): void;
  onRegionFilterChanged(regionId: number | ActivityFilterOtherOption.ALL): void;
  onCountyFilterChanged(countyId: number | ActivityFilterOtherOption.ALL): void;
  onSchoolSchoolDistrictFilterChanged(
    schoolDistrictId: number | ActivityFilterOtherOption.ALL,
  ): void;
  onActivityDurationsChanged(activityDurations: number[]): void;
  onTravelDurationsChanged(activityDurations: number[]): void;
  onActivityTypeFilterChanged(
    activityTypes: (number | ActivityFilterOtherOption.OTHER)[],
  ): void;
  onAudienceTypeFilterChanged(
    audienceTypes: (number | ActivityFilterOtherOption.OTHER)[],
  ): void;
  onDeliverableTypeFilterChanged(
    deliverableTypes: (number | ActivityFilterOtherOption.OTHER)[],
  ): void;
  onTechnicalAssistanceModeChanged(
    taMode: TechnicalAssistanceMode | ActivityFilterOtherOption.ALL,
  ): void;
  onCollaboratorFilterChanged(collaboratorIds: number[]): void;
  onAttendeeFilterChanged(attendeeIds: number[]): void;
  onActivityInQuarterlyReporFilterChangedt(
    activityInReport: boolean | ActivityFilterOtherOption.ALL,
  ): void;
  onCommentsInQuarterlyReportFilterChanged(
    commentsInReport: boolean | ActivityFilterOtherOption.ALL,
  ): void;
  onHasCommentsFilterChanged(
    hasComments: boolean | ActivityFilterOtherOption.ALL,
  ): void;
  onHasFilesFilterChanged(
    hasFiles: boolean | ActivityFilterOtherOption.ALL,
  ): void;
  onFollowUpFilterChanged(
    followUp: boolean | ActivityFilterOtherOption.ALL,
  ): void;
}

interface ActivityFilterState {
  activityFilter: ActivityFilter;
  allCounties: County[];
  filteredCounties: County[];
  allSchoolDistricts: SchoolDistrict[];
  filteredSchoolDistricts: SchoolDistrict[];
}

export const defaultActivityFilter: ActivityFilter = {
  statewide: false,
  regionId: null,
  countyId: null,
  schoolDistrictId: null,
  activityDurations: null,
  travelDurations: null,
  activityTypes: null,
  audienceTypes: null,
  deliverableTypes: null,
  technicalAssistanceMode: null,
  collaboratorIds: null,
  attendeeIds: null,
  activityInQuarterlyReport: null,
  commentsInQuarterlyReport: null,
  hasComments: null,
  hasFiles: null,
  followUp: null,
};

function getFilterSingleValue<T>(value: T | ActivityFilterOtherOption.ALL) {
  return value === ActivityFilterOtherOption.ALL ? null : value;
}

function getFilterArrayValue<
  T extends number | ActivityFilterOtherOption.OTHER
>(values: T[]) {
  if (values.length === 0) {
    return null;
  }

  return sort(values, (a, b) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }
    if (a === b) {
      return 0; // There should never be duplicates, but let's be thorough
    }
    // Sort OTHER before numbers so that .includes(OTHER) can exit earlier
    return a === ActivityFilterOtherOption.OTHER ? -1 : 1;
  });
}

type FilterAction =
  | { type: 'toggleStatewide' }
  | {
      type: 'setRegionId' | 'setCountyId' | 'setSchoolDistrictId';
      payload: number | ActivityFilterOtherOption.ALL;
    }
  | { type: 'setActivityDurations' | 'setTravelDurations'; payload: number[] }
  | {
      type: 'setActivityTypes' | 'setAudienceTypes' | 'setDeliverableTypes';
      payload: (number | ActivityFilterOtherOption.OTHER)[];
    }
  | {
      type: 'setTaMode';
      payload: TechnicalAssistanceMode | ActivityFilterOtherOption.ALL;
    }
  | { type: 'setCollaboratorIds' | 'setAttendeeIds'; payload: number[] }
  | {
      type:
        | 'setActivityInQuarterlyReport'
        | 'setCommentsInQuarterlyReport'
        | 'setHasComments'
        | 'setHasFiles'
        | 'setFollowUp';
      payload: boolean | ActivityFilterOtherOption.ALL;
    }
  | { type: 'updateCounties'; payload: County[] }
  | { type: 'updateSchoolDistricts'; payload: SchoolDistrict[] };

function updateActivityFilterByFilterAction(
  filter: ActivityFilter,
  action: FilterAction & {
    type: Exclude<
      FilterAction['type'],
      'updateCounties' | 'updateSchoolDistricts'
    >;
  },
): ActivityFilter {
  switch (action.type) {
    case 'toggleStatewide':
      return { ...filter, statewide: !filter.statewide };
    case 'setRegionId':
      return { ...filter, regionId: getFilterSingleValue(action.payload) };
    case 'setCountyId':
      return { ...filter, countyId: getFilterSingleValue(action.payload) };
    case 'setSchoolDistrictId':
      return {
        ...filter,
        schoolDistrictId: getFilterSingleValue(action.payload),
      };
    case 'setActivityDurations':
      return {
        ...filter,
        activityDurations: getFilterArrayValue(action.payload),
      };
    case 'setTravelDurations':
      return {
        ...filter,
        travelDurations: getFilterArrayValue(action.payload),
      };
    case 'setActivityTypes':
      return { ...filter, activityTypes: getFilterArrayValue(action.payload) };
    case 'setAudienceTypes':
      return { ...filter, audienceTypes: getFilterArrayValue(action.payload) };
    case 'setDeliverableTypes':
      return {
        ...filter,
        deliverableTypes: getFilterArrayValue(action.payload),
      };
    case 'setTaMode':
      return {
        ...filter,
        technicalAssistanceMode: getFilterSingleValue(action.payload),
      };
    case 'setCollaboratorIds':
      return {
        ...filter,
        collaboratorIds: getFilterArrayValue(action.payload),
      };
    case 'setAttendeeIds':
      return { ...filter, attendeeIds: getFilterArrayValue(action.payload) };
    case 'setActivityInQuarterlyReport':
      return {
        ...filter,
        activityInQuarterlyReport: getFilterSingleValue(action.payload),
      };
    case 'setCommentsInQuarterlyReport':
      return {
        ...filter,
        commentsInQuarterlyReport: getFilterSingleValue(action.payload),
      };
    case 'setHasComments':
      return { ...filter, hasComments: getFilterSingleValue(action.payload) };
    case 'setHasFiles':
      return { ...filter, hasFiles: getFilterSingleValue(action.payload) };
    case 'setFollowUp':
      return { ...filter, followUp: getFilterSingleValue(action.payload) };
    default:
      throw new Error(
        `Invalid action type: ${(action as { type: unknown }).type}`,
      );
  }
}

function activityFilterFormReducer(
  state: ActivityFilterState,
  action: FilterAction,
): ActivityFilterState {
  let {
    activityFilter,
    allCounties,
    filteredCounties,
    allSchoolDistricts,
    filteredSchoolDistricts,
  } = state;

  if (action.type === 'updateCounties') {
    allCounties = action.payload;
  } else if (action.type === 'updateSchoolDistricts') {
    allSchoolDistricts = action.payload;
  } else {
    activityFilter = updateActivityFilterByFilterAction(activityFilter, action);
  }

  // Disallow other location filters when `statewide` is selected
  if (activityFilter.statewide) {
    activityFilter.regionId = null;
    activityFilter.countyId = null;
    activityFilter.schoolDistrictId = null;
  }

  // Filter selectable counties by region
  if (
    allCounties !== state.allCounties ||
    activityFilter.regionId !== state.activityFilter.regionId
  ) {
    filteredCounties =
      activityFilter.regionId === null
        ? allCounties
        : allCounties.filter(
            ({ regionId }) => regionId === activityFilter.regionId,
          );
  }

  // Validate selected county
  if (
    activityFilter.countyId !== null &&
    !filteredCounties.some(({ id }) => id === activityFilter.countyId)
  ) {
    activityFilter.countyId = null;
  }

  // Filter selectable school districts by selectable/selected counties
  if (
    filteredCounties !== state.filteredCounties ||
    allSchoolDistricts !== state.allSchoolDistricts ||
    activityFilter.countyId !== state.activityFilter.countyId
  ) {
    if (activityFilter.countyId === null) {
      const countyIds = new Set(filteredCounties.map(({ id }) => id));
      filteredSchoolDistricts = allSchoolDistricts.filter(({ countyId }) =>
        countyIds.has(countyId),
      );
    } else {
      filteredSchoolDistricts = allSchoolDistricts.filter(
        ({ countyId }) => countyId === activityFilter.countyId,
      );
    }
  }

  // Validate selected school district
  if (
    activityFilter.schoolDistrictId !== null &&
    !filteredSchoolDistricts.some(
      ({ id }) => id === activityFilter.schoolDistrictId,
    )
  ) {
    activityFilter.schoolDistrictId = null;
  }

  return {
    activityFilter,
    allCounties,
    filteredCounties,
    allSchoolDistricts,
    filteredSchoolDistricts,
  };
}

export function useActivityFilterForm({
  countyList,
  schoolDistrictList,
}: Props): ActivityFilterFormReturnValues {
  const [
    { activityFilter, filteredCounties, filteredSchoolDistricts },
    dispatch,
  ] = useReducer(activityFilterFormReducer, {
    activityFilter: defaultActivityFilter,
    allCounties: countyList,
    filteredCounties: countyList,
    allSchoolDistricts: schoolDistrictList,
    filteredSchoolDistricts: schoolDistrictList,
  });

  useEffect(() => {
    dispatch({ type: 'updateCounties', payload: countyList });
  }, [countyList]);

  useEffect(() => {
    dispatch({ type: 'updateSchoolDistricts', payload: schoolDistrictList });
  }, [schoolDistrictList]);

  return {
    activityFilter,
    filteredCounties,
    filteredSchoolDistricts,
    onStatewideFilterChanged: useCallback(() => {
      dispatch({ type: 'toggleStatewide' });
    }, []),
    onRegionFilterChanged: useCallback((regionId) => {
      dispatch({ type: 'setRegionId', payload: regionId });
    }, []),
    onCountyFilterChanged: useCallback((countyId) => {
      dispatch({ type: 'setCountyId', payload: countyId });
    }, []),
    onSchoolSchoolDistrictFilterChanged: useCallback((schoolDistrictId) => {
      dispatch({ type: 'setSchoolDistrictId', payload: schoolDistrictId });
    }, []),
    onActivityDurationsChanged: useCallback((activityDurations) => {
      dispatch({ type: 'setActivityDurations', payload: activityDurations });
    }, []),
    onTravelDurationsChanged: useCallback((travelDurations) => {
      dispatch({ type: 'setTravelDurations', payload: travelDurations });
    }, []),
    onActivityTypeFilterChanged: useCallback((activityTypes) => {
      dispatch({ type: 'setActivityTypes', payload: activityTypes });
    }, []),
    onAudienceTypeFilterChanged: useCallback((audienceTypes) => {
      dispatch({ type: 'setAudienceTypes', payload: audienceTypes });
    }, []),
    onDeliverableTypeFilterChanged: useCallback((deliverableTypes) => {
      dispatch({ type: 'setDeliverableTypes', payload: deliverableTypes });
    }, []),
    onTechnicalAssistanceModeChanged: useCallback((taMode) => {
      dispatch({ type: 'setTaMode', payload: taMode });
    }, []),
    onCollaboratorFilterChanged: useCallback((collaboratorIds) => {
      dispatch({ type: 'setCollaboratorIds', payload: collaboratorIds });
    }, []),
    onAttendeeFilterChanged: useCallback((attendeeIds) => {
      dispatch({ type: 'setAttendeeIds', payload: attendeeIds });
    }, []),
    onActivityInQuarterlyReporFilterChangedt: useCallback((inReport) => {
      dispatch({ type: 'setActivityInQuarterlyReport', payload: inReport });
    }, []),
    onCommentsInQuarterlyReportFilterChanged: useCallback((inReport) => {
      dispatch({ type: 'setCommentsInQuarterlyReport', payload: inReport });
    }, []),
    onHasCommentsFilterChanged: useCallback((hasComments) => {
      dispatch({ type: 'setHasComments', payload: hasComments });
    }, []),
    onHasFilesFilterChanged: useCallback((hasFiles) => {
      dispatch({ type: 'setHasFiles', payload: hasFiles });
    }, []),
    onFollowUpFilterChanged: useCallback((followUp) => {
      dispatch({ type: 'setFollowUp', payload: followUp });
    }, []),
  };
}
