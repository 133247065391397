import React, { FC } from 'react';
import { SemiBoldHeader } from 'components/SemiBoldHeader/SemiBoldHeader';
import { loginRoute } from 'shared/lib/constants/routes/publicRoutes';
import { Link } from 'react-router-dom';

export const PasswordResetConfirmation: FC = (props) => (
  <div {...props}>
    <SemiBoldHeader text="Password Reset Success" />
    <div className="subheading-sm mb-12">
      Your password has been reset. For security reasons, you will need to
      login.
    </div>
    <Link className="btn-green" to={loginRoute}>
      Login
    </Link>
  </div>
);
