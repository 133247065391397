import React, { ComponentPropsWithoutRef, FC } from 'react';
import './ToggleInput.css';

interface Props extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  containerClassName?: string;
  labelClassName?: string;
}

export const ToggleInput: FC<Props> = ({
  label = '',
  containerClassName = '',
  labelClassName = '',
  className = '',
  disabled,
  ...props
}) => {
  return (
    <div
      className={`flex flex-row items-center justify-center ${containerClassName}`}
    >
      <div
        className={`mr-3 text-gray-700 font-sf-pro-medium toggle-label ${
          label ? '' : 'hidden'
        } ${disabled ? 'opacity-50' : ''} ${labelClassName}`}
      >
        {label}
      </div>
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className={`sr-only toggle ${className}`}
            disabled={disabled}
            {...props}
          />
          <div className="rounded-full shadow-inner toggle-inner"></div>
          <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-0 -top-1.5 transition select-none"></div>
        </div>
      </label>
    </div>
  );
};
