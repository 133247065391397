import { useState, useCallback, FormEvent, useEffect } from 'react';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { UserRole } from 'shared/lib/constants/user/UserRole';
import getErrorMessage from 'utils/getErrorMessage';
import { User } from 'shared/lib/types/User';
import { FillableUserFormProps } from 'types/FillableUserFormProps';

const defaultFillableUser: FillableUserFormProps = {
  firstName: '',
  lastName: '',
  email: '',
};

interface ReturnValues {
  fillableUserFields: FillableUserFormProps;
  onFirstNameChanged: (firstName: string) => void;
  onLastNameChanged: (lastName: string) => void;
  onEmailChanged: (email: string) => void;
  onRegionChanged: (regionId: number) => void;
  onRoleChanged: (role: UserRole) => void;
  handleFormSubmission: (
    e: FormEvent,
    onSubmit: (data: FillableUserFormProps) => void,
  ) => void;
}

export function useUserForm(user?: User | null): ReturnValues {
  const [fillableUserFields, setFillableUserFields] = useState<
    FillableUserFormProps
  >(defaultFillableUser);
  const { snackbar } = useSnackbarContext();

  const onFirstNameChanged = useCallback((firstName: string) => {
    setFillableUserFields((prevState) => ({
      ...prevState,
      firstName,
    }));
  }, []);

  const onLastNameChanged = useCallback((lastName: string) => {
    setFillableUserFields((prevState) => ({
      ...prevState,
      lastName,
    }));
  }, []);

  const onEmailChanged = useCallback((email: string) => {
    setFillableUserFields((prevState) => ({
      ...prevState,
      email,
    }));
  }, []);

  const onRegionChanged = useCallback((regionId: number) => {
    setFillableUserFields((prevState) => ({
      ...prevState,
      regionId,
    }));
  }, []);

  const onRoleChanged = useCallback((role: UserRole) => {
    setFillableUserFields((prevState) => ({
      ...prevState,
      role,
    }));
  }, []);

  const handleFormSubmission = useCallback(
    (e: FormEvent, onSubmit: (data: FillableUserFormProps) => void) => {
      e.preventDefault();
      const { firstName, lastName, email, role, regionId } = fillableUserFields;
      try {
        if (!role) {
          throw new Error('Role is required');
        }

        if (!regionId) {
          throw new Error('Region is required');
        }

        onSubmit({
          firstName,
          lastName,
          email,
          role,
          regionId,
        });
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [fillableUserFields, snackbar],
  );

  useEffect(() => {
    if (user) {
      onFirstNameChanged(user.firstName);
      onLastNameChanged(user.lastName);
      onRoleChanged(user.role);
      onEmailChanged(user.email);
      onRegionChanged(user.regionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    fillableUserFields,
    onFirstNameChanged,
    onLastNameChanged,
    onEmailChanged,
    onRegionChanged,
    onRoleChanged,
    handleFormSubmission,
  };
}
