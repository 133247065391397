import React, { FC } from 'react';
import { TextInput } from 'components/TextInput/TextInput';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { ActivityTypeRadioInput } from 'components/ActivityTypeRadioInput/ActivityTypeRadioInput';
import { ActivityTypeFormProps } from 'types/ActivityTypeFormProps';

export const ActivityTypeForm: FC<ActivityTypeFormProps> = ({
  activityType,
  otherActivityType,
  onActivityTypeChanged,
  onOtherActivityTypeChanged,
  activityTypeKindList,
  ...props
}) => (
  <div {...props}>
    <ActivityTypeRadioInput
      activityTypeKindList={activityTypeKindList}
      selected={activityType ?? []}
      onChange={onActivityTypeChanged}
    />
    <ActivityFormInputLabel className="mt-12">
      Other Activity
    </ActivityFormInputLabel>
    <TextInput
      value={otherActivityType ?? ''}
      placeholder="Type here..."
      onChange={(e) => onOtherActivityTypeChanged(e.target.value)}
      containerClassName="w-3/4"
    />
  </div>
);
