import React, { FC, useCallback } from 'react';
import {
  DropdownInput,
  DropdownInputProps,
} from 'components/DropdownInput/DropdownInput';
import { UserRole } from 'shared/lib/constants/user/UserRole';
import { userRoleDisplayNameMappings } from 'constants/userRoleDisplayNameMappings';
import fail from 'utils/fail';

interface RolesDropdownProps
  extends Omit<DropdownInputProps, 'onChange' | 'options'> {
  selectedValue?: UserRole;
  onChange: (value: UserRole) => void;
}

const options = [
  UserRole.PRE_ETS_SUPPORT_SPECIALIST,
  UserRole.TNF,
  UserRole.SUPER_ADMIN,
  UserRole.NON_COLLABORATOR_SUPER_ADMIN_READ_ONLY,
  UserRole.NON_COLLABORATOR_SUPER_ADMIN,
].map((role) => ({
  id: role,
  value:
    userRoleDisplayNameMappings.get(role) ??
    fail(`Missing display name for role ${role}`),
}));

export const RolesDropdown: FC<RolesDropdownProps> = ({
  onChange,
  selectedValue,
  ...props
}) => {
  const handleDropdownChange = useCallback(
    (option) => {
      const role = options.find((roleOption) => roleOption.id === option.id)
        ?.id;
      if (role) {
        onChange(role);
      }
    },
    [onChange],
  );
  return (
    <DropdownInput
      label="Role"
      options={options}
      onChange={handleDropdownChange}
      placeholder="Choose Role"
      selected={
        options.find((roleOption) => roleOption.id === selectedValue)
          ?.id as string
      }
      {...props}
    />
  );
};
