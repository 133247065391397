import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Button } from 'components/Button/Button';
import { TextInput } from 'components/TextInput/TextInput';
import fail from 'utils/fail';
import getErrorMessage from 'utils/getErrorMessage';
import getUrlParams from 'utils/getUrlParams';
import { FormValues } from 'pages/PasswordResetPage/PasswordResetPage';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';

interface Props {
  onSubmit: (values: FormValues) => void;
}

export const PasswordResetForm: FC<Props> = ({ onSubmit }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { snackbar } = useSnackbarContext();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        if (password.length < 6) {
          fail('Password must be 6 or more characters.');
        }

        if (password !== passwordConfirm) {
          fail('Passwords do not match.');
        }

        const params = await getUrlParams(['email', 'token']);

        onSubmit({
          email: params.email,
          password: password,
          token: params.token,
        });
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [onSubmit, password, passwordConfirm, snackbar],
  );

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        label="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mb-6"
        type="password"
      />
      <TextInput
        label="Confirm Password"
        value={passwordConfirm}
        onChange={(e) => setPasswordConfirm(e.target.value)}
        type="password"
      />
      <Button className="mt-10" type="submit">
        Submit
      </Button>
    </form>
  );
};
