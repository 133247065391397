import { useCallback, useState } from 'react';
import { SnackbarContextValue } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { SnackbarPositions } from 'enums/SnackbarPositions';

export function useSnackbar(): SnackbarContextValue {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<SnackbarSeverityKind>();
  const [text, setText] = useState('');
  const [position, setPosition] = useState<SnackbarPositions>();

  const show = useCallback(({ severity, text, position, timeout = 3250 }) => {
    setOpen(true);
    setSeverity(severity);
    setText(text);
    setPosition(position);
    if (timeout && severity !== SnackbarSeverityKind.SPINNER) {
      setTimeout(() => {
        setOpen(false);
      }, timeout);
    }
  }, []);

  const hide = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    severity,
    text,
    position,
    snackbar: {
      show,
      hide,
    },
  };
}
