import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Activity } from 'shared/lib/types/Activity';

export type ActivityDeliverableDetailProps = ComponentPropsWithoutRef<'div'> &
  Pick<Activity, 'activityDeliverables' | 'otherActivityDeliverable'>;

export const ActivityDeliverableDetail: FC<ActivityDeliverableDetailProps> = ({
  activityDeliverables,
  otherActivityDeliverable,
  ...props
}) => (
  <ActivityDetailSegment heading="Activity Deliverables" {...props}>
    <div className="detail-text mt-1">
      {activityDeliverables?.map((deliverable) => (
        <div
          key={deliverable.id}
        >{`${deliverable.activityDeliverableKind?.name}`}</div>
      ))}
      {otherActivityDeliverable && (
        <div className="detail-text mt-1">{otherActivityDeliverable}</div>
      )}
    </div>
  </ActivityDetailSegment>
);
