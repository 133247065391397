import { RadioButton } from 'components/RadioButton/RadioButton';
import React, { ComponentPropsWithoutRef, FC } from 'react';

interface Props extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
  label: string;
  checked?: boolean;
}

export const RadioInput: FC<Props> = ({ ...props }) => (
  <RadioButton type="radio" {...props} />
);
