import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { Activity } from 'shared/lib/types/Activity';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { ActivityFormStep } from 'enums/ActivityFormStep';
import { useActivityOverviewForm } from 'hooks/useActivityOverviewForm';
import { useActivityAreaInvolvedForm } from 'hooks/useActivityAreaInvolvedForm';
import { useActivityTypeForm } from 'hooks/useActivityTypeForm';
import { useActivityDeliverableForm } from 'hooks/useActivityDeliverablesForm';
import { useActivityAudienceForm } from 'hooks/useActivityAudienceForm';
import { useActivityCommentsAndFilesForm } from 'hooks/useActivityCommentsAndFilesForm';
import { Row } from 'components/Row/Row';
import { Stepper } from 'components/Stepper/Stepper';
import { ActivityFormStep1 } from 'components/ActivityFormStep1/ActivityFormStep1';
import { ActivityFormStep2 } from 'components/ActivityFormStep2/ActivityFormStep2';
import { ActivityFormStep3 } from 'components/ActivityFormStep3/ActivityFormStep3';
import { ActivityFormStep4 } from 'components/ActivityFormStep4/ActivityFormStep4';
import { ActivityFormStep5 } from 'components/ActivityFormStep5/ActivityFormStep5';
import { ActivityFormStep6 } from 'components/ActivityFormStep6/ActivityFormStep6';
import { StepperButtonGroup } from 'components/StepperButtonGroup/StepperButtonGroup';
import { ActivityFormWrapper } from 'components/ActivityFormWrapper/ActivityFormWrapper';
import { CancelActivityConfirmationModal } from 'components/CancelActivityConfirmationModal/CancelActivityConfirmationModal';
import { SaveActivityProgressConfirmationModal } from 'components/SaveActivityProgressConfirmationModal/SaveActivityProgressConfirmationModal';
import './ActivityForm.css';
import { FillableActivityFormFields } from 'types/FillableActivityFormFields';
import { County } from 'shared/lib/types/County';
import { isSuperViewer } from 'shared/lib/utils/isSuperAdmin';
import { useUserContext } from 'contexts/userContext';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  activity?: Activity;
  regionalCounties: County[];
  appBarHeaderLabel: string;
  onSubmitAndContinue(data: FillableActivityFormFields): void;
  onSubmitAndReturnToList(data: FillableActivityFormFields): void;
}

export const ActivityForm: FC<Props> = ({
  appBarHeaderLabel,
  activity,
  regionalCounties,
  onSubmitAndContinue,
  onSubmitAndReturnToList,
  ...props
}) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSaveProgressModal, setShowSaveProgressModal] = useState(false);
  const { user } = useUserContext();
  const history = useHistory();
  const { currentStep, onCurrentStepChanged } = useActivityFormContext();

  const steps = [
    {
      title: 'Overview',
      key: ActivityFormStep.OVERVIEW,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.OVERVIEW);
      },
    },
    {
      title: 'Area Involved',
      key: ActivityFormStep.AREA_INVOLVED,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.AREA_INVOLVED);
      },
    },
    {
      title: 'Type of Activity',
      key: ActivityFormStep.ACTIVITY_TYPE,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.ACTIVITY_TYPE);
      },
    },
    {
      title: 'Deliverables',
      key: ActivityFormStep.DELIVERABLES,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.DELIVERABLES);
      },
    },
    {
      title: 'Audience',
      key: ActivityFormStep.AUDIENCE,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.AUDIENCE);
      },
    },
    {
      title: 'Comments + Files',
      key: ActivityFormStep.COMMENTS_AND_FILES,
      onClick: () => {
        onCurrentStepChanged(ActivityFormStep.COMMENTS_AND_FILES);
      },
    },
  ];

  // Form fields
  const { overviewFields, ...restOfOverviewProps } = useActivityOverviewForm(
    activity,
  );
  const {
    areaInvolvedFields,
    ...restOfAreaInvolvedProps
  } = useActivityAreaInvolvedForm({ activity, regionalCounties });

  const {
    activityTypeFields,
    ...restOfActivityTypeProps
  } = useActivityTypeForm(activity);

  const {
    activityDeliverableFields,
    ...restOfActivityDeliverableProps
  } = useActivityDeliverableForm(activity);

  const {
    activityAudienceFields,
    ...restOfActivityAudienceProps
  } = useActivityAudienceForm(activity);

  const {
    activityCommentsAndFilesFields,
    ...restOfActivityCommentsAndFilesProps
  } = useActivityCommentsAndFilesForm(activity);

  const getSubmitValues = useCallback(
    () => ({
      ...overviewFields,
      ...areaInvolvedFields,
      ...activityTypeFields,
      ...activityDeliverableFields,
      ...activityAudienceFields,
      ...activityCommentsAndFilesFields,
    }),
    [
      overviewFields,
      areaInvolvedFields,
      activityTypeFields,
      activityDeliverableFields,
      activityAudienceFields,
      activityCommentsAndFilesFields,
    ],
  );

  const handleSubmitActivityAndContinue = useCallback(() => {
    setShowSaveProgressModal(false);
    onSubmitAndContinue(getSubmitValues());
  }, [onSubmitAndContinue, getSubmitValues]);

  const handleSubmitActivityAndReturnHome = useCallback(() => {
    setShowSaveProgressModal(false);
    onSubmitAndReturnToList(getSubmitValues());
  }, [onSubmitAndReturnToList, getSubmitValues]);

  const handleCancelActivity = useCallback(() => {
    onCurrentStepChanged(ActivityFormStep.OVERVIEW);
    history.push(activityListRoute);
  }, [history, onCurrentStepChanged]);

  const next = useCallback(async () => {
    if (currentStep === ActivityFormStep.COMMENTS_AND_FILES) {
      onSubmitAndReturnToList({
        ...getSubmitValues(),
        complete: true,
      });
    } else {
      onCurrentStepChanged(
        currentStep <= steps.length ? currentStep + 1 : currentStep,
      );
    }
  }, [
    currentStep,
    onCurrentStepChanged,
    onSubmitAndReturnToList,
    getSubmitValues,
    steps.length,
  ]);

  const back = useCallback(() => {
    onCurrentStepChanged(currentStep > 1 ? currentStep - 1 : currentStep);
  }, [onCurrentStepChanged, currentStep]);

  const handleShowCancelModal = useCallback(() => {
    setShowSaveProgressModal(false);
    setShowCancelModal(true);
  }, []);

  const handleShowSaveProgressModal = useCallback(() => {
    setShowCancelModal(false);
    setShowSaveProgressModal(true);
  }, []);

  return (
    <ActivityFormWrapper
      headerLabel={appBarHeaderLabel}
      onSaveProgressClick={handleShowSaveProgressModal}
      onCancelClick={handleShowCancelModal}
      {...props}
    >
      <div className="h-full relative overflow-scroll overscroll-contain">
        <Row>
          <div className="w-56">
            <Stepper steps={steps} currentStep={currentStep} />
          </div>
          <div className="pl-20 w-full">
            {(showCancelModal || showSaveProgressModal) && (
              <div className="mb-6">
                {showCancelModal && (
                  <CancelActivityConfirmationModal
                    onCancelConfirm={handleCancelActivity}
                    onClose={() => setShowCancelModal(false)}
                  />
                )}
                {showSaveProgressModal && (
                  <SaveActivityProgressConfirmationModal
                    onSaveAndContinueConfirm={handleSubmitActivityAndContinue}
                    onSaveAndReturnHomeConfirm={
                      handleSubmitActivityAndReturnHome
                    }
                    onClose={() => setShowSaveProgressModal(false)}
                  />
                )}
              </div>
            )}
            {currentStep === ActivityFormStep.OVERVIEW && (
              <ActivityFormStep1 {...overviewFields} {...restOfOverviewProps} />
            )}

            {currentStep === ActivityFormStep.AREA_INVOLVED && user && (
              <ActivityFormStep2
                canViewEverything={
                  isSuperViewer(user) &&
                  (!activity || activity?.user?.userId === user.id)
                }
                regionalCounties={regionalCounties}
                {...areaInvolvedFields}
                {...restOfAreaInvolvedProps}
              />
            )}
            {currentStep === ActivityFormStep.ACTIVITY_TYPE && (
              <ActivityFormStep3
                {...activityTypeFields}
                {...restOfActivityTypeProps}
              />
            )}
            {currentStep === ActivityFormStep.DELIVERABLES && (
              <ActivityFormStep4
                {...activityDeliverableFields}
                {...restOfActivityDeliverableProps}
              />
            )}
            {currentStep === ActivityFormStep.AUDIENCE && (
              <ActivityFormStep5
                {...activityAudienceFields}
                {...restOfActivityAudienceProps}
              />
            )}
            {currentStep === ActivityFormStep.COMMENTS_AND_FILES && (
              <ActivityFormStep6
                existingFiles={activity?.files}
                {...activityCommentsAndFilesFields}
                {...restOfActivityCommentsAndFilesProps}
              />
            )}
          </div>
        </Row>
        <StepperButtonGroup
          onNext={next}
          onPrevious={back}
          nextLabel={
            currentStep === ActivityFormStep.COMMENTS_AND_FILES
              ? 'Finish'
              : 'Next'
          }
        />
      </div>
    </ActivityFormWrapper>
  );
};
