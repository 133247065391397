import React, { FC } from 'react';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { ActivityCommentsAndFilesFormProps } from 'types/ActivityCommentsAndFilesFormProps';
import { ActivityFileUploadInput } from 'components/ActivityFileUploadInput/ActivityFileUploadInput';
import { ActivityFile } from 'shared/lib/types/ActivityFile';
import { TextAreaInput } from 'components/TextAreaInput/TextAreaInput';
import { ToggleInput } from 'components/ToggleInput/ToggleInput';
import { Row } from 'components/Row/Row';

export const ActivityCommentsAndFilesForm: FC<
  ActivityCommentsAndFilesFormProps & {
    onExistingFileRemoveClick(activityFile: ActivityFile): void;
  }
> = ({
  files,
  comments,
  showCommentsInQuarterlyReport,
  existingFiles,
  onExistingFileRemoveClick,
  onCommentsChanged,
  onShowCommentsInQuarterlyReportChanged,
  onFilesChanged,
  onFileRemoved,
}) => (
  <div>
    <Row className="w-3/4 flex-wrap justify-between">
      <ActivityFormInputLabel className="mr-4">
        Comments or Notes
      </ActivityFormInputLabel>
      <ToggleInput
        label="Include in quarterly report"
        checked={showCommentsInQuarterlyReport}
        onChange={(e) =>
          onShowCommentsInQuarterlyReportChanged(e.currentTarget.checked)
        }
      />
    </Row>
    <TextAreaInput
      value={comments ?? ''}
      onChange={(e) => onCommentsChanged(e.target.value)}
      containerClassName="w-3/4"
    />
    <ActivityFileUploadInput
      className="w-3/4 mt-13"
      files={files}
      onChange={onFilesChanged}
      onRemoveFile={onFileRemoved}
      existingFiles={existingFiles}
      onExistingFileRemoveClick={onExistingFileRemoveClick}
    />
  </div>
);
