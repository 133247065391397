export enum CircularActionIconKind {
  X_ACTIVE = 'X',
  PLUS = 'PLUS',
  PLUS_GREEN = 'PLUS_GREEN',
  PLUS_DARK = 'PLUS_DARK',
  X_GREEN = 'X_GREEN',
  DOWN_CHEVRON_ROUND = 'DOWN_CHEVRON_ROUND',
  FILTER = 'FILTER',
  FILTER_ACTIVE = 'FILTER_ACTIVE',
  SHARE = 'SHARE',
  SHARE_ACTIVE = 'SHARE_ACTIVE',
  DOWNLOAD = 'DOWNLOAD',
}
