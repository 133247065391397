import { apiAxios } from '../apiAxios';

interface Body {
  email: string;
  token: string;
  password: string;
}

export async function acceptInvitation(body: Body) {
  return apiAxios.post('/users/accept-invite', body);
}
