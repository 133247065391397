import React, { FC } from 'react';
import { ActivityOverviewForm } from 'components/ActivityOverviewForm/ActivityOverviewForm';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { formatUserNameAndRegion } from 'utils/formatUserNameAndRegion';
import { ActivityOverviewFormProps } from 'types/ActivityOverviewFormProps';
import { useUserContext } from 'contexts/userContext';

export const ActivityFormStep1: FC<Omit<
  ActivityOverviewFormProps,
  'appUserOptions'
>> = ({ collaborators, ...props }) => {
  const { user } = useUserContext();
  const { appUsers } = useActivityFormContext();
  return (
    <ActivityFormStepWrapper heading="Overview">
      <ActivityOverviewForm
        appUserOptions={(user &&
        collaborators?.some((collaborator) => collaborator.userId === user.id)
          ? [...appUsers, user]
          : appUsers
        ).map((appUser) => ({
          id: appUser.id,
          value: formatUserNameAndRegion(appUser),
        }))}
        collaborators={collaborators}
        {...props}
      />
    </ActivityFormStepWrapper>
  );
};
