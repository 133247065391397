import React, { FC } from 'react';
import { ActivityDeliverableRadioInput } from 'components/ActivityDeliverableRadioInput/ActivityDeliverableRadioInput';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { TextInput } from 'components/TextInput/TextInput';
import { ActivityDeliverableFormProps } from 'types/ActivityDeliverableFormProps';

export const ActivityDeliverablesForm: FC<ActivityDeliverableFormProps> = ({
  activityDeliverables,
  activityDeliverableKindList,
  otherActivityDeliverable,
  onOtherActivityDeliverableChanged,
  onActivityDeliverableChanged,
  ...props
}) => (
  <div {...props}>
    <ActivityDeliverableRadioInput
      activityDeliverableKindList={activityDeliverableKindList}
      selected={activityDeliverables ?? []}
      onChange={onActivityDeliverableChanged}
    />
    <ActivityFormInputLabel className="mt-12">
      Other Deliverables
    </ActivityFormInputLabel>
    <TextInput
      value={otherActivityDeliverable ?? ''}
      onChange={(e) => onOtherActivityDeliverableChanged(e.target.value)}
      placeholder="Type here..."
      containerClassName="w-3/4"
    />
  </div>
);
