import React, { FC, useState } from 'react';
import { RemoveUserSuccessCard } from 'components/RemoveUserSuccessCard/RemoveUserSuccessCard';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getDeletedUserById } from 'api/user/getDeletedUserById';
import { User } from 'shared/lib/types/User';
import formatName from 'shared/lib/utils/formatName';

interface Props {
  userId: number;
}

export const RemoveUserSuccess: FC<Props> = ({ userId }) => {
  const [user, setUser] = useState<User>();
  useAsyncEffect(async () => {
    const deletedUser = await getDeletedUserById(userId);
    if (deletedUser) {
      setUser(deletedUser);
    }
  }, []);

  return (
    <UsersManagementPage>
      <RemoveUserSuccessCard
        userFullName={user ? formatName(user) : ''}
      />
    </UsersManagementPage>
  );
};
