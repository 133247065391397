import React, { ComponentPropsWithoutRef, FC } from 'react';

interface Props extends ComponentPropsWithoutRef<'button'> {
  notificationCount: number;
}

export const NotificationAlertBadge: FC<Props> = ({
  notificationCount,
  className = '',
  ...rest
}) => (
  <button
    className={`px-4 py-2.5 rounded bg-blue-400 text-white text-sm font-sf-pro-medium text-center whitespace-nowrap ${className}`}
    {...rest}
  >
    ({notificationCount}) New Notification{notificationCount !== 1 && 's'}
  </button>
);
