import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Activity } from 'shared/lib/types/Activity';

type Props = ComponentPropsWithoutRef<'div'> &
  Pick<Activity, 'activityType' | 'otherActivityType'>;

export const ActivityTypeDetail: FC<Props> = ({
  activityType,
  otherActivityType,
  ...props
}) => (
  <ActivityDetailSegment heading="Activity Type" {...props}>
    <div className="detail-text mt-1">
      {activityType?.map((type) => (
        <div key={type.id}>
          <div>{type.activityTypeKind?.name}</div>
          {type.presenterName && (
            <div className="ml-3 text-black">{type.presenterName}</div>
          )}
        </div>
      ))}
      {otherActivityType && <div>{otherActivityType}</div>}
    </div>
  </ActivityDetailSegment>
);
