import React, { FC, useCallback, useState } from 'react';
import { LoginForm } from 'components/LoginForm/LoginForm';
import { PublicPageWrapper } from 'components/PublicPageWrapper/PublicPageWrapper';
import { SemiBoldHeader } from 'components/SemiBoldHeader/SemiBoldHeader';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

interface LoginProps {
  login: (email: string, password: string) => Promise<void>;
}

export const Login: FC<LoginProps> = ({ login }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { snackbar } = useSnackbarContext();

  const handleSubmit = useCallback(async () => {
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER, timeout: false });
    try {
      await login(email, password);
      snackbar.hide();
    } catch (error) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(error),
      });
    }
  }, [login, email, password, snackbar]);

  return (
    <PublicPageWrapper>
      <SemiBoldHeader className="mb-16" text="Welcome Back!" />
      <LoginForm
        email={email}
        password={password}
        onEmailChange={(value) => setEmail(value)}
        onPasswordChange={(value) => setPassword(value)}
        onSubmit={handleSubmit}
      />
    </PublicPageWrapper>
  );
};
