import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';
import { NumberCircle } from 'components/NumberCircle/NumberCircle';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';

interface Props extends ComponentPropsWithoutRef<'div'> {
  index: number;
  label: string;
  onRemove(): void;
}
export const RemoveableDropdownSelectItem: FC<Props> = ({
  index,
  label,
  onRemove,
  className,
  ...props
}) => (
  <Row {...props} className={`items-center justify-between ${className}`}>
    <NumberCircle numberValue={index} />
    <Row className="items-center flex-1 whitespace-nowrap overflow-hidden">
      <div className="font-sf-pro-medium ml-2.5 truncate">{label}</div>
    </Row>
    <div>
      <CircularActionButton
        className="w-6 h-6"
        icon={CircularActionIconKind.X_GREEN}
        onClick={onRemove}
      />
    </div>
  </Row>
);
