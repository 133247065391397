import { apiAxios } from 'api/apiAxios';
import { User } from 'shared/lib/types/User';

interface UserSession {
  user: User;
  logoutTimestamp: number | null;
}

export async function getUser(): Promise<UserSession | null> {
  const res = await apiAxios.get<UserSession>('/auth/user').catch(() => {
    return null;
  });

  return res?.data ?? null;
}
