import React, { ComponentPropsWithoutRef, FC } from 'react';
import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { Activity } from 'shared/lib/types/Activity';
import { County } from 'shared/lib/types/County';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { User } from 'shared/lib/types/User';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { Row } from 'components/Row/Row';
import { ListWithCounter } from 'components/ListWithCounter/ListWithCounter';
import { formatUserNameAndRegion } from 'utils/formatUserNameAndRegion';
import { sortUsersByRegion } from 'utils/sortUsersByRegion';

interface Props
  extends ComponentPropsWithoutRef<'div'>,
    Pick<Activity, 'areaInvolved'> {
  counties: County[];
  schoolDistricts: SchoolDistrict[];
  attendees: User[];
}

export const ActivityAreaInvolvedDetail: FC<Props> = ({
  counties,
  schoolDistricts,
  attendees,
  areaInvolved,
  ...props
}) => (
  <ActivityDetailSegment heading="Area Involved" {...props}>
    {areaInvolved === AreaInvolved.COUNTY && (
      <Row className="mt-1.5">
        <ListWithCounter
          className="flex-1"
          label="Counties"
          list={counties.map(({ id, name }) => ({
            id: id,
            value: name,
          }))}
        />
        <ListWithCounter
          className="flex-1"
          label="School Districts"
          list={schoolDistricts.map(({ id, name }) => ({
            id: id,
            value: name,
          }))}
        />
      </Row>
    )}
    <div className="detail-text">
      {areaInvolved === AreaInvolved.REGION && <div>Region</div>}
      {areaInvolved === AreaInvolved.STATEWIDE && <div>Statewide</div>}
    </div>

    <ListWithCounter
      className="mt-7"
      label="Inter-regional Attendees"
      list={sortUsersByRegion(attendees).map((user) => ({
        id: user.id,
        value: formatUserNameAndRegion(user),
      }))}
    />
  </ActivityDetailSegment>
);
