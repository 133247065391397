import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  FormEvent,
  useMemo,
} from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityAudienceType } from 'shared/lib/types/ActivityAudienceType';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { isSuperViewer } from 'shared/lib/utils/isSuperAdmin';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { formatUpdateActivityRoute } from 'shared/lib/constants/routes/activityRoutes';
import { User } from 'shared/lib/types/User';
import { getActivitySchoolYearRange } from 'shared/lib/utils/getActivitySchoolYearRange';
import { getNotNullish } from 'shared/lib/utils/validate';
import { getActivityTypes } from 'api/activityType/getActivityTypes';
import { getActivityAudienceTypes } from 'api/activityAudience/getActivityAudienceTypes';
import { emailActivity } from 'api/activity/emailActivity';
import { getUserActivities } from 'api/activity/getUserActivities';
import { deleteActivity } from 'api/activity/deleteActivity';
import { getActivityDeliverables } from 'api/activiteDeliverable/getActivityDeliverables';
import { getUsers } from 'api/user/getUsers';
import { useRegionContext } from 'contexts/regionContext';
import { useUserContext } from 'contexts/userContext';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { useActivityTitleSearch } from 'hooks/useActivityTitleSearch';
import { useActivityFilterForm } from 'hooks/useActivityFilterForm';
import { useSearch } from 'hooks/useSearch';
import { useActivityFilter } from 'hooks/useActivityFilter';
import { useActivityDateRangeFilter } from 'hooks/useActivityDateRangeFilter';
import { useActivityOwnerFilter } from 'hooks/useActivityOwnerFilter';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';
import { useHistory } from 'react-router-dom';
import { ActivityDetail } from 'components/ActivityDetail/ActivityDetail';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import { SearchTextInput } from 'components/SearchTextInput/SearchTextInput';
import { ActivityCardList } from 'components/ActivityCardList/ActivityCardList';
import { ActivityListFilterForm } from 'components/ActivityListFilterForm/ActivityListFilterForm';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { ActivityFilterChips } from 'components/ActivityFilterChips/ActivityFilterChips';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import { RoundFilterButton } from 'components/RoundFilterButton/RoundFilterButton';
import { ShareActivityMenu } from 'components/ShareActivityMenu/ShareActivityMenu';
import { EmailActivityFormModal } from 'components/EmailActivityFormModal/EmailActivityFormModal';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { DeleteActivityModal } from 'components/DeleteActivityModal/DeleteActivityModal';
import { PrintActivityModal } from 'components/PrintActivityModal/PrintActivityModal';
import { QuarterSelectInput } from 'components/QuarterSelectInput/QuarterSelectInput';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { ActivityOwnerRadioInput } from 'components/ActivityOwnerRadioInput/ActivityOwnerRadioInput';
import { ActivityOwnerFilter } from 'enums/ActivityOwnerFilter';
import { downloadActivityFile } from 'api/activity/downloadActivityFile';
import { ActivityFile } from 'shared/lib/types/ActivityFile';
import { downloadActivitiesCsv } from 'utils/downloadActivitiesCsv';
import { filterVisibleUsers } from 'utils/filterVisibleUsers';
import { sortUsersByRegion } from 'utils/sortUsersByRegion';
import { useQuarterDateRange } from 'hooks/useQuarterDateRange';

const leftColClassName = 'pr-5 w-2/6';
const rightColClassName = 'w-4/6 print:w-full';

interface Props {
  activityId?: number;
}
export const ActivityListPage: FC<Props> = ({ activityId }) => {
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<Activity>();
  const [showFilters, setShowFilters] = useState(false);
  const [showEmailActivityModal, setShowEmailActivityModal] = useState(false);
  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);
  const [activityEmailRecipient, setActivityEmailRecipient] = useState('');
  const [showPrintActivityModal, setShowPrintActivityModal] = useState(false);
  const [activityTypeKindList, setActivityTypeKindList] = useState<
    ActivityTypeKind[]
  >([]);
  const [activityAudienceList, setActivityAudienceList] = useState<
    ActivityAudienceType[]
  >([]);
  const [
    activityDeliverableKindList,
    setActivityDeliverableKindList,
  ] = useState<ActivityDeliverableKind[]>([]);
  const { user } = useUserContext();
  const { regionList, countyList, schoolDistrictList } = useRegionContext();
  const [appUserList, setAppUserList] = useState<User[]>([]);
  const history = useHistory();
  const { snackbar } = useSnackbarContext();
  const { onSearchChanged, search } = useSearch();
  const [activityOwnerFilter, setActivityOwnerFilter] = useState(
    ActivityOwnerFilter.CREATOR,
  );
  const {
    quarterDateRange,
    setYearQuarter,
    setStartDate,
    setEndDate,
  } = useQuarterDateRange();
  const {
    activityFilter,
    filteredCounties,
    filteredSchoolDistricts,
    ...restOfActivityFilterFormProps
  } = useActivityFilterForm({ countyList, schoolDistrictList });

  const canViewEverything = isSuperViewer(user);

  const [earliestQuarter1Year, latestQuarter1Year] = useMemo(() => {
    return getActivitySchoolYearRange(activityList);
  }, [activityList]);

  const filteredActivities = useActivityFilter({
    activityList: useActivityTitleSearch({
      activityList: useActivityDateRangeFilter({
        startDate: quarterDateRange.startDate,
        endDate: quarterDateRange.endDate,
        activityList: useActivityOwnerFilter({
          userId: user?.id ?? NaN,
          activityOwnerFilter,
          activityList,
        }),
      }),
      search,
    }),
    activityFilter,
  });

  const handleSaveFilter = useCallback(() => {
    setShowFilters(false);
    if (filteredActivities.length) {
      setSelectedActivity(filteredActivities[0]);
    } else {
      setSelectedActivity(undefined);
    }
    history.push(activityListRoute);
  }, [filteredActivities, history]);

  const handleEmailActivity = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        snackbar.show({
          severity: SnackbarSeverityKind.SPINNER,
          text: 'Sending Activity. Please wait...',
        });
        if (selectedActivity?.id && activityEmailRecipient) {
          await emailActivity({
            activityId: selectedActivity?.id,
            recipientEmail: activityEmailRecipient,
          });
        } else {
          throw new Error('Email Address is required');
        }
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'Activity Sent',
        });
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [activityEmailRecipient, selectedActivity?.id, snackbar],
  );

  const handleDeleteActivity = useCallback(async () => {
    if (selectedActivity) {
      try {
        snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
        await deleteActivity(selectedActivity.id);
        setActivityList((prevState) =>
          prevState.filter((activity) => activity.id !== selectedActivity.id),
        );
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'Activity Deleted',
        });
        history.push(activityListRoute);
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: 'Unable to delete activity.',
        });
      } finally {
        setShowDeleteActivityModal(false);
      }
    }
  }, [selectedActivity, snackbar, history]);

  const handleEditActivityClick = useCallback(() => {
    if (selectedActivity) {
      history.push(formatUpdateActivityRoute(selectedActivity.id));
    }
  }, [selectedActivity, history]);

  const handleShowEmailModal = useCallback(() => {
    setShowEmailActivityModal(true);
    setShowPrintActivityModal(false);
  }, []);

  const handleShowPrintModal = useCallback(() => {
    setShowPrintActivityModal(true);
    setShowEmailActivityModal(false);
  }, []);

  const handlePrintConfirm = useCallback(() => {
    window.print();
    setShowPrintActivityModal(false);
  }, []);

  const handleDownloadActivityFile = useCallback(
    async (activityFile: ActivityFile) => {
      if (selectedActivity) {
        await downloadActivityFile(selectedActivity.id, activityFile);
      }
    },
    [selectedActivity],
  );

  const handleDownloadCsv = useCallback(() => {
    downloadActivitiesCsv(filteredActivities);
  }, [filteredActivities]);

  useAsyncEffect(async (isCancelled) => {
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
    setLoading(true);

    try {
      const [
        activities,
        activityTypes,
        activityAudienceTypes,
        activityDeliverableTypes,
        appUsers,
      ] = await Promise.all([
        getUserActivities(),
        getActivityTypes(),
        getActivityAudienceTypes(),
        getActivityDeliverables(),
        getUsers(),
      ]);

      if (!isCancelled()) {
        setActivityList(activities ?? []);
        setActivityTypeKindList(activityTypes ?? []);
        setActivityAudienceList(activityAudienceTypes ?? []);
        setActivityDeliverableKindList(activityDeliverableTypes ?? []);
        // getUsers() exludes the logged in user, so add them back in.
        // The non-nullish assertion shuld never fail because the API calls
        // would alredy fail if the user was not logged in.
        appUsers.push(getNotNullish(user));
        setAppUserList(sortUsersByRegion(filterVisibleUsers(appUsers)));
        snackbar.hide();
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (activityId) {
      setSelectedActivity(
        activityList.find((activity) => activity.id === activityId),
      );
    }
  }, [activityId, activityList]);

  useEffect(() => {
    if (filteredActivities.length) {
      setSelectedActivity(filteredActivities[0]);
    } else {
      setSelectedActivity(undefined);
    }
  }, [filteredActivities]);

  return (
    <div>
      {!loading && (
        <>
          <Row className="w-100 justify-between mb-8 mt-6 no-print">
            <div className={`relative ${leftColClassName}`}>
              <SearchTextInput
                onSearchChange={onSearchChanged}
                searchValue={search}
              />
              <QuarterSelectInput
                className="block mx-auto w-52 mt-2"
                value={quarterDateRange.yearQuarter}
                onYearQuarterChange={setYearQuarter}
                earliestQuarter1Year={earliestQuarter1Year}
                latestQuarter1Year={latestQuarter1Year}
                isDateRangeAlignedWithQuarter={
                  quarterDateRange.isDateRangeAlignedWithQuarter
                }
              />
              {canViewEverything && (
                <DateRangePicker
                  startDate={quarterDateRange.startDate}
                  endDate={quarterDateRange.endDate}
                  onChangeStartDate={setStartDate}
                  onChangeEndDate={setEndDate}
                />
              )}
            </div>
            <Row className={`${rightColClassName} items-center h-14 pr-10`}>
              <Row className="flex-shrink-0 gap-x-3">
                <RoundFilterButton
                  active={showFilters}
                  onClick={() => setShowFilters(true)}
                />
                <ShareActivityMenu
                  active={showEmailActivityModal}
                  onEmailClick={handleShowEmailModal}
                  onPrintClick={handleShowPrintModal}
                />
                {canViewEverything && (
                  <CircularActionButton
                    icon={CircularActionIconKind.DOWNLOAD}
                    onClick={handleDownloadCsv}
                  />
                )}
              </Row>
              <ActivityFilterChips
                className="ml-6"
                regionList={regionList}
                countyList={countyList}
                schoolDistrictList={schoolDistrictList}
                activityDeliverableKindList={activityDeliverableKindList}
                activityAudienceList={activityAudienceList}
                activityTypeKindList={activityTypeKindList}
                appUserList={appUserList}
                {...activityFilter}
                {...restOfActivityFilterFormProps}
              />
              <ActivityOwnerRadioInput
                className="flex-shrink-0"
                value={activityOwnerFilter}
                onChange={setActivityOwnerFilter}
              />
            </Row>
          </Row>
          <Row>
            <Column
              className={`${leftColClassName} ${
                showFilters ? 'pointer-events-none' : ''
              } no-print`}
            >
              <ActivityCardList
                className="h-screen-full overflow-scroll"
                collaboratorId={user?.id}
                activeActivityId={selectedActivity?.id}
                activityList={filteredActivities}
              />
              {!filteredActivities.length && (
                <div className="text-gray-800 font-sf-pro-light text-base text-center">
                  No activities found.
                </div>
              )}
            </Column>
            <Column className={rightColClassName}>
              {showEmailActivityModal && (
                <EmailActivityFormModal
                  className="mb-12"
                  email={activityEmailRecipient}
                  onEmailChange={(email) => setActivityEmailRecipient(email)}
                  onClose={() => setShowEmailActivityModal(false)}
                  onSubmit={handleEmailActivity}
                />
              )}
              {showDeleteActivityModal && (
                <DeleteActivityModal
                  className="mb-6"
                  onDeleteConfirm={handleDeleteActivity}
                  onClose={() => setShowDeleteActivityModal(false)}
                />
              )}
              {showPrintActivityModal && (
                <PrintActivityModal
                  className="mb-12 no-print"
                  onPrintConfirm={handlePrintConfirm}
                  onClose={() => setShowPrintActivityModal(false)}
                />
              )}
              {showFilters ? (
                <ActivityDetailSegment heading={'Filter List'}>
                  <ActivityListFilterForm
                    regionList={regionList}
                    filteredCounties={filteredCounties}
                    filteredSchoolDistricts={filteredSchoolDistricts}
                    activityDeliverableKindList={activityDeliverableKindList}
                    activityAudienceList={activityAudienceList}
                    activityTypeKindList={activityTypeKindList}
                    appUserList={appUserList}
                    onSave={handleSaveFilter}
                    {...activityFilter}
                    {...restOfActivityFilterFormProps}
                    canViewEverything={canViewEverything}
                  />
                </ActivityDetailSegment>
              ) : (
                selectedActivity && (
                  <ActivityDetail
                    onDeleteActivityClick={() =>
                      setShowDeleteActivityModal(true)
                    }
                    onEditActivityClick={handleEditActivityClick}
                    onDownloadActivityFile={handleDownloadActivityFile}
                    activity={selectedActivity}
                  />
                )
              )}
            </Column>
          </Row>
        </>
      )}
    </div>
  );
};
