import React, { ComponentPropsWithoutRef, FC } from 'react';
import './RadioButton.css';

export interface RadioButtonProps extends ComponentPropsWithoutRef<'input'> {
  label: string;
  checked?: boolean;
  type: 'radio' | 'checkbox';
  radioSizeClassname?: 'sm' | 'md';
}

export const RadioButton: FC<RadioButtonProps> = ({
  label,
  checked,
  type,
  className = '',
  radioSizeClassname = 'md',
  ...props
}) => {
  const smCheckmark = radioSizeClassname === 'sm';
  return (
    <label
      className={`flex items-center radio-text ${className} ${
        smCheckmark ? 'text-sm font-sf-pro-semibold leading-4' : ''
      }`}
    >
      <div className={`radio-label ${smCheckmark ? 'mb-4 pl-7' : 'mb-6 pl-9'}`}>
        <input
          type={type}
          className="absolute opacity-0 cursor-pointer"
          checked={checked}
          {...props}
        />
        <span className={`checkmark ${smCheckmark ? 'checkmark-sm' : 'checkmark-md'}`}></span>
      </div>
      <div>{label}</div>
    </label>
  );
};
