import React, { FC } from 'react';
import {
  userDetailRoute,
  userRemoveRoute,
  userEditRoute,
  userRemoveSuccessRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { Route, Switch } from 'react-router-dom';
import { EditUser } from 'components/EditUser/EditUser';
import { UserDetail } from 'components/UserDetail/UserDetail';
import { RemoveUser } from 'components/RemoveUser/RemoveUser';
import { RemoveUserSuccess } from 'components/RemoveUserSuccess/RemoveUserSuccess';

interface UserRoutesProps {
  userId: number;
}

export const UserRoutes: FC<UserRoutesProps> = ({ userId }) => {
  return (
    <Switch>
      <Route exact path={userDetailRoute}>
        <UserDetail userId={userId} />
      </Route>
      <Route exact path={userEditRoute}>
        <EditUser userId={userId} />;
      </Route>
      <Route exact path={userRemoveRoute}>
        <RemoveUser userId={userId} />;
      </Route>
      <Route exact path={userRemoveSuccessRoute}>
        <RemoveUserSuccess userId={userId} />
      </Route>
    </Switch>
  );
};
