import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import React, { ComponentPropsWithoutRef, FC } from 'react';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  startDate: Date;
  endDate: Date;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
}

export const DateRangePicker: FC<Props> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  className,
  ...props
}) => (
  <div {...props} className={`flex justify-center gap-x-8 ${className}`}>
    <DatePickerInput
      label="Range start"
      selected={startDate}
      maxDate={endDate}
      onChange={onChangeStartDate}
      className="w-40"
    />
    <DatePickerInput
      label="Range end"
      selected={endDate}
      minDate={startDate}
      onChange={onChangeEndDate}
      className="w-40"
    />
  </div>
);
