import { apiAxios } from 'api/apiAxios';
import { User } from 'shared/lib/types/User';

interface Request extends Omit<User, 'role' | 'region'> {
  region?: string;
  role?: string;
}

export async function updatePreEtsSpecialistUser(
  userId: number,
  request: Request,
) {
  const response = await apiAxios.patch(`/pre-ets/${userId}`, request);
  return response.data;
}
