import React, { FC, ReactNode } from 'react';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { Row } from 'components/Row/Row';

export interface FormFieldTitleProps {
  className?: string;
  label: ReactNode;
  infoText?: ReactNode;
}

export const FormFieldTitle: FC<FormFieldTitleProps> = ({
  className = '',
  label,
  infoText,
}) => (
  <Row className={`items-center font-sf-pro-semibold ${className}`}>
    {label}
    {infoText && (
      <InfoTooltip className="ml-2.5" position="top" text={infoText} />
    )}
  </Row>
);
