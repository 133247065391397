import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityFile } from 'shared/lib/types/ActivityFile';
import { getFileExtension } from 'shared/lib/utils/getFileExtension';
import { FileIcon } from 'components/FileIcon/FileIcon';

interface Props extends ComponentPropsWithoutRef<'div'> {
  files: ActivityFile[];
  onDownloadActivityFile(activityFile: ActivityFile): void;
}

export const ActivityDetailFileList: FC<Props> = ({
  files,
  onDownloadActivityFile,
  ...rest
}) => (
  <div {...rest}>
    {files.map(({ filename, key, ...restOfActivityFile }) => (
      <button
        className="flex flex-row items-center mb-2.5"
        onClick={() =>
          onDownloadActivityFile({ filename, key, ...restOfActivityFile })
        }
        key={key}
      >
        <FileIcon fileExtension={getFileExtension(filename)} />
        <div className="text-xs font-sf-pro-medium text-gray-700 ml-2">
          {filename}
        </div>
      </button>
    ))}
  </div>
);
