import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';

interface Props extends ComponentPropsWithoutRef<'button'> {
  active?: boolean;
}

export const RoundFilterButton: FC<Props> = ({ active, ...props }) => (
  <CircularActionButton
    icon={
      active
        ? CircularActionIconKind.FILTER_ACTIVE
        : CircularActionIconKind.FILTER
    }
    {...props}
  />
);
