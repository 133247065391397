import { useMemo } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { stringContainsString } from 'shared/lib/utils/stringContainsString';

interface UserNameSearchParams {
  activityList: Activity[];
  search: string;
}

export function useActivityTitleSearch({
  activityList,
  search,
}: UserNameSearchParams): Activity[] {
  return useMemo(() => {
    return activityList.filter((activityList) =>
      stringContainsString(activityList.title, search),
    );
  }, [activityList, search]);
}
