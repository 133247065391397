import React, { FC } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';

interface Props {
  userFullName: string;
}
export const RemoveUserSuccessCard: FC<Props> = ({ userFullName }) => {
  return (
    <CloseableCard>
      <>
        <div className="card-header">User Removed</div>
        <div>
          User ‘{userFullName}’ has been removed. Select another user to view.
        </div>
      </>
    </CloseableCard>
  );
};
