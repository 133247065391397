import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  onCancelConfirm(): void;
}
export const CancelActivityConfirmationModal: FC<Props> = ({
  onClose,
  onCancelConfirm,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Cancel Activity Entry</div>
    <div>Cancelling an Activity Entry will remove any progress.</div>
    <ButtonGroup className="mt-9 border border-white">
      <button className="text-white" onClick={onClose}>
        No
      </button>
      <button className="bg-white text-blue-400" onClick={onCancelConfirm}>
        Yes, Cancel
      </button>
    </ButtonGroup>
  </CloseableCard>
);
