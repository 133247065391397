import { ToggleInput } from 'components/ToggleInput/ToggleInput';
import React, { FC } from 'react';

interface Props {
  selected: boolean;
  onChange(value: boolean): void;
}

export const QuarterlyReportToggleInput: FC<Props> = ({
  selected,
  onChange,
  ...props
}) => (
  <div {...props}>
    <ToggleInput
      label="Mark for Quarterly Report"
      checked={selected}
      onChange={() => onChange(!selected)}
    />
  </div>
);
