import React, { FC } from 'react';
import {
  RadioButton,
  RadioButtonProps,
} from 'components/RadioButton/RadioButton';

interface Props extends Omit<RadioButtonProps, 'type'> {
  label: string;
  checked?: boolean;
}

export const CheckboxRadioInput: FC<Props> = ({ ...props }) => (
  <RadioButton type="checkbox" {...props} />
);
