import React, { ComponentPropsWithoutRef, FC } from 'react';
interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  className?: string;
  inverse?: boolean;
}

const filledStyles = 'btn-green';
const inverseStyles = 'btn-green-outline';

export const Button: FC<ButtonProps> = ({
  className = '',
  inverse,
  ...props
}) => {
  return (
    <button
      className={`text-sm font-sf-pro-medium disabled:opacity-80 ${
        inverse ? inverseStyles : filledStyles
      } ${className} `}
      {...props}
    />
  );
};
