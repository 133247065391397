import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import {
  usersRoute,
  userDetailRoute,
  userCreateRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { CommonRoutes } from './CommonRoutes';
import { UserRoutes } from './sub-routes/UserRoutes';
import { AuthenticatedPageWrapper } from 'components/AuthenticatedPageWrapper/AuthenticatedPageWrapper';
import { CreateUser } from 'components/CreateUser/CreateUser';

export function AdminRoutes() {
  return (
    <BrowserRouter>
      <AuthenticatedPageWrapper>
        <Switch>
          <Route exact path={usersRoute}>
            <UsersManagementPage />
          </Route>
          <Route exact path={userCreateRoute}>
            <CreateUser />;
          </Route>
          <Route path={userDetailRoute}>
            {({ match }) => {
              if (!match) {
                return null;
              }

              const userId = +match.params.id;
              if (Number.isNaN(userId)) {
                return <Redirect to={usersRoute} />;
              }
              return <UserRoutes userId={userId} />;
            }}
          </Route>

          <CommonRoutes />
        </Switch>
      </AuthenticatedPageWrapper>
    </BrowserRouter>
  );
}
