import React, { FC, useCallback } from 'react';
import { ActivityForm } from 'components/ActivityForm/ActivityForm';
import { useActivityFormSubmit } from 'hooks/useActivityFormSubmit';
import { FillableActivityFormFields } from 'types/FillableActivityFormFields';
import { useHistory } from 'react-router-dom';
import { formatUpdateActivityRoute } from 'shared/lib/constants/routes/activityRoutes';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { SnackbarProps } from 'components/Snackbar/Snackbar';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { ActivityFormStep } from 'enums/ActivityFormStep';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';

export const CreateActivityPage: FC = () => {
  const { onCreateActivity } = useActivityFormSubmit();
  const { onCurrentStepChanged, regionalCounties } = useActivityFormContext();
  const history = useHistory();

  const handleCreateActivityAndReturnToList = useCallback(
    async (activity: FillableActivityFormFields) => {
      const createdActivity = await onCreateActivity(activity);
      if (createdActivity) {
        onCurrentStepChanged(ActivityFormStep.OVERVIEW);
        history.push({
          pathname: activityListRoute,
          state: {
            snackbarProps: {
              severity: SnackbarSeverityKind.SUCCESS,
              text: 'Activity created successfully!',
            } as SnackbarProps,
          },
        });
      }
    },
    [history, onCreateActivity, onCurrentStepChanged],
  );

  const handleCreateActivityAndContinue = useCallback(
    async (activity: FillableActivityFormFields) => {
      const createdActivity = await onCreateActivity(activity);
      if (createdActivity) {
        history.push(formatUpdateActivityRoute(createdActivity.id));
      }
    },
    [history, onCreateActivity],
  );

  return (
    <ActivityForm
      regionalCounties={regionalCounties}
      appBarHeaderLabel="Add New Activity Entry"
      onSubmitAndReturnToList={handleCreateActivityAndReturnToList}
      onSubmitAndContinue={handleCreateActivityAndContinue}
    />
  );
};
