import { useState, useCallback, useEffect } from 'react';
import { TechnicalAssistanceMode } from 'shared/lib/constants/activity/TechnicalAssistanceMode';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityCollaborator } from 'shared/lib/types/ActivityCollaborator';
import { dateObjectToISODate } from 'shared/lib/utils/formatDate';
import { FillableActivityOverviewFormProps } from 'types/FillableActivityOverviewFormProps';

const defaultOverviewFields: FillableActivityOverviewFormProps = {
  title: '',
  entryDate: dateObjectToISODate(new Date()),
  duration: null,
  travelInvolved: null,
  followUp: false,
  technicalAssistanceMode: TechnicalAssistanceMode.ON_SITE,
  collaborators: [],
  markedForQuarterlyReport: false,
  complete: false,
};

export interface ActivityOverviewFormReturnValues {
  overviewFields: FillableActivityOverviewFormProps;
  onTitleChanged(title: string): void;
  onEntryDateChanged(entryDate: string): void;
  onDurationChanged(duration: number): void;
  onTravelInvolvedChanged(travelInvolved: number): void;
  onFollowUpChanged(followUp: boolean): void;
  onCollaboratorsChanged(collaborators: ActivityCollaborator[]): void;
  onMarkedForQuarterlyReportChanged(markedForQuarterlyReport: boolean): void;
  onTechnicalAssistanceModeChanged(
    technicalAssistanceMode: TechnicalAssistanceMode,
  ): void;
}

export function useActivityOverviewForm(
  activity?: Activity,
): ActivityOverviewFormReturnValues {
  const [overviewFields, setOverviewFields] = useState<
    FillableActivityOverviewFormProps
  >(defaultOverviewFields);

  const onTitleChanged = useCallback((title: string) => {
    setOverviewFields((prevState) => ({
      ...prevState,
      title,
    }));
  }, []);

  const onEntryDateChanged = useCallback((entryDate: string) => {
    setOverviewFields((prevState) => ({
      ...prevState,
      entryDate,
    }));
  }, []);

  const onDurationChanged = useCallback((duration: number) => {
    setOverviewFields((prevState) => ({
      ...prevState,
      duration,
    }));
  }, []);

  const onTravelInvolvedChanged = useCallback((travelInvolved: number) => {
    setOverviewFields((prevState) => ({
      ...prevState,
      travelInvolved,
    }));
  }, []);

  const onFollowUpChanged = useCallback((followUp: boolean) => {
    setOverviewFields((prevState) => ({
      ...prevState,
      followUp,
    }));
  }, []);

  const onTechnicalAssistanceModeChanged = useCallback(
    (technicalAssistanceMode: TechnicalAssistanceMode) => {
      setOverviewFields((prevState) => ({
        ...prevState,
        technicalAssistanceMode,
      }));
    },
    [],
  );

  const onCollaboratorsChanged = useCallback(
    (collaborators: ActivityCollaborator[]) => {
      setOverviewFields((prevState) => ({
        ...prevState,
        collaborators,
      }));
    },
    [],
  );

  const onMarkedForQuarterlyReportChanged = useCallback(
    (markedForQuarterlyReport: boolean) => {
      setOverviewFields((prevState) => ({
        ...prevState,
        markedForQuarterlyReport,
      }));
    },
    [],
  );

  useEffect(() => {
    if (activity) {
      setOverviewFields({
        title: activity.title,
        entryDate: activity.entryDate,
        duration: activity.duration,
        travelInvolved: activity.travelInvolved,
        followUp: activity.followUp,
        technicalAssistanceMode: activity.technicalAssistanceMode,
        collaborators: activity.collaborators,
        markedForQuarterlyReport: activity.markedForQuarterlyReport,
        complete: activity.complete,
      });
    }
  }, [activity]);

  return {
    overviewFields,
    onTitleChanged,
    onEntryDateChanged,
    onDurationChanged,
    onTravelInvolvedChanged,
    onFollowUpChanged,
    onTechnicalAssistanceModeChanged,
    onMarkedForQuarterlyReportChanged,
    onCollaboratorsChanged,
  };
}
