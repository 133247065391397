import { createContext, useContext } from 'react';
import { County } from 'shared/lib/types/County';
import { Region } from 'shared/lib/types/Region';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';

export interface RegionContextValue {
  loading: boolean;
  regionList: Region[];
  countyList: County[];
  schoolDistrictList: SchoolDistrict[];
}

export const RegionContext = createContext<RegionContextValue>({
  loading: false,
  regionList: [],
  countyList: [],
  schoolDistrictList: [],
});

export function useRegionContext() {
  return useContext(RegionContext);
}
