import { apiAxios } from 'api/apiAxios';

export async function uploadActivityFiles(activityId: number, files: File[]) {
  let formData = new FormData();
  for (let file of files) {
    formData.append(file.name, file);
  }

  const response = await apiAxios.put(
    `/activity/${activityId}/fileUpload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
}
