import React, { FC } from 'react';
import { isSuperAdmin } from 'shared/lib/utils/isSuperAdmin';
import { User } from 'shared/lib/types/User';
import { AdminApp } from './AdminApp';
import { UserApp } from './UserApp';
import { useRegion } from 'hooks/useRegion';
import { RegionContext } from 'contexts/regionContext';

interface Props {
  user: User;
}
export const AuthenticatedApp: FC<Props> = ({ user }) => {
  const { regionList, loading, ...restofRegionContextValues } = useRegion();
  return (
    <RegionContext.Provider
      value={{
        regionList,
        loading,
        ...restofRegionContextValues,
      }}
    >
      {!loading && (isSuperAdmin(user) ? <AdminApp /> : <UserApp />)}
    </RegionContext.Provider>
  );
};
