import React, { FC } from 'react';
import { tnfLogo } from 'assets/images';

export const AppBar: FC = ({ children }) => (
  <div className="flex flex-row items-center justify-between h-32 px-16 no-print">
    <img src={tnfLogo} alt="TNF Logo" className="h-20 w-auto ml-8" />
    {/* Option for for navigation component */}
    {children}
  </div>
);
