import React, { ComponentPropsWithoutRef, useCallback } from 'react';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { greenTriangleIcon, triangleIcon } from 'assets/images';
import { DropdownProps } from 'types/Dropdown';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import './Dropdown.css';

export const Dropdown = <T extends unknown = string | number>({
  label,
  selectedLabel,
  className = '',
  options,
  readOnly,
  readOnlyDisabled,
  placeholder,
  optionItem,
  open,
  onOpenChange,
  disableClickOutside,
  ...props
}: ComponentPropsWithoutRef<'div'> & DropdownProps<T>) => {
  const handleClickOutside = useCallback(() => {
    if (!disableClickOutside) {
      onOpenChange(false);
    }
  }, [disableClickOutside, onOpenChange]);

  const dropDownOptionsRef = useOnClickOutside<HTMLDivElement>(
    handleClickOutside,
  );

  const enabledStyles = 'border-b-2 border-gray-500 cursor-pointer';
  const enabled = !readOnly && !readOnlyDisabled;

  return (
    <div
      className={`relative ${className}`}
      ref={dropDownOptionsRef}
      {...props}
    >
      <Column>
        <div className="input-label mb-1">{label}</div>
        <Row
          className={`pb-1 text-gray-500 text-sm justify-between items-center ${
            enabled ? enabledStyles : ''
          }`}
          onClick={() => {
            if (enabled) {
              onOpenChange(!open);
            }
          }}
        >
          <div
            className={`text-base font-sf-pro-medium ${
              readOnlyDisabled ? 'text-gray-600' : 'text-black'
            }`}
          >
            {readOnly || readOnlyDisabled
              ? selectedLabel
              : selectedLabel ?? placeholder}
          </div>
          {enabled && (
            <img
              src={open ? greenTriangleIcon : triangleIcon}
              alt="Triangle Icon"
              className={`duration-500 transform ${
                open ? 'rotate-180' : 'rotate-0'
              }`}
            />
          )}
        </Row>
      </Column>
      {/* AutoSize and List components (react-window) needed when rendering a long list options */}
      <AutoSizer>
        {({ width }) => (
          <List
            height={160}
            itemCount={options.length}
            itemSize={39}
            width={width}
            itemData={options}
            className={`bg-white border-2 -mt-0.5 border-tnf-green-400 w-full duration-500 h-40 overflow-y-scroll overflow-x-hidden ${
              open ? '' : 'hidden'
            } absolute z-50`}
          >
            {optionItem}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};
