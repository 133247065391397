import React, { FC } from 'react';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { ActivityAudienceForm } from 'components/ActivityAudienceForm/ActivityAudienceForm';
import { useActivityForm } from 'hooks/useActivityForm';
import { ActivityAudienceFormProps } from 'types/ActivityAudienceFormProps';

export const ActivityFormStep5: FC<Omit<
  ActivityAudienceFormProps,
  'activityAudienceCategories'
>> = (props) => {
  const { activityAudienceCategories } = useActivityForm();
  return (
    <ActivityFormStepWrapper heading="Audience">
      <ActivityAudienceForm
        activityAudienceCategories={activityAudienceCategories}
        {...props}
      />
    </ActivityFormStepWrapper>
  );
};
