import React, { FC, useCallback } from 'react';
import { DropdownOption } from 'types/Dropdown';
import {
  DropdownInput,
  DropdownInputProps,
} from 'components/DropdownInput/DropdownInput';
import { Region } from 'shared/lib/types/Region';

interface RegionsDropdownProps
  extends Omit<DropdownInputProps, 'onChange' | 'options'> {
  onChange: (option: DropdownOption) => void;
  regionList: Region[];
}

export const RegionsDropdown: FC<RegionsDropdownProps> = ({
  onChange,
  regionList,
  ...props
}) => {
  const handleDropdownChange = useCallback(
    (option) => {
      onChange(option);
    },
    [onChange],
  );
  return (
    <DropdownInput
      label="Region"
      options={regionList.map((region) => ({
        id: region.id,
        value: region.name,
      }))}
      onChange={handleDropdownChange}
      placeholder="Choose Region"
      {...props}
    />
  );
};
