import { apiAxios } from 'api/apiAxios';
import { County } from 'shared/lib/types/County';

export async function getCountiesFromRegionId(
  regionId: number,
): Promise<County[] | null> {
  const res = await apiAxios.get(`/county/${regionId}`);

  return res?.data ?? null;
}
