import React, { FC } from 'react';
import { checkmarkIcon, exclamationIcon, spinner } from 'assets/images';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';

interface Props {
  icon: SnackbarSeverityKind;
  className?: string;
}

interface ImageProps {
  src: string;
  alt: string;
}

const iconsMap = new Map<SnackbarSeverityKind, ImageProps>([
  [
    SnackbarSeverityKind.SUCCESS,
    { src: checkmarkIcon, alt: 'Circular Checkmark Icon' },
  ],
  [
    SnackbarSeverityKind.ERROR,
    { src: exclamationIcon, alt: 'Circular Exclamation Icon' },
  ],
  [
    SnackbarSeverityKind.SPINNER,
    { src: spinner, alt: 'Circular Spinner Icon' },
  ],
]);

export const SnackbarIcon: FC<Props> = ({ icon, className }) => {
  const imageSrc = iconsMap.get(icon)?.src;
  const imageAlt = iconsMap.get(icon)?.alt;

  return (
    <img
      src={imageSrc}
      alt={imageAlt}
      className={`h-5 w-auto ${className} ${
        icon === 'spinner' ? 'animate-spin' : ''
      }`}
    />
  );
};
