const amchartOregonCountyKeys = {
  umatilla: '41059',
  union: '41061',
  clatsop: '41007',
  columbia: '41009',
  tillamook: '41057',
  washington: '41067',
  multnomah: '41051',
  yamhill: '41071',
  clackamas: '41005',
  hoodriver: '41027',
  lincoln: '41041',
  polk: '41053',
  benton: '41003',
  lane: '41039',
  douglas: '41019',
  coos: '41011',
  curry: '41015',
  josephine: '41033',
  jackson: '41029',
  klamath: '41035',
  lake: '41037',
  harney: '41025',
  malheur: '41045',
  baker: '41001',
  grant: '41023',
  deschutes: '41017',
  jefferson: '41031',
  linn: '41043',
  marion: '41047',
  wasco: '41065',
  sherman: '41055',
  gilliam: '41021',
  morrow: '41049',
  utamilla: '41059',
  wheeler: '41069',
  crook: '41013',
  wallowa: '41063',
} as any;

export default amchartOregonCountyKeys;
