import React, { FC } from 'react';
import { ActivityAreaInvolvedForm } from 'components/ActivityAreaInvolvedForm/ActivityAreaInvolvedForm';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { formatUserNameAndRegion } from 'utils/formatUserNameAndRegion';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { ActivityAreaInvolvedFormProps } from 'types/ActivityAreaInvolvedFormProps';
import { useUserContext } from 'contexts/userContext';
import { County } from 'shared/lib/types/County';
import { useRegionContext } from 'contexts/regionContext';

interface Props
  extends Omit<
    ActivityAreaInvolvedFormProps,
    'regionalCountyOptions' | 'regionalSchoolDistrictOptions' | 'appUserOptions'
  > {
  regionalCounties: County[];
  canViewEverything?: boolean;
}

export const ActivityFormStep2: FC<Props> = ({
  counties,
  attendees,
  regionalCounties,
  selectedSuperAdminRegionId,
  canViewEverything,
  ...props
}) => {
  const { user } = useUserContext();
  const { appUsers } = useActivityFormContext();
  const { countyList } = useRegionContext();

  let countyOptions = regionalCounties;

  if (canViewEverything) {
    countyOptions = countyList.filter(
      (county) => county.regionId === selectedSuperAdminRegionId,
    );
  }

  // Get district options based on selected counties
  const selectedCountyIds = counties?.map((county) => county.countyId);
  const schoolDistricts = countyOptions
    .filter((county) => selectedCountyIds?.includes(county.id))
    .flatMap((county) => county.schoolDistricts as SchoolDistrict[]);

  // Dropdown options
  const regionalCountyDropdownOptions = countyOptions.map((county) => ({
    id: county.id,
    value: county.name,
  }));

  const regionalSchoolDistrictDropdownOptions =
    schoolDistricts?.map((schoolDistrict) => ({
      id: schoolDistrict.id,
      value: schoolDistrict.name,
    })) ?? [];

  return (
    <ActivityFormStepWrapper heading="Area Involved">
      <ActivityAreaInvolvedForm
        canViewEverything={canViewEverything}
        selectedSuperAdminRegionId={selectedSuperAdminRegionId}
        regionalCountyOptions={regionalCountyDropdownOptions}
        regionalSchoolDistrictOptions={regionalSchoolDistrictDropdownOptions}
        appUserOptions={(user &&
        attendees?.some((attendee) => attendee.userId === user.id)
          ? [...appUsers, user]
          : appUsers
        ).map((appUser) => ({
          id: appUser.id,
          value: formatUserNameAndRegion(appUser),
        }))}
        attendees={attendees}
        counties={counties}
        {...props}
      />
    </ActivityFormStepWrapper>
  );
};
