import React, { FC, useCallback, useState } from 'react';
import {
  formatUserEditRoute,
  formatUserRemoveSuccessRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { User } from 'shared/lib/types/User';
import formatName from 'shared/lib/utils/formatName';
import { UserManagementForm } from 'components/UserManagementForm/UserManagementForm';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { useHistory } from 'react-router';
import { RemoveUserConfirmationCard } from 'components/RemoveUserConfirmationCard/RemoveUserConfirmationCard';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { deleteUser } from 'api/user/deleteUser';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getUserById } from 'api/user/getUserById';
import getErrorMessage from 'utils/getErrorMessage';
import { noop } from 'shared/lib/utils/noop';

interface Props {
  userId: number;
}

export const RemoveUser: FC<Props> = ({ userId }) => {
  const [user, setUser] = useState<User>();
  const history = useHistory();
  const { snackbar } = useSnackbarContext();

  const handleDeleteUser = useCallback(async () => {
    try {
      snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
      if (userId) {
        await deleteUser(userId);

        history.push(formatUserRemoveSuccessRoute(userId));
      }
      snackbar.hide();
    } catch (e) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(e),
      });
    }
  }, [history, snackbar, userId]);

  useAsyncEffect(async () => {
    const user = await getUserById(userId);
    if (user) {
      setUser(user);
    }
  }, [userId]);

  return (
    <UsersManagementPage userId={userId}>
      {user && (
        <>
          <RemoveUserConfirmationCard
            onDelete={handleDeleteUser}
            onClose={() => {
              history.push(formatUserEditRoute(userId));
            }}
            userFullName={formatName(user)}
            className="mb-6 animate-slide-in"
          />

          <PageSegmentHeading>
            {formatName(user)}
          </PageSegmentHeading>
          <UserManagementForm user={user} onSubmit={noop} readOnly />
        </>
      )}
    </UsersManagementPage>
  );
};
