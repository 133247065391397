import React, { FC, useCallback } from 'react';
import { ActivityCollaborator } from 'shared/lib/types/ActivityCollaborator';
import { Column } from 'components/Column/Column';
import { FormFieldTitle } from 'components/FormFieldTitle/FormFieldTitle';
import {
  DropdownCheckboxInput,
  DropdownCheckboxInputProps,
} from 'components/DropdownCheckboxInput/DropdownCheckboxInput';

interface Props
  extends Pick<DropdownCheckboxInputProps, 'options' | 'selected'> {
  className?: string;
  onChange(attendees: Pick<ActivityCollaborator, 'userId'>[]): void;
}

export const ActivityCollaboratorsInput: FC<Props> = ({
  className = '',
  onChange,
  ...props
}) => {
  const handleCollaboratorDropdownChange = useCallback(
    (collaboratorIds: number[]) => {
      onChange(collaboratorIds.map((userId) => ({ userId })));
    },
    [onChange],
  );

  return (
    <Column className={className}>
      <FormFieldTitle
        className="mb-2"
        label="Collaborators"
        infoText="A Collaborator is an individual who worked closely with you on this activity. Adding a collaborator will make this activity appear in your collaborator’s activity list."
      />
      <DropdownCheckboxInput
        placeholder="Select collaborators"
        onChange={handleCollaboratorDropdownChange}
        {...props}
      />
    </Column>
  );
};
