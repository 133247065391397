import { Activity } from 'shared/lib/types/Activity';
import { ActivityOwnerFilter } from 'enums/ActivityOwnerFilter';
import { useMemo } from 'react';

export interface ActivityOwnerFilterProps {
  userId: number;
  activityOwnerFilter: ActivityOwnerFilter;
  activityList: Activity[];
}

export function useActivityOwnerFilter({
  userId,
  activityOwnerFilter,
  activityList,
}: ActivityOwnerFilterProps): Activity[] {
  return useMemo(() => {
    switch (activityOwnerFilter) {
      case ActivityOwnerFilter.CREATOR:
        return activityList.filter(({ user }) => user?.userId === userId);
      case ActivityOwnerFilter.COLLABORATOR:
        return activityList.filter(({ collaborators }) => {
          return (
            collaborators !== undefined &&
            collaborators.some((collaborator) => collaborator.userId === userId)
          );
        });
      case ActivityOwnerFilter.ALL:
        return activityList;
      default:
        throw new Error(`Invalid ActivityOwnerFilter: ${activityOwnerFilter}`);
    }
  }, [userId, activityOwnerFilter, activityList]);
}
