import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { Column } from 'components/Column/Column';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { Row } from 'components/Row/Row';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  selected: AreaInvolved | null;
  onChange(value: AreaInvolved): void;
}

export const AreaInvolvedRadioInput: FC<Props> = ({
  selected,
  onChange,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      onChange(value);
    },
    [onChange],
  );

  return (
    <Column {...props}>
      <Row>
        <RadioInput
          label="County"
          value={AreaInvolved.COUNTY}
          checked={selected === AreaInvolved.COUNTY}
          onChange={handleRadioInputChange}
        />
        <RadioInput
          label="Entire Region"
          className="ml-6"
          value={AreaInvolved.REGION}
          checked={selected === AreaInvolved.REGION}
          onChange={handleRadioInputChange}
        />
        <RadioInput
          label="Statewide"
          className="ml-6"
          value={AreaInvolved.STATEWIDE}
          checked={selected === AreaInvolved.STATEWIDE}
          onChange={handleRadioInputChange}
        />
      </Row>
    </Column>
  );
};
