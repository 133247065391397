import React, { FC } from 'react';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';
import { TextInput } from 'components/TextInput/TextInput';
import { User } from 'shared/lib/types/User';
import { Button } from 'components/Button/Button';
import { RegionsDropdown } from 'components/RegionsDropdown/RegionsDropdown';
import { RolesDropdown } from 'components/RolesDropdown/RolesDropdown';
import { FillableUserFormProps } from 'types/FillableUserFormProps';
import { useUserForm } from 'hooks/useUserForm';
import { useRegionContext } from 'contexts/regionContext';

interface Props {
  user?: User;
  onSubmit: (data: FillableUserFormProps) => void;
  readOnly?: boolean;
}

export const UserManagementForm: FC<Props> = ({ user, readOnly, onSubmit }) => {
  const {
    fillableUserFields,
    onFirstNameChanged,
    onLastNameChanged,
    onEmailChanged,
    onRegionChanged,
    onRoleChanged,
    handleFormSubmission,
  } = useUserForm(user);
  const { regionList } = useRegionContext();
  const { firstName, lastName, email, regionId, role } = fillableUserFields;

  return (
    <form onSubmit={(e) => handleFormSubmission(e, onSubmit)}>
      <Column className="mt-7">
        <Row className="justify-between">
          <div className="w-2/4">
            <TextInput
              value={firstName}
              label="First Name"
              onChange={(e) => onFirstNameChanged(e.target.value)}
              containerClassName="input-width"
              readOnly={readOnly}
              required
            />
          </div>
          <div className="w-2/4">
            <TextInput
              label="Last Name"
              value={lastName}
              onChange={(e) => onLastNameChanged(e.target.value)}
              containerClassName="input-width"
              readOnly={readOnly}
              required
            />
          </div>
        </Row>
        <Row className="justify-between mt-7">
          <div className="w-2/4">
            <RegionsDropdown
              regionList={regionList}
              selected={regionId}
              className="input-width"
              onChange={(value) => onRegionChanged(+value.id)}
              readOnly={readOnly}
            />
          </div>
          <div className="w-2/4">
            <RolesDropdown
              selectedValue={role}
              className="input-width"
              onChange={(value) => onRoleChanged(value)}
              readOnly={readOnly}
            />
          </div>
        </Row>
        <Row className="justify-between mt-7">
          <div className="w-2/4">
            <TextInput
              label="Email"
              value={email}
              onChange={(e) => onEmailChanged(e.target.value)}
              containerClassName="input-width"
              readOnly={readOnly}
              required
            />
          </div>
        </Row>
        {!readOnly && (
          <Button className="w-24 mt-16 mr-8 ml-auto" type="submit">
            Save
          </Button>
        )}
      </Column>
    </form>
  );
};
