import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { ActivityDeliverable } from 'shared/lib/types/ActivityDeliverable';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { RadioInputWithInfo } from 'components/RadioInputWithInfo/RadioInputWithInfo';
import { removeRecordFromArray } from 'shared/lib/utils/removeRecordFromArray';
import { Row } from 'components/Row/Row';
import { splitArray } from 'shared/lib/utils/splitArray';
import { Column } from 'components/Column/Column';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  activityDeliverableKindList: ActivityDeliverableKind[];
  selected: ActivityDeliverable[];
  onChange(activityDeliverable: ActivityDeliverable[]): void;
}
export const ActivityDeliverableRadioInput: FC<Props> = ({
  activityDeliverableKindList,
  selected = [],
  onChange,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const selectedActivityDeliverableKindId = +e.target.value;
      const deliverableKindIdExists = selected.find(
        (activityDeliverable) =>
          activityDeliverable.activityDeliverableKindId ===
          selectedActivityDeliverableKindId,
      );
      onChange(
        deliverableKindIdExists
          ? removeRecordFromArray(selected, {
              key: 'activityDeliverableKindId',
              value: selectedActivityDeliverableKindId,
            })
          : ([
              ...selected,
              { activityDeliverableKindId: selectedActivityDeliverableKindId },
            ] as ActivityDeliverable[]),
      );
    },
    [onChange, selected],
  );

  return (
    <div {...props}>
      <Row className="justify-between">
        {splitArray(activityDeliverableKindList).map(
          (activityDeliverableKindListCol, i) => {
            return (
              <Column key={i} className="w-full">
                {activityDeliverableKindListCol.map(
                  (activityDeliverableKind: ActivityDeliverableKind) => {
                    const checkedItem = selected?.find(
                      (deliverableType) =>
                        deliverableType.activityDeliverableKindId ===
                        activityDeliverableKind.id,
                    );
                    return (
                      <RadioInputWithInfo
                        type="checkbox"
                        key={activityDeliverableKind.id}
                        containerClassName="mb-6 mr-2"
                        label={activityDeliverableKind.name}
                        value={activityDeliverableKind.id}
                        onChange={handleRadioInputChange}
                        checked={!!checkedItem}
                        infoText={activityDeliverableKind.description}
                      />
                    );
                  },
                )}
              </Column>
            );
          },
        )}
      </Row>
    </div>
  );
};
