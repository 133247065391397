import { PublicAppBar } from 'components/PublicAppBar/PublicAppBar';
import { useUserContext } from 'contexts/userContext';
import { AcceptInvitationPage } from 'pages/AcceptInvitationPage/AcceptInvitationPage';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import { Login } from 'pages/Login/Login';
import { PasswordResetPage } from 'pages/PasswordResetPage/PasswordResetPage';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import {
  loginRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  acceptInviteRoute,
} from 'shared/lib/constants/routes/publicRoutes';

export function PublicRoutes() {
  const { login } = useUserContext();

  return (
    <BrowserRouter>
      <PublicAppBar />
      <Switch>
        <Route exact path={loginRoute}>
          <Login login={login}></Login>
        </Route>
        <Route exact path={forgotPasswordRoute}>
          <ForgotPasswordPage />
        </Route>
        <Route exact path={resetPasswordRoute}>
          <PasswordResetPage />
        </Route>
        <Route exact path={acceptInviteRoute}>
          <AcceptInvitationPage />
        </Route>
        <Redirect to={loginRoute} />
      </Switch>
    </BrowserRouter>
  );
}
