import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Column } from 'components/Column/Column';

interface Props extends ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
}
export const ReadOnlyField: FC<Props> = ({ label, value, ...props }) => (
  <Column {...props}>
    <div className="input-label">{label}</div>
    <div className="detail-text">{value}</div>
  </Column>
);
