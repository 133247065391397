import { useCallback, useEffect, useState } from 'react';
import { User } from 'shared/lib/types/User';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getUser } from 'api/auth/getUser';
import { login } from 'api/auth/login';
import { logout } from 'api/auth/logout';
import { UserContextValue } from '../contexts/userContext';

/**
 * Hook to manage the currently logged in user and log them in or out.
 */
export function useUser(): UserContextValue & { loading: boolean } {
  const [user, setUser] = useState<User | null>(null);
  const [logoutTimestamp, setLogoutTimestamp] = useState<number | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  const refreshUser = useCallback(async (isCancelled?: () => boolean) => {
    const userSession = await getUser();
    if (!isCancelled || !isCancelled()) {
      if (userSession) {
        setUser(userSession.user);
        setLogoutTimestamp(userSession.logoutTimestamp);
      } else {
        setUser(null);
        setLogoutTimestamp(null);
      }
    }
  }, []);

  const doLogin = useCallback(
    async (username: string, password: string) => {
      setLoading(true);
      try {
        await login(username, password);
        await refreshUser();
      } finally {
        setLoading(false);
      }
    },
    [refreshUser],
  );

  const doLogout = useCallback(async () => {
    setLoading(true);
    try {
      await logout();
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useAsyncEffect(async (isCancelled) => {
    setLoading(true);
    try {
      await refreshUser(isCancelled);
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (logoutTimestamp !== null) {
      const now = Date.now();
      if (logoutTimestamp < now) {
        return; // The user's clock is probably set wrong
      }
      const timeout = setTimeout(doLogout, logoutTimestamp - now);
      return () => clearTimeout(timeout);
    }
  }, [logoutTimestamp, doLogout]);

  return {
    user,
    refreshUser,
    login: doLogin,
    logout: doLogout,
    loading,
  };
}
