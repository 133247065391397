import React, { FC, useCallback, useState } from 'react';
import { usersRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { profileRoute } from 'shared/lib/constants/routes/commonRoutes';
import { isSuperAdmin } from 'shared/lib/utils/isSuperAdmin';
import { NavigationItem } from 'components/NavigationItem/NavigationItem';
import { activeSettingsIcon } from 'assets/images';
import { inactiveSettingsIcon } from 'assets/images';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { ListGroup } from 'components/ListGroup/ListGroup';
import { useUserContext } from 'contexts/userContext';

export const SettingsNavigationItem: FC = () => {
  const [showListGroup, setShowListGroup] = useState(false);
  const { user, logout } = useUserContext();
  const location = useLocation();
  const history = useHistory();

  const handleClickOutside = useCallback(() => setShowListGroup(false), []);

  const handleListGroupItemClick = useCallback(
    (routeName) => {
      setShowListGroup(false);
      history.push(routeName);
    },
    [history],
  );

  const path = location.pathname;

  const matchesProfileRoute = matchPath(path, { path: profileRoute });
  const matchesUserManagementRoute = matchPath(path, { path: usersRoute });

  return (
    <div className="relative">
      <NavigationItem
        imageSrc={
          matchesProfileRoute || matchesUserManagementRoute
            ? activeSettingsIcon
            : inactiveSettingsIcon
        }
        imageAlt="Settings Icon"
        label="Manage"
        onClick={() => {
          setShowListGroup((prevState) => !prevState);
        }}
      />
      {showListGroup && (
        <ListGroup
          onClickOutside={handleClickOutside}
          className="absolute top-24 right-0 z-50"
        >
          {isSuperAdmin(user) && (
            <li
              onClick={() => handleListGroupItemClick(usersRoute)}
              className={matchesUserManagementRoute ? 'active' : ''}
            >
              Users
            </li>
          )}
          <li
            onClick={() => handleListGroupItemClick(profileRoute)}
            className={matchesProfileRoute ? 'active' : ''}
          >
            My Profile
          </li>
          <li onClick={logout}>Logout</li>
        </ListGroup>
      )}
    </div>
  );
};
