import React, { FC, useEffect } from 'react';
import { Circle, Label, create, color } from '@amcharts/amcharts4/core';
import {
  MapChart,
  MapPolygonSeries,
  MapImageSeries,
  projections,
} from '@amcharts/amcharts4/maps';
import am4geodata_region_usa_orLow from '@amcharts/amcharts4-geodata/region/usa/orLow';

const TOP_PADDING_OFFSET = 60;

export interface CountyMapActivityData {
  countyId: string;
  value: number;
}

interface Props {
  activityData: CountyMapActivityData[];
}

export const OregonCountyMap: FC<Props> = ({ activityData }) => {
  useEffect(() => {
    // Create map instance
    const chart = create('county-map', MapChart);

    // MapChart configuration
    chart.geodata = am4geodata_region_usa_orLow;
    chart.projection = new projections.Miller();
    chart.maxZoomLevel = 1;
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;

    // Load county polygon shapes
    const polygonSeries = chart.series.push(new MapPolygonSeries());
    polygonSeries.useGeodata = true;
    polygonSeries.calculateVisualCenter = true;
    polygonSeries.mapPolygons.template.propertyFields.fill = 'fill';

    // Add county labels inside polygon shapes
    const labelSeries = chart.series.push(new MapImageSeries());
    const labelTemplate = labelSeries.mapImages.template.createChild(Label);
    labelTemplate.horizontalCenter = 'middle';
    labelTemplate.verticalCenter = 'middle';
    labelTemplate.fontSize = 11;
    labelTemplate.nonScaling = false;
    labelTemplate.interactionsEnabled = false;

    polygonSeries.events.on('inited', function () {
      polygonSeries.mapPolygons.each(function (polygon: any) {
        const label: any = labelSeries.mapImages.create();
        const countyName = polygon.dataItem.dataContext.name;
        label.latitude = polygon.visualLatitude;
        label.longitude = polygon.visualLongitude;

        if (countyName === 'Multnomah') {
          // Move Multnomah labe since it is in the wrong place
          label.paddingLeft = 50;
          label.paddingTop = 10;
        }
        label.children.getIndex(0).text = countyName;
      });
    });

    // Map BUBBLES!
    const imageSeries = chart.series.push(new MapImageSeries());
    imageSeries.data = activityData;
    imageSeries.dataFields.value = 'value';

    const imageTemplate = imageSeries.mapImages.template;
    imageTemplate.nonScaling = true;

    const circle = imageTemplate.createChild(Circle);
    circle.fillOpacity = 0.5;
    circle.fill = color('#94D37D');
    circle.paddingBottom = TOP_PADDING_OFFSET;

    imageSeries.heatRules.push({
      target: circle,
      property: 'radius',
      min: 35,
      max: 35,
      dataField: 'value',
    });

    imageTemplate.adapter.add('latitude', function (latitude, target: any) {
      const polygon = polygonSeries.getPolygonById(
        target.dataItem.dataContext.countyId,
      );
      if (polygon) {
        return polygon.visualLatitude;
      }
      return latitude;
    });

    imageTemplate.adapter.add('longitude', function (longitude, target: any) {
      const polygon = polygonSeries.getPolygonById(
        target.dataItem.dataContext.countyId,
      );
      if (polygon) {
        return polygon.visualLongitude;
      }
      return longitude;
    });

    // Map BUBBLE labels
    const label = imageTemplate.createChild(Label);
    label.text = '{value}';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';
    label.paddingBottom = 65 + TOP_PADDING_OFFSET;
    label.fontSize = 22;
    label.fontFamily = 'SF Pro Display Light';
    label.adapter.add('dy', function (dy, target: any) {
      const circle = target.parent.children.getIndex(0);
      return circle.pixelRadius;
    });

    return function cleanup() {
      chart.dispose();
    };
  }, [activityData]);

  return <div id="county-map" className="h-screen" />;
};
