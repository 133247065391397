import React, { FC, ComponentPropsWithoutRef, useRef } from 'react';
import { Row } from 'components/Row/Row';
import { magnifyingGlassIcon } from 'assets/images';

interface Props extends ComponentPropsWithoutRef<'div'> {
  placeholder?: string;
  searchValue: string;
  onSearchChange: (value: string) => void;
}

export const SearchTextInput: FC<Props> = ({
  className = '',
  placeholder,
  searchValue,
  onSearchChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Row
      onClick={() => inputRef.current?.focus()}
      className={`border border-gray-450 rounded-3xl items-center px-5 py-2.5 ${className}`}
    >
      <img src={magnifyingGlassIcon} alt="Magnifying Glass" />
      <input
        type="text"
        ref={inputRef}
        value={searchValue}
        className="font-sf-pro-medium text-gray-600 text-sm pl-2.5 w-full p-1"
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder={placeholder ?? 'Search'}
      />
    </Row>
  );
};
