import { User } from 'shared/lib/types/User';
import { createContext, useContext } from 'react';

export interface UserContextValue {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
  async login() {},
  async logout() {},
  async refreshUser() {},
});

export function useLoggedInUserContext(): Omit<
  UserContextValue,
  'user' | 'login'
> & { user: User } {
  const { user, login, ...restOfContext } = useContext(UserContext);
  if (!user) {
    throw new Error('useUser must be called after ensuring logged in');
  }

  return {
    user,
    ...restOfContext,
  };
}

export function useUserContext() {
  return useContext(UserContext);
}
