import React, { FC, FormEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { forgotPasswordRoute } from 'shared/lib/constants/routes/publicRoutes';
import { Button } from 'components/Button/Button';
import { TextInput } from 'components/TextInput/TextInput';

interface LoginFormProps {
  email: string;
  password: string;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onSubmit: () => void;
}

export const LoginForm: FC<LoginFormProps> = ({
  email,
  password,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) => {
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );
  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        label="Email"
        type="text"
        className="mb-8"
        value={email}
        onChange={(e) => onEmailChange(e.target.value)}
        required
      />
      <TextInput
        label="Password"
        type="password"
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
        required
      />
      <div className="font-sf-pro-semibold text-blue-400 mt-3">
        <Link to={forgotPasswordRoute}>Forgot password?</Link>
      </div>
      <Button type="submit" className="mt-10">
        Login
      </Button>
    </form>
  );
};
