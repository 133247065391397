import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';

interface Props extends ComponentPropsWithoutRef<'div'> {
  badgeLabel: string;
  label: string;
  badgeClassName?: string;
}

export const ActivityCardBadge: FC<Props> = ({
  label,
  badgeLabel,
  badgeClassName,
  className = '',
  ...props
}) => (
  <Row className={`items-center ${className}`} {...props}>
    <Column
      className={`justify-center rounded-sm font-sf-pro-medium w-4 h-4 text-center text-xxs text-white ${badgeClassName}`}
    >
      {badgeLabel}
    </Column>
    <div className={`font-sf-pro-medium text-xs ml-1`}>{label}</div>
  </Row>
);
