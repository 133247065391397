import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityDetailFileList } from 'components/ActivityDetailFileList/ActivityDetailFileList';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { ActivityFile } from 'shared/lib/types/ActivityFile';

interface Props extends ComponentPropsWithoutRef<'div'> {
  files?: ActivityFile[];
  comments: string | null;
  onDownloadActivityFile(activityFile: ActivityFile): void;
}

export const ActivityCommentsAndFilesDetail: FC<Props> = ({
  files,
  comments,
  onDownloadActivityFile,
  ...rest
}) => (
  <ActivityDetailSegment heading="Comments + Files" {...rest}>
    <div className="mt-1.5 font-sf-pro-medium break-words text-gray-700">
      {comments}
    </div>
    {files && (
      <ActivityDetailFileList
        onDownloadActivityFile={onDownloadActivityFile}
        className="mt-5"
        files={files}
      />
    )}
  </ActivityDetailSegment>
);
