import React, { FC } from 'react';
import { createActivityRoute } from 'shared/lib/constants/routes/activityRoutes';
import { homeRoute } from 'shared/lib/constants/routes/commonRoutes';
import { AuthenticatedAppBar } from 'components/AuthenticatedAppBar/AuthenticatedAppBar';
import { NewActivityButton } from 'components/NewActivityButton/NewActivityButton';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import { useUserContext } from 'contexts/userContext';
import { isViewOnly } from 'shared/lib/utils/isSuperAdmin';

export const AuthenticatedPageWrapper: FC = ({ children }) => {
  const { user } = useUserContext();
  const history = useHistory();
  const location = useLocation();
  const path = location.pathname;

  const matchesActivityRoute = matchPath(path, { path: '/activity' });
  const matchesDashboardRoute = matchPath(path, { path: homeRoute });

  return (
    <div className="h-screen">
      {!matchesActivityRoute && <AuthenticatedAppBar />}
      <div
        className={`${!matchesActivityRoute ? 'px-6' : ''} ${
          !matchesDashboardRoute && !matchesActivityRoute ? 'pb-20' : ''
        }`}
      >
        {!matchesActivityRoute && !isViewOnly(user) && (
          <div className="fixed right-0 bottom-0 z-50">
            <NewActivityButton
              onClick={() => history.push(createActivityRoute)}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
