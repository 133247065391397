import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityAudienceType } from 'shared/lib/types/ActivityAudienceType';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { User } from 'shared/lib/types/User';
import { YearQuarter } from 'shared/lib/types/YearQuarter';
import { reportRoute } from 'shared/lib/constants/routes/commonRoutes';
import { isSuperViewer } from 'shared/lib/utils/isSuperAdmin';
import { getActivitySchoolYearRange } from 'shared/lib/utils/getActivitySchoolYearRange';
import { getNotNullish } from 'shared/lib/utils/validate';
import { getActivityTypes } from 'api/activityType/getActivityTypes';
import { getActivityAudienceTypes } from 'api/activityAudience/getActivityAudienceTypes';
import { getUserActivities } from 'api/activity/getUserActivities';
import { getActivityDeliverables } from 'api/activiteDeliverable/getActivityDeliverables';
import { getReportSummaries } from 'api/reportSummary/getReportSummaries';
import { saveReportSummary } from 'api/reportSummary/saveReportSummary';
import { useRegionContext } from 'contexts/regionContext';
import { useUserContext } from 'contexts/userContext';
import { useActivityFilterForm } from 'hooks/useActivityFilterForm';
import { useActivityFilter } from 'hooks/useActivityFilter';
import { useActivityDateRangeFilter } from 'hooks/useActivityDateRangeFilter';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { useHistory } from 'react-router-dom';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import { Button } from 'components/Button/Button';
import { TextAreaInput } from 'components/TextAreaInput/TextAreaInput';
import { ActivityListFilterForm } from 'components/ActivityListFilterForm/ActivityListFilterForm';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { ActivityFilterChips } from 'components/ActivityFilterChips/ActivityFilterChips';
import { RoundFilterButton } from 'components/RoundFilterButton/RoundFilterButton';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import { ShareActivityMenu } from 'components/ShareActivityMenu/ShareActivityMenu';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { ActivityTypeDonutChart } from 'components/ActivityTypeDonutChart/ActivityTypeDonutChart';
import { ActivitySiteVisitsChart } from 'components/ActivitySiteVisitsChart/ActivitySiteVisitsChart';
import { ActivityAudienceChart } from 'components/ActivityAudienceChart/ActivityAudienceChart';
import { ActivityDurationSummary } from 'components/ActivityDurationSummary/ActivityDurationSummary';
import { PrintActivityReportModal } from 'components/PrintActivityReportModal/PrintActivityReportModal';
import { QuarterSelectInput } from 'components/QuarterSelectInput/QuarterSelectInput';
import { ActivityReportComments } from 'components/ActivityReportComments/ActivityReportComments';
import { DateRangePicker } from 'components/DateRangePicker/DateRangePicker';
import { downloadActivitiesCsv } from 'utils/downloadActivitiesCsv';
import getErrorMessage from 'utils/getErrorMessage';
import { getUsers } from 'api/user/getUsers';
import { filterVisibleUsers } from 'utils/filterVisibleUsers';
import { sortUsersByRegion } from 'utils/sortUsersByRegion';
import { useQuarterDateRange } from 'hooks/useQuarterDateRange';

const leftColClassName = 'pr-5 w-2/6';
const rightColClassName = 'w-4/6 print:w-full';

function getReportSummaryKey({ quarter1Year, quarter }: YearQuarter) {
  return `${quarter1Year}${quarter}`;
}

export const ReportPage: FC<ComponentPropsWithoutRef<'div'>> = (props) => {
  const [loading, setLoading] = useState(false);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [
    showPrintActivityReportModal,
    setShowPrintActivityReportModal,
  ] = useState(false);
  const [activityTypeKindList, setActivityTypeKindList] = useState<
    ActivityTypeKind[]
  >([]);
  const [activityAudienceList, setActivityAudienceList] = useState<
    ActivityAudienceType[]
  >([]);
  const [appUserList, setAppUserList] = useState<User[]>([]);
  const [reportSummaries, setReportSummaries] = useState<
    Record<string, string | undefined> // [`${quarter1Year}${Quarter}`]?: summary
  >({});
  const [reportSummary, setReportSummary] = useState<string | undefined>();
  const [
    activityDeliverableKindList,
    setActivityDeliverableKindList,
  ] = useState<ActivityDeliverableKind[]>([]);
  const { user } = useUserContext();
  const { regionList, countyList, schoolDistrictList } = useRegionContext();
  const history = useHistory();
  const { snackbar } = useSnackbarContext();
  const {
    quarterDateRange,
    setYearQuarter,
    setStartDate,
    setEndDate,
  } = useQuarterDateRange();
  const {
    activityFilter,
    filteredCounties,
    filteredSchoolDistricts,
    ...restOfActivityFilterFormProps
  } = useActivityFilterForm({ countyList, schoolDistrictList });

  const canViewEverything = isSuperViewer(user);

  const [earliestQuarter1Year, latestQuarter1Year] = useMemo(() => {
    return getActivitySchoolYearRange(activityList);
  }, [activityList]);

  const filteredActivities = useActivityFilter({
    activityList: useActivityDateRangeFilter({
      startDate: quarterDateRange.startDate,
      endDate: quarterDateRange.endDate,
      filterQuarterlyReport: true,
      activityList,
    }),
    activityFilter,
  });

  const handleSaveFilter = useCallback(() => {
    setShowFilters(false);

    history.push(reportRoute);
  }, [history]);

  const handleShowPrintModal = useCallback(() => {
    setShowPrintActivityReportModal(true);
  }, []);

  const handlePrintConfirm = useCallback(() => {
    window.print();
    setShowPrintActivityReportModal(false);
  }, []);

  const handleDownloadCsv = useCallback(() => {
    downloadActivitiesCsv(filteredActivities);
  }, [filteredActivities]);

  const savedReportSummary =
    reportSummaries[getReportSummaryKey(quarterDateRange.yearQuarter)];

  const handleYearQuarterChange = useCallback(
    (yearQuarter: YearQuarter) => {
      setYearQuarter(yearQuarter);
      setReportSummary(reportSummaries[getReportSummaryKey(yearQuarter)]);
    },
    [reportSummaries, setYearQuarter],
  );

  const reportSummaryRef = useRef<HTMLTextAreaElement>(null);
  const handleSaveReportSummary = useCallback(async () => {
    setLoading(true);
    const reportSummaryKey = getReportSummaryKey(quarterDateRange.yearQuarter);

    try {
      const updatedReportSummary = await saveReportSummary({
        ...quarterDateRange.yearQuarter,
        summary: reportSummary ?? '',
      });
      setReportSummaries((prev) => ({
        ...prev,
        [reportSummaryKey]: updatedReportSummary?.summary,
      }));
    } catch (e) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(e),
      });
    } finally {
      setLoading(false);
      reportSummaryRef.current?.scrollIntoView();
    }
  }, [quarterDateRange.yearQuarter, reportSummary, snackbar]);

  useAsyncEffect(async (isCancelled) => {
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
    setLoading(true);

    try {
      const [
        activities,
        activityTypes,
        activityAudienceTypes,
        activityDeliverableTypes,
        appUsers,
        reportSummariesList,
      ] = await Promise.all([
        getUserActivities(),
        getActivityTypes(),
        getActivityAudienceTypes(),
        getActivityDeliverables(),
        getUsers(),
        getReportSummaries(),
      ]);

      if (!isCancelled()) {
        setActivityList(activities ?? []);
        setActivityTypeKindList(activityTypes ?? []);
        setActivityAudienceList(activityAudienceTypes ?? []);
        setActivityDeliverableKindList(activityDeliverableTypes ?? []);
        // getUsers() exludes the logged in user, so add them back in.
        // The non-nullish assertion shuld never fail because the API calls
        // would alredy fail if the user was not logged in.
        appUsers.push(getNotNullish(user));
        setAppUserList(sortUsersByRegion(filterVisibleUsers(appUsers)));
        const newReportSummaries = Object.fromEntries(
          reportSummariesList.map(({ quarter1Year, quarter, summary }) => {
            return [getReportSummaryKey({ quarter1Year, quarter }), summary];
          }),
        );
        setReportSummaries(newReportSummaries);
        setReportSummary(
          newReportSummaries[getReportSummaryKey(quarterDateRange.yearQuarter)],
        );
        snackbar.hide();
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  return (
    <div {...props}>
      {!loading && (
        <>
          <Row className="w-100 justify-between mb-8 mt-6 no-print">
            <div className={leftColClassName} />
            <Row className={`${rightColClassName} items-center relative`}>
              <Column className="w-full">
                <Row className="flex-1">
                  <Row className="flex-shrink-0 gap-x-3">
                    <RoundFilterButton
                      active={showFilters}
                      onClick={() => setShowFilters(true)}
                    />
                    <ShareActivityMenu onPrintClick={handleShowPrintModal} />
                    {canViewEverything && (
                      <CircularActionButton
                        icon={CircularActionIconKind.DOWNLOAD}
                        onClick={handleDownloadCsv}
                      />
                    )}
                  </Row>
                  <ActivityFilterChips
                    className="ml-6"
                    regionList={regionList}
                    countyList={countyList}
                    schoolDistrictList={schoolDistrictList}
                    activityDeliverableKindList={activityDeliverableKindList}
                    activityAudienceList={activityAudienceList}
                    activityTypeKindList={activityTypeKindList}
                    appUserList={appUserList}
                    {...activityFilter}
                    {...restOfActivityFilterFormProps}
                  />
                </Row>
                <Row className="items-center flex-wrap gap-x-8 ml-1">
                  <QuarterSelectInput
                    className="w-52 mt-2"
                    value={quarterDateRange.yearQuarter}
                    onYearQuarterChange={handleYearQuarterChange}
                    earliestQuarter1Year={earliestQuarter1Year}
                    latestQuarter1Year={latestQuarter1Year}
                    isDateRangeAlignedWithQuarter={
                      quarterDateRange.isDateRangeAlignedWithQuarter
                    }
                  />
                  {canViewEverything && (
                    <DateRangePicker
                      startDate={quarterDateRange.startDate}
                      endDate={quarterDateRange.endDate}
                      onChangeStartDate={setStartDate}
                      onChangeEndDate={setEndDate}
                      className="ml-1"
                    />
                  )}
                </Row>
              </Column>
            </Row>
          </Row>
          <Row className="print:flex-col">
            <Column
              className={`${leftColClassName} ${
                showFilters ? 'pointer-events-none' : ''
              } print:w-full`}
            >
              <ActivityDurationSummary activityList={filteredActivities} />
            </Column>
            <Column className={rightColClassName}>
              {showPrintActivityReportModal && (
                <PrintActivityReportModal
                  className="mb-12 no-print"
                  onPrintConfirm={handlePrintConfirm}
                  onClose={() => setShowPrintActivityReportModal(false)}
                />
              )}
              {showFilters ? (
                <ActivityDetailSegment heading="Filter List">
                  <ActivityListFilterForm
                    regionList={regionList}
                    filteredCounties={filteredCounties}
                    filteredSchoolDistricts={filteredSchoolDistricts}
                    activityDeliverableKindList={activityDeliverableKindList}
                    activityAudienceList={activityAudienceList}
                    activityTypeKindList={activityTypeKindList}
                    appUserList={appUserList}
                    onSave={handleSaveFilter}
                    {...activityFilter}
                    {...restOfActivityFilterFormProps}
                    canViewEverything={canViewEverything}
                  />
                </ActivityDetailSegment>
              ) : (
                <div className="mb-48">
                  <ActivityDetailSegment heading="Activities">
                    <ActivityTypeDonutChart
                      className="mt-5"
                      activityList={filteredActivities}
                    />
                  </ActivityDetailSegment>
                  <ActivityDetailSegment
                    heading="Audience"
                    className="mt-14 page-break-after-print"
                  >
                    <ActivityAudienceChart
                      className="mt-5"
                      activityList={filteredActivities}
                    />
                  </ActivityDetailSegment>
                  <ActivityDetailSegment
                    heading="Site Visits"
                    className="mt-14 print:mt-0"
                  >
                    <ActivitySiteVisitsChart
                      className="mt-5"
                      activityList={filteredActivities}
                    />
                  </ActivityDetailSegment>
                  <ActivityDetailSegment
                    heading="Comments"
                    className="mt-14 print:mt-0"
                  >
                    <ActivityReportComments
                      className="mt-5"
                      activityList={filteredActivities}
                    />
                  </ActivityDetailSegment>
                  <ActivityDetailSegment
                    heading="Quarterly Report Summary"
                    tooltipText="Describe extraordinary circumstances, barriers encountered, and/or action steps."
                    className="mt-14 print:mt-0"
                  >
                    <div className="hidden print:block whitespace-pre-wrap mt-5">
                      {reportSummary}
                    </div>
                    <TextAreaInput
                      value={reportSummary ?? ''}
                      onChange={(e) => setReportSummary(e.currentTarget.value)}
                      className="print:hidden mt-5"
                      ref={reportSummaryRef}
                    />
                  </ActivityDetailSegment>
                  <Button
                    disabled={
                      (reportSummary ?? '') === (savedReportSummary ?? '')
                    }
                    onClick={handleSaveReportSummary}
                    className="print:hidden mt-5"
                  >
                    Save Summary
                  </Button>
                </div>
              )}
            </Column>
          </Row>
        </>
      )}
    </div>
  );
};
