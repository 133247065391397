import React, {
  ComponentPropsWithoutRef,
  FC,
  useState,
  useEffect,
} from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { getObjectPropertyValueFrequency } from 'shared/lib/utils/getObjectPropertyValueFrequency';
import { DonutChartData } from 'types/report/DonutChart';
import { DonutChart } from 'components/DonutChart/DonutChart';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { Row } from 'components/Row/Row';
import { ActivityTypeDonutChartLegend } from 'components/ActivityTypeDonutChartLegend/ActivityTypeDonutChartLegend';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activityList: Activity[];
}

export const ActivityTypeDonutChart: FC<Props> = ({
  activityList,
  ...rest
}) => {
  const [chartData, setChartData] = useState<DonutChartData>([]);

  useEffect(() => {
    const activityTypes = activityList
      .flatMap((activity) => activity.activityType)
      .map(
        (activityType) => activityType?.activityTypeKind as ActivityTypeKind,
      );
    const activityTypeFrequency = getObjectPropertyValueFrequency({
      data: activityTypes,
      key: 'name',
    });
    setChartData(
      activityTypeFrequency
        .sort((a, b) => b.frequency - a.frequency)
        .map((frequencyObj, i) => ({
          name: frequencyObj.value as string,
          value: frequencyObj.frequency,
          color: `rgba(74, 131, 53, ${1 - i / activityTypeFrequency.length})`,
        })),
    );
  }, [activityList]);

  return (
    <Row {...rest}>
      {chartData.length ? (
        <>
          <DonutChart className="h-64 flex-1" data={chartData} />
          <div className="flex flex-1 items-center">
            <ActivityTypeDonutChartLegend chartData={chartData} />
          </div>
        </>
      ) : (
        <div>No data available</div>
      )}
    </Row>
  );
};
