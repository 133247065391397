import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityType } from 'shared/lib/types/ActivityType';
import { splitArray } from 'shared/lib/utils/splitArray';
import { RadioInputWithInfo } from 'components/RadioInputWithInfo/RadioInputWithInfo';
import { TextInput } from 'components/TextInput/TextInput';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import { removeRecordFromArray } from 'shared/lib/utils/removeRecordFromArray';
import { updateArrayRecord } from 'shared/lib/utils/updateArrayRecord';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  activityTypeKindList: ActivityTypeKind[];
  selected: ActivityType[];
  onChange(activityType: ActivityType[]): void;
}

export const ActivityTypeRadioInput: FC<Props> = ({
  activityTypeKindList,
  selected = [],
  onChange,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const selectedActivityTypeKindId = +e.target.value;
      const selectedActivityTypeKindExists = selected.find(
        (activityType) =>
          activityType.activityTypeKindId === selectedActivityTypeKindId,
      );

      onChange(
        selectedActivityTypeKindExists
          ? removeRecordFromArray(selected, {
              key: 'activityTypeKindId',
              value: selectedActivityTypeKindId,
            })
          : ([
              ...selected,
              { activityTypeKindId: selectedActivityTypeKindId },
            ] as ActivityType[]),
      );
    },
    [onChange, selected],
  );

  const handlePresenterNameChange = useCallback(
    (presenterName: string, selectedActivityTypeKindId: number) => {
      onChange(
        updateArrayRecord(selected, {
          key: 'activityTypeKindId',
          value: selectedActivityTypeKindId,
          data: {
            activityTypeKindId: selectedActivityTypeKindId,
            presenterName,
          } as ActivityType,
        }),
      );
    },
    [onChange, selected],
  );

  return (
    <div {...props}>
      <Row className="justify-between">
        {splitArray(activityTypeKindList).map((activityTypeKindListCol, i) => {
          return (
            <Column key={i} className="w-full">
              {activityTypeKindListCol.map(
                (activityTypeKind: ActivityTypeKind) => {
                  const checkedItem = selected?.find(
                    (activityType) =>
                      activityType.activityTypeKindId === activityTypeKind.id,
                  );
                  return (
                    <div key={activityTypeKind.id} className="mb-6">
                      <RadioInputWithInfo
                        type="checkbox"
                        label={activityTypeKind.name}
                        value={activityTypeKind.id}
                        onChange={handleRadioInputChange}
                        checked={!!checkedItem}
                        infoText={activityTypeKind.description}
                      />
                      {!!checkedItem &&
                        activityTypeKind.name ===
                          'Attend Training/Conference' && (
                          <div className="pl-9">
                            <TextInput
                              label="Presenter Name"
                              value={checkedItem.presenterName ?? ''}
                              containerClassName="w-60 mt-1.5"
                              onChange={(e) =>
                                handlePresenterNameChange(
                                  e.target.value,
                                  activityTypeKind.id,
                                )
                              }
                            />
                          </div>
                        )}
                    </div>
                  );
                },
              )}
            </Column>
          );
        })}
      </Row>
    </div>
  );
};
