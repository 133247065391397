import React from 'react';
import { useUser } from 'hooks/useUser';
import { UserContext } from 'contexts/userContext';
import { SnackbarContext } from 'contexts/snackbarContext';
import { PublicRoutes } from 'routes/PublicRoutes';
import { AuthenticatedApp } from 'apps/AuthenticatedApp';
import { useSnackbar } from 'hooks/useSnackbar';
import { Snackbar } from 'components/Snackbar/Snackbar';
import { SnackbarSpinnerOverlay } from 'components/SnackbarSpinnerOverlay/SnackbarSpinnerOverlay';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';

function App() {
  const { user, loading, ...restOfContextValues } = useUser();
  const {
    open,
    severity,
    text,
    snackbar,
    position,
    ...restOfSnackbarContextValues
  } = useSnackbar();
  return (
    <UserContext.Provider value={{ user, ...restOfContextValues }}>
      <SnackbarContext.Provider
        value={{
          open,
          severity,
          text,
          snackbar,
          ...restOfSnackbarContextValues,
        }}
      >
        {user === null && !loading && <PublicRoutes />}
        {user !== null && !loading && <AuthenticatedApp user={user} />}
        {severity === SnackbarSeverityKind.SPINNER ? (
          <SnackbarSpinnerOverlay open={open} text={text} position={position} />
        ) : (
          <Snackbar
            open={open}
            text={text}
            severity={severity}
            onClose={snackbar.hide}
            position={position}
          />
        )}
      </SnackbarContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
