import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Column } from 'components/Column/Column';

interface TextAreaInputProps extends ComponentPropsWithoutRef<'textarea'> {
  label?: string;
  containerClassName?: string;
  readOnly?: boolean;
}
const editableStyles = 'border-b-2 border-gray-500';

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(({ className = '', label, containerClassName, readOnly, ...rest }, ref) => (
  <Column className={containerClassName}>
    <div className="pb-1 input-label">{label}</div>
    <textarea
      placeholder="Type here..."
      className={`placeholder-black h-32 w-full text-base disabled:bg-white font-sf-pro-medium rounded-none p-2 ${
        !readOnly ? editableStyles : ''
      } ${className}`}
      disabled={readOnly}
      ref={ref}
      {...rest}
    />
  </Column>
));
