import React, { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';

export const Column: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  children,
  ...rest
}) => (
  <div
    className={twMerge('relative flex flex-col box-border', className)}
    {...rest}
  >
    {children}
  </div>
);
