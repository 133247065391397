import { Column } from 'components/Column/Column';
import React, { FC } from 'react';
interface PublicPageWrapperProps {
  className?: string;
}
export const PublicPageWrapper: FC<PublicPageWrapperProps> = ({
  className = '',
  children,
}) => (
  <div
    className={`p-6 flex flex-grow flex-row justify-center align-center ${className}`}
  >
    <Column> {children}</Column>
  </div>
);
