import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { ActivityAudience } from 'shared/lib/types/ActivityAudience';
import { ActivityAudienceCategory } from 'shared/lib/types/ActivityAudienceCategory';
import { ActivityAudienceType } from 'shared/lib/types/ActivityAudienceType';
import { splitArray } from 'shared/lib/utils/splitArray';
import { removeRecordFromArray } from 'shared/lib/utils/removeRecordFromArray';
import { updateArrayRecord } from 'shared/lib/utils/updateArrayRecord';
import { Accordion } from 'components/Accordion/Accordion';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';
import { NumberInput } from 'components/NumberInput/NumberInput';
import { CheckboxRadioInput } from 'components/CheckboxRadioInput/CheckboxRadioInput';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  selected: ActivityAudience[];
  onChange(audience: ActivityAudience[]): void;
  activityAudienceCategories: ActivityAudienceCategory[];
}

export const ActivityAudienceTypeRadioInput: FC<Props> = ({
  selected,
  onChange,
  activityAudienceCategories,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const selectedAudienceTypeId = +e.target.value;
      const audienceTypeIdExists = selected.find(
        (audienceType) =>
          audienceType.audienceTypeId === selectedAudienceTypeId,
      );
      onChange(
        audienceTypeIdExists
          ? removeRecordFromArray(selected, {
              key: 'audienceTypeId',
              value: selectedAudienceTypeId,
            })
          : ([
              ...selected,
              { audienceTypeId: selectedAudienceTypeId, quantity: 1 },
            ] as ActivityAudience[]),
      );
    },
    [onChange, selected],
  );

  const handleAudienceQtyChange = useCallback(
    (quantity: number, audienceTypeId: number) => {
      onChange(
        updateArrayRecord(selected, {
          key: 'audienceTypeId',
          value: audienceTypeId,
          data: {
            audienceTypeId,
            quantity,
          } as ActivityAudience,
        }),
      );
    },
    [onChange, selected],
  );

  return (
    <div {...props}>
      {activityAudienceCategories.map((audienceCategory) => {
        return (
          <Accordion
            key={audienceCategory.id}
            label={audienceCategory.name}
            className="mt-6"
          >
            <Row>
              {splitArray(
                audienceCategory.audienceTypes as ActivityAudienceType[],
              ).map((audienceTypesCol, i) => {
                return (
                  <Column key={i} className="w-full">
                    {audienceTypesCol.map(
                      (activityAudienceType: ActivityAudienceType) => {
                        const checked = selected?.find(
                          (audienceType) =>
                            audienceType.audienceTypeId ===
                            activityAudienceType.id,
                        );
                        return (
                          <Row
                            key={activityAudienceType.id}
                            className="mt-5 h-9"
                          >
                            <CheckboxRadioInput
                              label={activityAudienceType.name}
                              value={activityAudienceType.id}
                              onChange={handleRadioInputChange}
                              checked={!!checked}
                            />
                            {checked && (
                              <NumberInput
                                placeholder="Qty"
                                className="w-12 h-9 ml-3 mr-2 text-center flex-shrink-0"
                                value={checked.quantity.toString()} // get rid of leading 0 on user input
                                min={0}
                                onChange={(e) =>
                                  handleAudienceQtyChange(
                                    +e.target.value,
                                    activityAudienceType.id,
                                  )
                                }
                              />
                            )}
                          </Row>
                        );
                      },
                    )}
                  </Column>
                );
              })}
            </Row>
          </Accordion>
        );
      })}
    </div>
  );
};
