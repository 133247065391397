import Axios, { AxiosError, AxiosResponse } from 'axios';

const apiAxios = Axios.create({ baseURL: '/api' });

apiAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error?.response?.data?.error?.message) {
      throw new Error(error.response.data.error.message);
    }
    throw error;
  },
);

export { apiAxios };
