import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Column } from 'components/Column/Column';
import { DonutChartData } from 'types/report/DonutChart';
import { Row } from 'components/Row/Row';
import { calculatePercentage } from 'shared/lib/utils/calculatePercentage';
import { formatPercentValue } from 'shared/lib/utils/formatPercentValue';

interface Props extends ComponentPropsWithoutRef<'div'> {
  chartData: DonutChartData;
}

export const ActivityTypeDonutChartLegend: FC<Props> = ({
  chartData,
  className = '',
  ...rest
}) => {
  const totals = chartData.reduce(
    (sum, chartDataItem) => sum + chartDataItem.value,
    0,
  );
  return (
    <Column className={`${className} w-full`} {...rest}>
      {chartData.map((chartDataItem) => (
        <Row
          key={chartDataItem.name}
          className="items-center justify-between mb-1"
        >
          <div className="text-sm text-right flex-1 overflow-ellipsis overflow-hidden">
            {chartDataItem.name}
          </div>
          <div
            className="w-4 h-4 ml-1.5"
            style={{ background: chartDataItem.color }}
          />
          <div className="flex-1 ml-1.5 text-sm">
            {chartDataItem.value} (
            {formatPercentValue(
              calculatePercentage(chartDataItem.value, totals),
            )}
            )
          </div>
        </Row>
      ))}
      <Row className="items-center justify-between mb-1">
        <div className="text-sm text-right flex-1 overflow-ellipsis overflow-hidden font-semibold">
          Total:
        </div>
        <div className="w-4 h-4 ml-1.5"></div>
        <div className="flex-1 ml-1.5 text-sm font-semibold">{totals}</div>
      </Row>
    </Column>
  );
};
