import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { Column } from 'components/Column/Column';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import { Row } from 'components/Row/Row';
import useToggleState from '@emberex/react-utils/lib/useToggleState';

interface Props extends ComponentPropsWithoutRef<'div'> {
  label: string;
  panelClassName?: string;
}

export const Accordion: FC<Props> = ({
  label,
  panelClassName = '',
  children,
  ...props
}) => {
  const [on, toggle] = useToggleState(false);

  return (
    <Column {...props}>
      <Row className="items-center">
        <CircularActionButton
          className={`w-6 h-6 duration-500 ${
            on ? 'transform rotate-180' : 'transform rotate-0'
          }`}
          icon={CircularActionIconKind.DOWN_CHEVRON_ROUND}
          onClick={toggle}
        />
        <div className="ml-2.5 font-sf-pro-semibold">{label}</div>
      </Row>
      <div
        className={`overflow-hidden transition-max-height ${
          !on
            ? 'duration-500 ease-out max-h-0'
            : 'duration-1000 ease-in-out max-h-screen'
        } ${panelClassName}`}
      >
        {children}
      </div>
    </Column>
  );
};
