import React, { FC, useCallback } from 'react';
import { ActivitySchoolDistrict } from 'shared/lib/types/ActivitySchoolDistrict';
import {
  DropdownCheckboxInput,
  DropdownCheckboxInputProps,
} from 'components/DropdownCheckboxInput/DropdownCheckboxInput';

interface Props
  extends Omit<
    DropdownCheckboxInputProps,
    'label' | 'placeholder' | 'onChange'
  > {
  onChange(counties: Pick<ActivitySchoolDistrict, 'schoolDistrictId'>[]): void;
}

export const ActivitySchoolDistrictInput: FC<Props> = ({
  onChange,
  ...props
}) => {
  const handleSchoolDistrictDropdownChange = useCallback(
    (schoolDistrictIds: number[]) => {
      onChange(
        schoolDistrictIds.map((schoolDistrictId) => ({ schoolDistrictId })),
      );
    },
    [onChange],
  );
  return (
    <DropdownCheckboxInput
      label="School Districts"
      placeholder="Choose School Districts"
      onChange={handleSchoolDistrictDropdownChange}
      {...props}
    />
  );
};
