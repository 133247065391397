import React, {
  ComponentPropsWithoutRef,
  FC,
  FormEventHandler,
  useCallback,
} from 'react';
import {
  filterCreatorIconActive,
  filterCreatorIconInactive,
  filterCollaboratorIconActive,
  filterCollaboratorIconInactive,
  filterAllIconActive,
  filterAllIconInactive,
} from 'assets/images';
import { ActivityOwnerFilter } from 'enums/ActivityOwnerFilter';

export interface ActivityOwnerRadioInputProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value: ActivityOwnerFilter;
  onChange(value: ActivityOwnerFilter): unknown;
}

export const ActivityOwnerRadioInput: FC<ActivityOwnerRadioInputProps> = ({
  value,
  onChange,
  className = '',
  ...props
}) => {
  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    (event) => onChange(event.currentTarget.value as ActivityOwnerFilter),
    [onChange],
  );

  return (
    <div {...props} className={`flex w-37.5 h-7.5 ${className}`}>
      <ActivityOwnerRadioButton
        value={ActivityOwnerFilter.CREATOR}
        selectedValue={value}
        description="My Activities"
        onChange={handleChange}
        className="border rounded-l-full"
      />
      <ActivityOwnerRadioButton
        value={ActivityOwnerFilter.COLLABORATOR}
        selectedValue={value}
        description="Colaborator Activities"
        onChange={handleChange}
        className="border-t border-b"
      />
      <ActivityOwnerRadioButton
        value={ActivityOwnerFilter.ALL}
        selectedValue={value}
        description="All Activities"
        onChange={handleChange}
        className="border rounded-r-full"
      />
    </div>
  );
};

const filterIcons: Record<
  ActivityOwnerFilter,
  [activeIcon: string, inactiveIcon: string]
> = {
  [ActivityOwnerFilter.CREATOR]: [
    filterCreatorIconActive,
    filterCreatorIconInactive,
  ],
  [ActivityOwnerFilter.COLLABORATOR]: [
    filterCollaboratorIconActive,
    filterCollaboratorIconInactive,
  ],
  [ActivityOwnerFilter.ALL]: [filterAllIconActive, filterAllIconInactive],
};

const ActivityOwnerRadioButton: FC<{
  value: ActivityOwnerFilter;
  selectedValue: ActivityOwnerFilter;
  description: string;
  onChange: FormEventHandler<HTMLInputElement>;
  className: string;
}> = ({ value, selectedValue, description, onChange, className }) => {
  const isChecked = value === selectedValue;
  const [activeIcon, inactiveIcon] = filterIcons[value];

  return (
    <label
      className={`flex justify-center items-center w-1/3 py-1 border-tnf-green ${
        isChecked ? 'bg-tnf-green' : 'bg-white'
      } ${className}`}
    >
      <input
        type="radio"
        name="activity-owner-filter"
        value={value}
        checked={isChecked}
        onChange={onChange}
        className="sr-only"
      />
      <img
        src={isChecked ? activeIcon : inactiveIcon}
        alt={description}
        className="max-h-full"
      />
    </label>
  );
};
