import React, { FC } from 'react';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { ActivityTypeForm } from 'components/ActivityTypeForm/ActivityTypeForm';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { ActivityTypeFormProps } from 'types/ActivityTypeFormProps';

export const ActivityFormStep3: FC<Omit<
  ActivityTypeFormProps,
  'activityTypeKindList'
>> = (props) => {
  const { activityTypeKindList } = useActivityFormContext();
  return (
    <ActivityFormStepWrapper heading="Type of Activity">
      <ActivityTypeForm
        activityTypeKindList={activityTypeKindList}
        {...props}
      />
    </ActivityFormStepWrapper>
  );
};
