import { UserRole } from 'shared/lib/constants/user/UserRole';
import { User } from 'shared/lib/types/User';

export function filterVisibleUsers(
  users: User[],
): (User & { role: Omit<UserRole, UserRole.NON_COLLABORATOR_SUPER_ADMIN> })[] {
  return users.filter(
    ({ role }) =>
      !(
        role === UserRole.NON_COLLABORATOR_SUPER_ADMIN ||
        role === UserRole.NON_COLLABORATOR_SUPER_ADMIN_READ_ONLY
      ),
  );
}
