import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';

interface Props extends ComponentPropsWithoutRef<'div'> {
  label?: string;
  quantity: number;
}

export const ReadOnlyNumberField: FC<Props> = ({
  label = '',
  quantity,
  className = '',
  ...props
}) => (
  <Row className={`items-center ${className}`} {...props}>
    <div className="detail-text">{label}</div>
    <div className="flex items-center justify-center ml-3 rounded border border-gray-500 text-blue-500 w-12 h-9 font-sf-pro-medium">
      {quantity}
    </div>
  </Row>
);
