import React, { FC, useCallback, useState } from 'react';
import { PublicPageWrapper } from 'components/PublicPageWrapper/PublicPageWrapper';
import { SemiBoldHeader } from 'components/SemiBoldHeader/SemiBoldHeader';
import { ForgotPasswordForm } from 'components/ForgotPasswordForm/ForgotPasswordForm';
import { forgotPassword } from 'api/auth/forgotPassword';
import { ForgotPasswordEmailConfirmation } from 'components/ForgotPasswordEmailConfirmation/ForgotPasswordEmailConfirmation';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

export const ForgotPasswordPage: FC = () => {
  const [email, setEmail] = useState('');
  const [emailSentConfirmation, setEmailSentConfirmation] = useState(false);
  const { snackbar } = useSnackbarContext();

  const handleSubmit = useCallback(async () => {
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
    try {
      await forgotPassword(email);
      setEmailSentConfirmation(true);
      snackbar.hide();
    } catch (e) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(e),
      });
    }
  }, [email, snackbar]);

  return (
    <PublicPageWrapper>
      {emailSentConfirmation ? (
        <ForgotPasswordEmailConfirmation />
      ) : (
        <>
          <SemiBoldHeader text="Forgot Password" />
          <div className="subheading-sm">
            Enter your email, and we will send you steps to recover your
            password.
          </div>
          <ForgotPasswordForm
            email={email}
            onEmailChange={(value) => setEmail(value)}
            onSubmit={handleSubmit}
          />
        </>
      )}
    </PublicPageWrapper>
  );
};
