import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';

interface Props extends ComponentPropsWithoutRef<'div'> {
  filename: string;
  onClose(): void;
  onConfirmDelete(): void;
  onCancel(): void;
}
export const DeleteExistingFileConfirmationModal: FC<Props> = ({
  filename,
  onClose,
  onConfirmDelete,
  onCancel,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Delete Existing File</div>
    <div>Are you sure you wish to delete {filename}?</div>
    <ButtonGroup className="mt-9 border border-white w-3/5">
      <button className="text-white" onClick={onConfirmDelete}>
        Delete
      </button>
      <button className="bg-white text-blue-400" onClick={onCancel}>
        Cancel
      </button>
    </ButtonGroup>
  </CloseableCard>
);
