import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityCardBadge } from 'components/ActivityCardBadge/ActivityCardBadge';

export const ActivityQuarterlyReportBadge: FC<ComponentPropsWithoutRef<
  'div'
>> = (props) => (
  <ActivityCardBadge
    label="Quarterly Report"
    badgeLabel="Q"
    badgeClassName="bg-tnf-green-500"
    className="text-tnf-green-500"
    {...props}
  />
);
