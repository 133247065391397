import { useUserContext } from 'contexts/userContext';
import { useState, useCallback, useEffect } from 'react';
import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityAttendee } from 'shared/lib/types/ActivityAttendee';
import { ActivityCounty } from 'shared/lib/types/ActivityCounty';
import { ActivitySchoolDistrict } from 'shared/lib/types/ActivitySchoolDistrict';
import { County } from 'shared/lib/types/County';
import { DropdownOption } from 'types/Dropdown';
import { FillableActivityAreaInvolvedFormFields } from 'types/FillableActivityAreaInvolvedFormFields';

const defaultAreaInvolvedFields: FillableActivityAreaInvolvedFormFields = {
  areaInvolved: AreaInvolved.COUNTY,
  school: null,
  counties: [],
  schoolDistricts: [],
  attendees: [],
};

export interface ActivityAreaInvolvedFromReturnValues {
  selectedSuperAdminRegionId?: number;
  areaInvolvedFields: FillableActivityAreaInvolvedFormFields;
  onSuperAdminRegionChanged(regionDropdownOption: DropdownOption): void;
  onAreaInvolvedChanged(areaInvolved: AreaInvolved): void;
  onSchoolChanged(school: string): void;
  onCountiesChanged(counties: ActivityCounty[]): void;
  onSchoolDistrictsChanged(schoolDistricts: ActivitySchoolDistrict[]): void;
  onAttendeesChanged(attendees: ActivityAttendee[]): void;
}

interface Props {
  activity?: Activity;
  regionalCounties: County[];
}

export function useActivityAreaInvolvedForm({
  activity,
  regionalCounties,
}: Props): ActivityAreaInvolvedFromReturnValues {
  const [selectedSuperAdminRegionId, setSelectedSuperAdminRegionId] = useState<
    number
  >();
  const [areaInvolvedFields, setAreaInvolvedFields] = useState<
    FillableActivityAreaInvolvedFormFields
  >(defaultAreaInvolvedFields);

  const { user } = useUserContext();

  const onSchoolChanged = useCallback((school: string) => {
    setAreaInvolvedFields((prevState) => ({
      ...prevState,
      school,
    }));
  }, []);

  const onSchoolDistrictsChanged = useCallback(
    (schoolDistricts: ActivitySchoolDistrict[]) => {
      setAreaInvolvedFields((prevState) => ({
        ...prevState,
        schoolDistricts,
      }));
    },
    [],
  );

  const onCountiesChanged = useCallback(
    (counties: ActivityCounty[]) => {
      const availableSchoolDistricts = regionalCounties
        .filter(({ id }) => counties.some(({ countyId }) => id === countyId))
        .flatMap(({ schoolDistricts }) => schoolDistricts)
        .map((schoolDistrict) => schoolDistrict?.id);

      setAreaInvolvedFields((prevState) => ({
        ...prevState,
        counties,
        schoolDistricts: prevState.schoolDistricts?.filter((sd) =>
          availableSchoolDistricts.includes(sd.schoolDistrictId),
        ),
      }));

      if (counties.length === 0) {
        onSchoolDistrictsChanged([]);
      }
    },
    [regionalCounties, onSchoolDistrictsChanged],
  );

  const onAttendeesChanged = useCallback((attendees: ActivityAttendee[]) => {
    setAreaInvolvedFields((prevState) => ({
      ...prevState,
      attendees,
    }));
  }, []);

  const onAreaInvolvedChanged = useCallback(
    (areaInvolved: AreaInvolved) => {
      if (areaInvolved === AreaInvolved.STATEWIDE) {
        // Clear counties, school districts and school
        onCountiesChanged([]);
        onSchoolDistrictsChanged([]);
        onSchoolChanged('');
      }
      setAreaInvolvedFields((prevState) => ({
        ...prevState,
        areaInvolved,
      }));
    },
    [onCountiesChanged, onSchoolChanged, onSchoolDistrictsChanged],
  );

  /**
   * Super Admin function only
   */
  const onSuperAdminRegionChanged = useCallback(
    (dropdownOption: DropdownOption) => {
      onCountiesChanged([]);
      onSchoolDistrictsChanged([]);
      setSelectedSuperAdminRegionId(+dropdownOption.id);
    },
    [onCountiesChanged, onSchoolDistrictsChanged],
  );

  useEffect(() => {
    if (activity) {
      setAreaInvolvedFields({
        areaInvolved: activity.areaInvolved,
        school: activity.school,
        counties: activity.counties,
        schoolDistricts: activity.schoolDistricts,
        attendees: activity.attendees,
      });

      /**
       * Default Super Admin region dropdown to the
       * activity's county list region OR Super Admin's
       * default region if existing activity does not have any counties
       *  */
      setSelectedSuperAdminRegionId(
        regionalCounties?.find((county) => county)?.regionId ??
          user?.region?.id,
      );
    } else {
      setSelectedSuperAdminRegionId(user?.region?.id);
    }
  }, [activity, regionalCounties, user]);

  return {
    selectedSuperAdminRegionId,
    areaInvolvedFields,
    onAreaInvolvedChanged,
    onSchoolChanged,
    onCountiesChanged,
    onSchoolDistrictsChanged,
    onAttendeesChanged,
    onSuperAdminRegionChanged,
  };
}
