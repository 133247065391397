import { apiAxios } from 'api/apiAxios';
import { FillableUserFormProps } from 'types/FillableUserFormProps';

export async function updateUser(
  userId: number,
  request: FillableUserFormProps,
) {
  const response = await apiAxios.patch(`/users/${userId}`, request);
  return response.data;
}
