import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Button } from 'components/Button/Button';
import { Column } from 'components/Column/Column';
import { Notification } from 'shared/lib/types/Notification';
import formatName from 'shared/lib/utils/formatName';
import './ActivityCollaboratorNotificationModal.css';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  notificationList: Notification[];
}

export const ActivityCollaboratorNotificationModal: FC<Props> = ({
  notificationList,
  onClose,
  className = '',
  ...rest
}) => (
  <Column
    className={`w-80 rounded py-3.5 px-3 bg-blue-400 z-50 ${className}`}
    {...rest}
  >
    <div className="max-h-52 overflow-y-auto text-white text-sm font-sf-pro-medium">
      <ul className="notification-list">
        {notificationList.map(({ id, activityCollaboratorNotification }) => {
          const user = activityCollaboratorNotification?.activity?.user?.user;
          const activity = activityCollaboratorNotification?.activity;
          const senderName = user ? formatName(user) : '';
          return (
            <li className="pr-14" key={id}>
              {`${senderName} has added you the activity "${
                activity?.title ?? 'N/A'
              }"`}
            </li>
          );
        })}
      </ul>
    </div>
    <Button className="bg-white w-28 h-12 text-blue-400 mt-4" onClick={onClose}>
      Ok
    </Button>
  </Column>
);
