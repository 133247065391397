import { ActivityFileIconKind } from 'enums/ActivityFileIconKind';

export function getFileIconFromExtension(fileExtension: string) {
  switch (fileExtension.toLowerCase()) {
    case 'pdf':
      return ActivityFileIconKind.PDF;
    case 'jpg':
      return ActivityFileIconKind.IMAGE;
    case 'tiff':
      return ActivityFileIconKind.IMAGE;
    case 'png':
      return ActivityFileIconKind.IMAGE;

    case 'svg':
      return ActivityFileIconKind.IMAGE;

    case 'doc':
      return ActivityFileIconKind.DOC;

    case 'docx':
      return ActivityFileIconKind.DOC;

    case 'mp3':
      return ActivityFileIconKind.MP3;

    case 'wav':
      return ActivityFileIconKind.MP3;

    case 'aiff':
      return ActivityFileIconKind.MP3;

    case 'mp4':
      return ActivityFileIconKind.MP4;

    default:
      return ActivityFileIconKind.OTHER;
  }
}
