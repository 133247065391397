import { useCallback } from 'react';
import { createActivity } from 'api/activity/createActivity';
import { updateActivity } from 'api/activity/updateActivity';
import { uploadActivityFiles } from 'api/activity/uploadActivityFiles';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { FillableActivityFormFields } from 'types/FillableActivityFormFields';
import getErrorMessage from 'utils/getErrorMessage';
import { Activity } from 'shared/lib/types/Activity';

export interface ReturnValues {
  onCreateActivity(
    activityFormFields: FillableActivityFormFields,
  ): Promise<Activity | undefined>;
  onUpdateActivity(
    activityId: number,
    activityFormFields: FillableActivityFormFields,
  ): Promise<Activity | undefined>;
}

export function useActivityFormSubmit(): ReturnValues {
  const { snackbar } = useSnackbarContext();

  const onCreateActivity = useCallback(
    async (
      formFields: FillableActivityFormFields,
    ): Promise<Activity | undefined> => {
      try {
        snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
        const activity = await createActivity({ ...formFields, files: [] });
        if (activity && formFields.files) {
          await uploadActivityFiles(activity.id, formFields.files);
        }
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'Activity Created Successfully!',
        });
        return activity;
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [snackbar],
  );

  const onUpdateActivity = useCallback(
    async (
      activityId: number,
      formFields: FillableActivityFormFields,
    ): Promise<Activity | undefined> => {
      try {
        snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
        if (activityId && formFields.files) {
          await uploadActivityFiles(activityId, formFields.files);
        }
        const activity = await updateActivity(activityId, {
          ...formFields,
          files: [],
        });
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'Activity Updated Successfully!',
        });
        return activity;
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [snackbar],
  );

  return {
    onCreateActivity,
    onUpdateActivity,
  };
}
