import sortBy from 'lodash/sortBy';
import { User } from 'shared/lib/types/User';

export function sortUsersByRegion(users: User[]) {
  return sortBy(
    users,
    (user) => user.region?.name,
    (user) => user.firstName.toLowerCase(),
  );
}
