import { useCallback, useState } from 'react';

interface SearchProps {
  search: string;
  onSearchChanged(search: string): void;
}

export function useSearch(): SearchProps {
  const [search, setSearch] = useState('');

  const onSearchChanged = useCallback((value: string) => {
    setSearch(value);
  }, []);

  return {
    search,
    onSearchChanged,
  };
}
