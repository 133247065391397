import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CircularActionButtonIcon } from 'components/CircularActionButtonIcon/CircularActionButtonIcon';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';

interface Props extends ComponentPropsWithoutRef<'button'> {
  icon: CircularActionIconKind;
}

export const CircularActionButton: FC<Props> = ({
  icon,
  onClick,
  className = '',
}) => (
  <button
    className={`${className} circular-btn-action-wrapper`}
    onClick={onClick}
  >
    <CircularActionButtonIcon icon={icon} />
  </button>
);
