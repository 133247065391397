type UrlParams = { [k: string]: string };

export default function getUrlParams(parameters: string[]): Promise<UrlParams> {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    for (let param of parameters) {
      if (!urlParams.has(param) || !urlParams.get(param)) {
        reject(`Missing or empty URL parameter of ${param}`);
      }
    }
    const entries = urlParams.entries();
    resolve(Object.fromEntries(entries));
  });
}
