import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { ActivityFollowUp } from 'shared/lib/constants/activity/ActivityFollowUp';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { Column } from 'components/Column/Column';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { Row } from 'components/Row/Row';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  selected: boolean | null;
  onChange(value: boolean): void;
}

export const ActivityFollowUpRadioInput: FC<Props> = ({
  selected,
  onChange,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      onChange(value === ActivityFollowUp.FOLLOW_UP);
    },
    [onChange],
  );

  return (
    <Column {...props}>
      <ActivityFormInputLabel className="mb-2">
        Follow-up to Activity
      </ActivityFormInputLabel>
      <Row>
        <RadioInput
          label="No Follow-up"
          value={ActivityFollowUp.NO_FOLLOW_UP}
          checked={selected === false}
          onChange={handleRadioInputChange}
        />
        <RadioInput
          label="Follow-up"
          value={ActivityFollowUp.FOLLOW_UP}
          className="ml-6"
          checked={selected === true}
          onChange={handleRadioInputChange}
        />
      </Row>
    </Column>
  );
};
