import { useMemo } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityFilter } from 'types/ActivityFilter';
import { filterActivities } from 'utils/filterActivities';

interface ActivityFilterParams {
  activityList: Activity[];
  activityFilter: ActivityFilter;
}

export function useActivityFilter({
  activityList,
  activityFilter,
}: ActivityFilterParams): Activity[] {
  return useMemo(() => filterActivities(activityList, activityFilter), [
    activityList,
    activityFilter,
  ]);
}
