import React, {
  ComponentPropsWithoutRef,
  FC,
  useState,
  useEffect,
} from 'react';
import { AudiencePerson } from 'components/AudiencePerson/AudiencePerson';
import { Row } from 'components/Row/Row';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityAudience } from 'shared/lib/types/ActivityAudience';
import { ActivityAudienceChartData } from 'types/report/ActivityReport';
import { calculatePercentage } from 'shared/lib/utils/calculatePercentage';
import { Column } from 'components/Column/Column';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activityList: Activity[];
}

function hasAudienceType(
  audience: ActivityAudience,
): audience is ActivityAudience &
  Required<Pick<ActivityAudience, 'audienceType'>> {
  return audience.audienceType !== undefined;
}

export const ActivityAudienceChart: FC<Props> = ({
  activityList,
  className = '',
  ...rest
}) => {
  const [chartData, setChartData] = useState<ActivityAudienceChartData>([]);

  useEffect(() => {
    if (activityList) {
      const audienceTypes = activityList.reduce<
        { audienceTypeName: string; quantity: number }[]
      >((result, { audience, otherAudienceType }) => {
        if (audience) {
          result.push(
            ...audience
              .filter(hasAudienceType)
              .map(({ audienceType, quantity }) => ({
                audienceTypeName: audienceType.name,
                quantity,
              })),
          );
        }
        if (otherAudienceType) {
          result.push({ audienceTypeName: otherAudienceType, quantity: 1 });
        }
        return result;
      }, []);

      const audienceTypeTotals = audienceTypes.reduce<Record<string, number>>(
        (result, { audienceTypeName, quantity }) => {
          result[audienceTypeName] = (result[audienceTypeName] ?? 0) + quantity;
          return result;
        },
        // An object without any properties or methods so that nothing breaks if
        // the user types in something like "toString" for otherAudienceType.
        Object.create(null),
      );

      setChartData(
        Object.entries(audienceTypeTotals)
          .map(([audienceTypeName, quantity]) => ({
            audienceTypeName,
            quantity,
          }))
          .sort((a, b) => b.quantity - a.quantity),
      );
    }
  }, [activityList]);

  const totals = chartData.reduce(
    (sum, chartDataItem) => sum + chartDataItem.quantity,
    0,
  );
  return (
    <Column>
      <label className="text-center mt-4 font-semibold">Total: {totals}</label>
      <Row className={`flex-wrap ${className}`} {...rest}>
        {chartData.length ? (
          <>
            {chartData.map(({ audienceTypeName, quantity }, i) => (
              <AudiencePerson
                key={audienceTypeName}
                className="w-44"
                quantity={quantity}
                percentage={calculatePercentage(quantity, totals)}
                audienceTypeName={audienceTypeName}
                opacity={1 - i / chartData.length}
              />
            ))}
          </>
        ) : (
          <div>No data available</div>
        )}
      </Row>
    </Column>
  );
};
