import React, { ComponentPropsWithoutRef, FC } from 'react';
import './NumberInput.css';

export const NumberInput: FC<ComponentPropsWithoutRef<'input'>> = ({
  className = '',
  ...props
}) => (
  <input
    type="number"
    className={`number-input border border-gray-500 rounded font-sf-pro-medium text-base ${className}`}
    {...props}
  />
);
