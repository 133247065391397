import { apiAxios } from 'api/apiAxios';
import FileDownload from 'js-file-download';
import { ActivityFile } from 'shared/lib/types/ActivityFile';

export async function downloadActivityFile(
  activityId: number,
  activityFile: ActivityFile,
) {
  const res = await apiAxios
    .get(`/activity/${activityId}/fileUpload/${activityFile.id}`, {
      responseType: 'blob',
    })
    .catch(() => {
      return null;
    });
  if (res) {
    FileDownload(res.data, activityFile.filename);
  }
}
