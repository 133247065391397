import React, { ComponentPropsWithoutRef, FC } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';

interface Props extends ComponentPropsWithoutRef<'div'> {
  userFullName: string;
  onClose: () => void;
  onDelete: () => void;
}

export const RemoveUserConfirmationCard: FC<Props> = ({
  userFullName,
  onClose,
  onDelete,
  className = '',
}) => (
  <CloseableCard className={className} onClose={onClose}>
    <div className="card-header">Remove User</div>
    <div>Do you wish to remove ‘{userFullName}’?</div>
    <ButtonGroup className="mt-9 border border-white">
      <button className="text-white" onClick={onClose}>
        No
      </button>
      <button className="bg-white text-blue-400" onClick={onDelete}>
        Yes, Remove
      </button>
    </ButtonGroup>
  </CloseableCard>
);
