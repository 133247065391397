import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';

export interface ActivityAppBarProps extends ComponentPropsWithoutRef<'div'> {
  onSaveProgressClick(): void;
  onCancelClick(): void;
  headerLabel: string;
}

export const ActivityFormAppBar: FC<ActivityAppBarProps> = ({
  headerLabel,
  onSaveProgressClick,
  onCancelClick,
  ...props
}) => {
  return (
    <div
      className={`w-full bg-tnf-green pl-20 pr-11 py-5 ${props.className}`}
      {...props}
    >
      <Row className="justify-between">
        <div className="font-sf-pro-semibold text-white">{headerLabel}</div>
        <Row className="text-sm text-white">
          <button onClick={onSaveProgressClick} className="mr-14">
            Save Progress
          </button>
          <button onClick={onCancelClick}>Cancel</button>
        </Row>
      </Row>
    </div>
  );
};
