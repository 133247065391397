import React, { FC } from 'react';
interface Props {
  text: string;
  className?: string;
}
export const SemiBoldHeader: FC<Props> = ({ text, className }) => (
  <div className={`font-sf-pro-semibold text-tnf-green text-4xl ${className}`}>
    {text}
  </div>
);
