import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Snackbar } from 'components/Snackbar/Snackbar';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { SnackbarPositions } from 'enums/SnackbarPositions';

interface SnackbarSpinnerOverlayProps extends ComponentPropsWithoutRef<'div'> {
  open?: boolean;
  text?: string;
  position?: SnackbarPositions;
}

export const SnackbarSpinnerOverlay: FC<SnackbarSpinnerOverlayProps> = ({
  open,
  text = 'Please wait...',
  position,
  className = '',
  ...rest
}) => (
  <div
    className={`${
      open ? '' : 'hidden'
    } flex flex-column absolute top-0 w-full h-screen justify-center items-center z-50 ${className}`}
    {...rest}
  >
    <Snackbar
      text={text}
      open={open}
      position={position}
      severity={SnackbarSeverityKind.SPINNER}
    />
  </div>
);
