import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityCard } from 'components/ActivityCard/ActivityCard';
import { useHistory } from 'react-router-dom';
import { formatActivityDetailRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { Activity } from 'shared/lib/types/Activity';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activeActivityId?: number;
  activityList: Activity[];
  collaboratorId?: number;
}

export const ActivityCardList: FC<Props> = ({
  activeActivityId,
  activityList,
  collaboratorId,
  ...props
}) => {
  const history = useHistory();
  return (
    <div {...props}>
      {activityList.map(
        ({
          id,
          title,
          entryDate,
          areaInvolved,
          markedForQuarterlyReport,
          counties,
          schoolDistricts,
          activityType,
          collaborators,
          complete,
        }) => (
          <ActivityCard
            key={id}
            collaborator={collaborators?.some(
              ({ userId }) => userId === collaboratorId,
            )}
            unfinished={!complete}
            className="mb-2.5"
            active={activeActivityId === id}
            title={title}
            date={entryDate}
            areaInvolved={areaInvolved}
            markedForQuarterlyReport={markedForQuarterlyReport}
            onClick={() => history.push(formatActivityDetailRoute(id))}
            counties={counties?.map(({ county }) => county?.name) as string[]}
            activityType={
              activityType?.map(
                (type) => type.activityTypeKind?.name,
              ) as string[]
            }
            schoolDistricts={
              schoolDistricts?.map(
                ({ schoolDistrict }) => schoolDistrict?.name,
              ) as string[]
            }
            region={
              counties && counties?.length && counties[0].county?.region
                ? counties[0].county?.region?.name
                : '--'
            }
          />
        ),
      )}
    </div>
  );
};
