import React, { forwardRef } from 'react';
import { calendarIcon } from 'assets/images';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';

interface Props {
  value?: Date;
  onClick?(): void;
  label: string;
}

export const DatePickerWithIcon = forwardRef<HTMLButtonElement, Props>(
  ({ value, label, onClick }, ref) => (
    <button
      onClick={onClick}
      ref={ref}
      className="border-b-2 border-gray-500 cursor-pointer w-full"
    >
      <Column>
        <div className="input-label mb-1">{label}</div>
        <Row className="justify-between">
          <div className="h-7 w-full font-sf-pro-medium text-base">{value}</div>
          <img src={calendarIcon} alt="Calendar" />
        </Row>
      </Column>
    </button>
  ),
);
