import React, { FC } from 'react';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { ActivityDeliverablesForm } from 'components/ActivityDeliverablesForm/ActivityDeliverablesForm';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { ActivityDeliverableFormProps } from 'types/ActivityDeliverableFormProps';

export const ActivityFormStep4: FC<Omit<
  ActivityDeliverableFormProps,
  'activityDeliverableKindList'
>> = (props) => {
  const { activityDeliverableTypeKindList } = useActivityFormContext();
  return (
    <ActivityFormStepWrapper heading="Deliverables">
      <ActivityDeliverablesForm
        {...props}
        activityDeliverableKindList={activityDeliverableTypeKindList}
      />
    </ActivityFormStepWrapper>
  );
};
