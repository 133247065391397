import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Dropdown } from 'components/Dropdown/Dropdown';
import {
  DropdownOption,
  DropdownOptionItemProps,
  DropdownProps,
} from 'types/Dropdown';

export interface DropdownInputProps<T = string | number>
  extends Pick<
    DropdownProps<T>,
    | 'label'
    | 'className'
    | 'readOnly'
    | 'readOnlyDisabled'
    | 'placeholder'
    | 'options'
  > {
  disabled?: boolean;
  selected?: T;
  onChange(option: DropdownOption<T>): void;
}

export const DropdownInput = <T extends unknown = string | number>({
  options,
  disabled,
  selected,
  onChange,
  ...rest
}: PropsWithChildren<DropdownInputProps<T>>) => {
  const [open, setOpen] = useState(false);

  const handleOptionSelection = useCallback(
    (selectedOption: DropdownOption<T>) => {
      onChange(selectedOption);
      setOpen(false);
    },
    [onChange],
  );

  const handleDropdownOpen = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  const selectedLabel = options.find(
    (optionValue) => optionValue.id === selected,
  )?.value;

  const DropdownOptionItem = ({
    index,
    style,
    data,
  }: DropdownOptionItemProps<DropdownOption<T>>) => (
    <div
      style={style}
      onClick={() => {
        handleOptionSelection(data[index]);
      }}
      className={`dropdown-li px-4 py-2.5 font-sf-pro-medium text-sm cursor-pointer bg-white 
      ${data[index].id === selected ? 'text-tnf-green-400' : ''}`}
    >
      {data[index].value}
    </div>
  );

  return (
    <Dropdown
      options={options}
      selectedLabel={selectedLabel}
      open={open}
      onOpenChange={handleDropdownOpen}
      optionItem={DropdownOptionItem}
      readOnlyDisabled={disabled}
      {...rest}
    />
  );
};
