import React, { FC, useCallback, useState } from 'react';
import { PageSegmentHeadingIcons } from 'enums/PageSegmentHeadingIcons';
import formatName from 'shared/lib/utils/formatName';
import {
  formatUserDetailRoute,
  formatUserRemoveRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { User } from 'shared/lib/types/User';
import { UserManagementForm } from 'components/UserManagementForm/UserManagementForm';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { FillableUserFormProps } from 'types/FillableUserFormProps';
import { useHistory } from 'react-router';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { updateUser } from 'api/user/updateUser';
import getErrorMessage from 'utils/getErrorMessage';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getUserById } from 'api/user/getUserById';

interface Props {
  userId: number;
}

export const EditUser: FC<Props> = ({ userId }) => {
  const [user, setUser] = useState<User>();
  const history = useHistory();
  const { snackbar } = useSnackbarContext();

  const handleSubmit = useCallback(
    async (data: FillableUserFormProps) => {
      snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
      try {
        if (userId) {
          await updateUser(userId, data);
          snackbar.show({
            severity: SnackbarSeverityKind.SUCCESS,
            text: 'User updated successfully!',
          });
          history.push(formatUserDetailRoute(userId));
        }
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [history, snackbar, userId],
  );

  useAsyncEffect(async () => {
    const user = await getUserById(userId);
    if (user) {
      setUser(user);
    }
  }, [userId]);

  return (
    <UsersManagementPage userId={userId}>
      {user && (
        <>
          <PageSegmentHeading
            icon={PageSegmentHeadingIcons.TRASH}
            onIconClick={() => history.push(formatUserRemoveRoute(user.id))}
          >
            {formatName(user)}
          </PageSegmentHeading>
          <UserManagementForm user={user} onSubmit={handleSubmit} />
        </>
      )}
    </UsersManagementPage>
  );
};
