import React, { FC } from 'react';
import { MapNavigationItem } from 'components/MapNavigationItem/MapNavigationItem';
import { ListNavigationItem } from 'components/ListNavigationItem/ListNavigationItem';
import { Row } from 'components/Row/Row';
import { ReportNavigationItem } from 'components/ReportNavigationItem/ReportNavigationItem';
import { SettingsNavigationItem } from 'components/SettingsNavigationItem/SettingsNavigationItem';

export const Navigation: FC = (props) => (
  <Row className="justify-between w-72">
    <MapNavigationItem />
    <ListNavigationItem />
    <ReportNavigationItem />
    <SettingsNavigationItem />
  </Row>
);
