import React, { FC } from 'react';
import { AreaInvolvedRadioInput } from 'components/AreaInvolvedRadioInput/AreaInvolvedRadioInput';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';
import { TextInput } from 'components/TextInput/TextInput';
import { ActivityCountyInput } from 'components/ActivityCountyInput/ActivityCountyInput';
import { ActivityAttendeeInput } from 'components/ActivityAttendeeInput/ActivityAttendeeInput';
import { ActivitySchoolDistrictInput } from 'components/ActivitySchoolDistrictInput/ActivitySchoolDistrictInput';
import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { ActivityAreaInvolvedFormProps } from 'types/ActivityAreaInvolvedFormProps';
import { RegionsDropdown } from 'components/RegionsDropdown/RegionsDropdown';
import { useRegionContext } from 'contexts/regionContext';

interface Props extends ActivityAreaInvolvedFormProps {
  canViewEverything?: boolean;
}

export const ActivityAreaInvolvedForm: FC<Props> = ({
  areaInvolved,
  school,
  counties,
  schoolDistricts,
  attendees,
  selectedSuperAdminRegionId,
  onAreaInvolvedChanged,
  onSchoolChanged,
  onCountiesChanged,
  onSchoolDistrictsChanged,
  onAttendeesChanged,
  onSuperAdminRegionChanged,
  regionalCountyOptions,
  regionalSchoolDistrictOptions,
  appUserOptions,
  canViewEverything,
}) => {
  const showCountiesAndSDs = areaInvolved === AreaInvolved.COUNTY;
  const { regionList } = useRegionContext();
  return (
    <div>
      <AreaInvolvedRadioInput
        selected={areaInvolved}
        onChange={onAreaInvolvedChanged}
        className="mb-6"
      />
      {canViewEverything && areaInvolved === AreaInvolved.COUNTY && (
        <Row className="activity-form-row">
          <Column className="activity-form-col mb-9">
            <RegionsDropdown
              selected={selectedSuperAdminRegionId}
              onChange={onSuperAdminRegionChanged}
              regionList={regionList}
            />
          </Column>
          <Column className="activity-form-col" />
        </Row>
      )}

      <Row className="activity-form-row justify-between">
        <Column className="activity-form-col">
          <ActivityCountyInput
            className={showCountiesAndSDs ? '' : 'hidden'}
            options={regionalCountyOptions}
            selected={counties?.map((county) => county.countyId)}
            onChange={onCountiesChanged}
          />
          <TextInput
            containerClassName={`mt-6 ${!showCountiesAndSDs && 'hidden'}`}
            onChange={(e) => onSchoolChanged(e.target.value)}
            value={school ?? ''}
            label="School, Program, or Agency"
            placeholder="Type here..."
          />
          {/* removed from form on request - keep around associated code */}
          <ActivityAttendeeInput
            className="mt-9 hidden"
            options={appUserOptions}
            selected={attendees?.map((attendee) => attendee.userId)}
            onChange={onAttendeesChanged}
          />
        </Column>
        <Column className="activity-form-col">
          <ActivitySchoolDistrictInput
            className={showCountiesAndSDs ? '' : 'hidden'}
            options={regionalSchoolDistrictOptions}
            onChange={onSchoolDistrictsChanged}
            selected={schoolDistricts?.map(
              (schoolDistrict) => schoolDistrict.schoolDistrictId,
            )}
            readOnlyDisabled={regionalSchoolDistrictOptions?.length === 0}
          />
        </Column>
      </Row>
    </div>
  );
};
