import { apiAxios } from 'api/apiAxios';

export async function deleteActivityFile(
  activityId: number,
  activityFileId: number,
) {
  return apiAxios.delete(
    `/activity/${activityId}/fileUpload/${activityFileId}`,
  );
}
