import React, { ComponentPropsWithoutRef, FC } from 'react';
import { formatDateFull } from 'shared/lib/utils/formatDateFull';
import { Activity } from 'shared/lib/types/Activity';
import { User } from 'shared/lib/types/User';
import { ActivityOverviewDetail } from 'components/ActivityOverviewDetail/ActivityOverviewDetail';
import { ActivityAreaInvolvedDetail } from 'components/ActivityAreaInvolvedDetail/ActivityAreaInvolvedDetail';
import { County } from 'shared/lib/types/County';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { ActivityTypeDetail } from 'components/ActivityTypeDetail/ActivityTypeDetail';
import { ActivityDeliverableDetail } from 'components/ActivityDeliverableDetail/ActivityDeliverableDetail';
import { ActivityAudienceDetail } from 'components/ActivityAudienceDetail/ActivityAudienceDetail';
import { ActivityCommentsAndFilesDetail } from 'components/ActivityCommentsAndFilesDetail/ActivityCommentsAndFilesDetail';
import { ActivityDetailMeatballsMenu } from 'components/ActivityDetailMeatballsMenu/ActivityDetailMeatballsMenu';
import { ActivityFile } from 'shared/lib/types/ActivityFile';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activity: Activity;
  onEditActivityClick(): void;
  onDeleteActivityClick(): void;
  onDownloadActivityFile(activityFile: ActivityFile): void;
}

export const ActivityDetail: FC<Props> = ({
  activity,
  onEditActivityClick,
  onDeleteActivityClick,
  onDownloadActivityFile,
  ...props
}) => (
  <div {...props}>
    <ActivityDetailMeatballsMenu
      className="absolute right-10 mt-3 z-40"
      onEditActivityClick={onEditActivityClick}
      onDeleteActivityClick={onDeleteActivityClick}
    />

    <ActivityOverviewDetail
      title={activity.title}
      entryDate={formatDateFull(activity.entryDate) ?? ''}
      duration={activity.duration}
      travelInvolved={activity.travelInvolved}
      followUp={activity.followUp}
      technicalAssistanceMode={activity.technicalAssistanceMode}
      activityCollaborators={
        activity.collaborators?.map(({ user }) => user as User) ?? []
      }
      author={activity.user?.user}
    />
    <ActivityAreaInvolvedDetail
      className="mt-8"
      areaInvolved={activity.areaInvolved}
      attendees={activity.attendees?.map(({ user }) => user as User) ?? []}
      counties={activity.counties?.map(({ county }) => county as County) ?? []}
      schoolDistricts={
        activity.schoolDistricts?.map(
          ({ schoolDistrict }) => schoolDistrict as SchoolDistrict,
        ) ?? []
      }
    />
    <ActivityTypeDetail
      className="mt-8"
      activityType={activity.activityType}
      otherActivityType={activity.otherActivityType}
    />
    <ActivityDeliverableDetail
      className="mt-8 page-break-after-print"
      activityDeliverables={activity.activityDeliverables}
      otherActivityDeliverable={activity.otherActivityDeliverable}
    />
    <ActivityAudienceDetail
      audience={activity.audience}
      otherAudienceType={activity.otherAudienceType}
      className="mt-8"
    />
    <ActivityCommentsAndFilesDetail
      className="mt-8"
      files={activity.files}
      comments={activity.comments}
      onDownloadActivityFile={onDownloadActivityFile}
    />
  </div>
);
