import { Column } from 'components/Column/Column';
import React, { ComponentPropsWithoutRef, FC } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {
  numberValue: number;
}

export const NumberCircle: FC<Props> = ({ numberValue, className }) => (
  <Column className={`circle-badge ${className}`}>{numberValue}</Column>
);
