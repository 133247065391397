import { useCallback, useState } from 'react';
import { isEqual, startOfDay } from 'date-fns';
import { YearQuarter } from 'shared/lib/types/YearQuarter';
import { getSchoolYearStartDate } from 'shared/lib/utils/getSchoolYearStartDate';
import { getYearQuarterDateRange } from 'shared/lib/utils/getYearQuarterDateRange';
import { getCurrentQuarter } from 'shared/lib/utils/quarter';

interface QuarterDateRange {
  /** The YearQuarter containing startDate. */
  yearQuarter: YearQuarter;
  /** Date range start. Start of day in local time. */
  startDate: Date;
  /** Date range end. Start of day in local time. */
  endDate: Date;
  /** Do startDate and endDate exactly match the range for yearQuarter? */
  isDateRangeAlignedWithQuarter: boolean;
}

interface QuarterDateRangeReturnValues {
  /**
   * Contains the date range state.
   *
   * - startDate will always be contained within yearQuarter.
   *
   * - startDate and endDate are the "source of truth" for the state. This is
   *   relevant when they are not aligned with the date range for yearQuarter.
   *
   * - startDate and endDate are NOT required to be in chronological order.
   *   Their setters will accept any date passed to them. If endDate is earlier
   *   than startDate, a filter using this state should return 0 results. If you
   *   need to avoid such a state, restrict the date input's selectable dates.
   */
  quarterDateRange: QuarterDateRange;
  /** Sets yearQuarter and updates startDate and endDate to match.  */
  setYearQuarter(yearQuarter: YearQuarter): void;
  /** Sets startDate and updates yearQuarter to the containing YearQuarter. */
  setStartDate(startDate: Date): void;
  /** Sets endDate. Does not update yearQuarter. */
  setEndDate(endDate: Date): void;
}

export function useQuarterDateRange(
  now = new Date(),
): QuarterDateRangeReturnValues {
  const [quarterDateRange, setQuarterDateRange] = useState<QuarterDateRange>(
    () => {
      const yearQuarter = {
        quarter1Year: getSchoolYearStartDate(now).getFullYear(),
        quarter: getCurrentQuarter(now),
      };
      const [startDate, endDate] = getYearQuarterDateRange(yearQuarter);
      const isDateRangeAlignedWithQuarter = true;
      return { yearQuarter, startDate, endDate, isDateRangeAlignedWithQuarter };
    },
  );

  const setYearQuarter = useCallback((yearQuarter: YearQuarter) => {
    const [startDate, endDate] = getYearQuarterDateRange(yearQuarter);
    setQuarterDateRange({
      yearQuarter,
      startDate,
      endDate,
      isDateRangeAlignedWithQuarter: true,
    });
  }, []);

  const setStartDate = useCallback((startDate: Date) => {
    const newStartDate = startOfDay(startDate);
    const newYearQuarter = {
      quarter1Year: getSchoolYearStartDate(startDate).getFullYear(),
      quarter: getCurrentQuarter(startDate),
    };
    const [qStart, qEnd] = getYearQuarterDateRange(newYearQuarter);

    setQuarterDateRange((oldValue) => ({
      ...oldValue,
      startDate: newStartDate,
      yearQuarter: newYearQuarter,
      isDateRangeAlignedWithQuarter:
        isEqual(qStart, newStartDate) && isEqual(qEnd, oldValue.endDate),
    }));
  }, []);

  const setEndDate = useCallback((endDate: Date) => {
    const newEndDate = startOfDay(endDate);
    setQuarterDateRange((oldValue) => {
      const [qStart, qEnd] = getYearQuarterDateRange(oldValue.yearQuarter);
      return {
        ...oldValue,
        endDate: newEndDate,
        isDateRangeAlignedWithQuarter:
          isEqual(qStart, oldValue.startDate) && isEqual(qEnd, newEndDate),
      };
    });
  }, []);

  return { quarterDateRange, setYearQuarter, setStartDate, setEndDate };
}
