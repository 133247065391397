import { apiAxios } from 'api/apiAxios';

interface Params {
  recipientEmail: string;
  activityId: number;
}

export async function emailActivity({ recipientEmail, activityId }: Params) {
  const response = await apiAxios.post(`/activity/email/${activityId}`, {
    recipientEmail,
  });
  return response.data;
}
