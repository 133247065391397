import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  onDeleteConfirm(): void;
}
export const DeleteActivityModal: FC<Props> = ({
  onClose,
  onDeleteConfirm,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Delete Activity</div>
    <div>Do you wish to delete this activity?</div>
    <ButtonGroup className="mt-9 border border-white">
      <button className="text-white" onClick={onClose}>
        No
      </button>
      <button className="bg-white text-blue-400" onClick={onDeleteConfirm}>
        Yes, Delete
      </button>
    </ButtonGroup>
  </CloseableCard>
);
