import { createContext, useContext } from 'react';
import { ActivityAudienceCategory } from 'shared/lib/types/ActivityAudienceCategory';
import { County } from 'shared/lib/types/County';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { User } from 'shared/lib/types/User';
import { ActivityFormStep } from 'enums/ActivityFormStep';

export interface ActivityFormContextValue {
  currentStep: ActivityFormStep;
  appUsers: User[];
  regionalCounties: County[];
  activityTypeKindList: ActivityTypeKind[];
  activityDeliverableTypeKindList: ActivityDeliverableKind[];
  activityAudienceCategories: ActivityAudienceCategory[];
  onCurrentStepChanged(step: ActivityFormStep): void;
}

export const ActivityFormContext = createContext<ActivityFormContextValue>({
  currentStep: ActivityFormStep.OVERVIEW,
  appUsers: [],
  regionalCounties: [],
  activityTypeKindList: [],
  activityDeliverableTypeKindList: [],
  activityAudienceCategories: [],
  onCurrentStepChanged() {},
});

export function useActivityFormContext() {
  return useContext(ActivityFormContext);
}
