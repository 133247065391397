import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import React, { FC } from 'react';
import { noop } from 'shared/lib/utils/noop';
import './ListGroup.css';

interface Props {
  className?: string;
  onClickOutside?: () => void;
}

export const ListGroup: FC<Props> = ({
  className = '',
  onClickOutside = noop,
  children,
}) => {
  const listGroupRef = useOnClickOutside<HTMLUListElement>(onClickOutside);
  return (
    <ul className={`w-40 ${className} list-group-nav`} ref={listGroupRef}>
      {children}
    </ul>
  );
};
