import { useState, useCallback, useEffect } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityDeliverable } from 'shared/lib/types/ActivityDeliverable';
import { FillableActivityDeliverableFormFields } from 'types/FillableActivityDeliverableFormFields';

const defaultActivityDeliverableFields: FillableActivityDeliverableFormFields = {
  activityDeliverables: [],
  otherActivityDeliverable: null,
};

export interface ActivityDeliverableFormReturnValues {
  activityDeliverableFields: FillableActivityDeliverableFormFields;
  onActivityDeliverableChanged(
    activityDeliverables: ActivityDeliverable[],
  ): void;
  onOtherActivityDeliverableChanged(otherActivityDeliverable: string): void;
}

export function useActivityDeliverableForm(
  activity?: Activity,
): ActivityDeliverableFormReturnValues {
  const [activityDeliverableFields, setActivityDeliverableFields] = useState<
    FillableActivityDeliverableFormFields
  >(defaultActivityDeliverableFields);

  const onActivityDeliverableChanged = useCallback(
    (activityDeliverables: ActivityDeliverable[]) => {
      setActivityDeliverableFields((prevState) => ({
        ...prevState,
        activityDeliverables,
      }));
    },
    [],
  );

  const onOtherActivityDeliverableChanged = useCallback(
    (otherActivityDeliverable: string) => {
      setActivityDeliverableFields((prevState) => ({
        ...prevState,
        otherActivityDeliverable,
      }));
    },
    [],
  );

  useEffect(() => {
    if (activity) {
      setActivityDeliverableFields({
        activityDeliverables: activity.activityDeliverables,
        otherActivityDeliverable: activity.otherActivityDeliverable,
      });
    }
  }, [activity]);

  return {
    activityDeliverableFields,
    onActivityDeliverableChanged,
    onOtherActivityDeliverableChanged,
  };
}
