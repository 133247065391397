import React, { FC } from 'react';
import { AuthenticatedPageWrapper } from 'components/AuthenticatedPageWrapper/AuthenticatedPageWrapper';
import { BrowserRouter, Switch } from 'react-router-dom';
import { CommonRoutes } from 'routes/CommonRoutes';

export const UserApp: FC = () => (
  <BrowserRouter>
    <AuthenticatedPageWrapper>
      <Switch>
        <CommonRoutes />
      </Switch>
    </AuthenticatedPageWrapper>
  </BrowserRouter>
);
