import React, { ComponentPropsWithoutRef, FC } from 'react';
import './CloseableCard.css';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose?(): void;
}

export const CloseableCard: FC<Props> = ({
  onClose,
  children,
  className,
  ...props
}) => (
  <div className={`card bg-blue-400 py-6 ${className}`} {...props}>
    {typeof onClose === 'function' && (
      <div className="absolute right-6">
        <button className="close-button" onClick={onClose} />
      </div>
    )}
    <div className="card-body font-sf-pro-semibold text-white text-lg px-6">
      {children}
    </div>
  </div>
);
