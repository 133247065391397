import { Activity } from 'shared/lib/types/Activity';
import { apiAxios } from 'api/apiAxios';

export async function getUserActivities(): Promise<Activity[] | null> {
  const res = await apiAxios.get('/activity/userActivities').catch(() => {
    return null;
  });

  return res?.data ?? null;
}
