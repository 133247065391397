import React, { ComponentPropsWithoutRef, FC } from 'react';
import { TechnicalAssistanceModeDisplayString } from 'shared/lib/constants/activity/TechnicalAssistanceMode';
import { Region } from 'shared/lib/types/Region';
import { County } from 'shared/lib/types/County';
import { ActivityAudienceType } from 'shared/lib/types/ActivityAudienceType';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { User } from 'shared/lib/types/User';
import { formatDuration } from 'shared/lib/utils/formatDuration';
import formatName from 'shared/lib/utils/formatName';
import { ActivityFilterFormReturnValues } from 'hooks/useActivityFilterForm';
import { ActivityFilter } from 'types/ActivityFilter';
import { ActivityFilterOtherOption } from 'enums/ActivityFilterOtherOption';
import { Row } from 'components/Row/Row';
import { FilterChip } from 'components/FilterChip/FilterChip';
import './ActivityFilterChips.css';

interface Props
  extends ComponentPropsWithoutRef<'div'>,
    ActivityFilter,
    Omit<
      ActivityFilterFormReturnValues,
      'activityFilter' | 'filteredCounties' | 'filteredSchoolDistricts'
    > {
  regionList: Region[];
  countyList: County[];
  schoolDistrictList: SchoolDistrict[];
  activityAudienceList: ActivityAudienceType[];
  activityTypeKindList: ActivityTypeKind[];
  activityDeliverableKindList: ActivityDeliverableKind[];
  appUserList: User[];
}

function getMultiSelectLabel(
  typeLabel: string,
  selected: (number | ActivityFilterOtherOption.OTHER)[],
  options: { id: number; name: string }[],
) {
  if (selected.length === 1) {
    const [id] = selected;
    return id === ActivityFilterOtherOption.OTHER
      ? `Other ${typeLabel} Type`
      : options.find((option) => option.id === id)?.name;
  }
  return `${selected.length} ${typeLabel} Types`;
}

function formatUserById(userId: number, users: User[]) {
  const user = users.find(({ id }) => id === userId);
  // We should always find a user, but don't throw if we don't find one
  return user ? formatName(user) : 'Unknown user';
}

export const ActivityFilterChips: FC<Props> = ({
  statewide,
  regionId,
  countyId,
  schoolDistrictId,
  activityDurations,
  travelDurations,
  activityTypes,
  audienceTypes,
  deliverableTypes,
  technicalAssistanceMode,
  collaboratorIds,
  attendeeIds,
  activityInQuarterlyReport,
  commentsInQuarterlyReport,
  hasComments,
  hasFiles,
  followUp,
  onStatewideFilterChanged,
  onRegionFilterChanged,
  onCountyFilterChanged,
  onSchoolSchoolDistrictFilterChanged,
  onActivityDurationsChanged,
  onTravelDurationsChanged,
  onActivityTypeFilterChanged,
  onAudienceTypeFilterChanged,
  onDeliverableTypeFilterChanged,
  onTechnicalAssistanceModeChanged,
  onCollaboratorFilterChanged,
  onAttendeeFilterChanged,
  onActivityInQuarterlyReporFilterChangedt,
  onCommentsInQuarterlyReportFilterChanged,
  onHasCommentsFilterChanged,
  onHasFilesFilterChanged,
  onFollowUpFilterChanged,
  regionList,
  countyList,
  schoolDistrictList,
  activityAudienceList,
  activityTypeKindList,
  activityDeliverableKindList,
  appUserList,
  className = '',
  ...props
}) => {
  return (
    <Row
      className={`${className} flex-wrap activity-filter-chips w-full`}
      {...props}
    >
      {statewide && (
        <FilterChip label="Statewide" onClose={onStatewideFilterChanged} />
      )}
      {regionId && (
        <FilterChip
          label={regionList.find(({ id }) => id === regionId)?.name}
          onClose={() => onRegionFilterChanged(ActivityFilterOtherOption.ALL)}
        />
      )}
      {countyId && (
        <FilterChip
          label={countyList.find(({ id }) => id === countyId)?.name}
          onClose={() => onCountyFilterChanged(ActivityFilterOtherOption.ALL)}
        />
      )}
      {schoolDistrictId && (
        <FilterChip
          label={
            schoolDistrictList.find(({ id }) => id === schoolDistrictId)?.name
          }
          onClose={() =>
            onSchoolSchoolDistrictFilterChanged(ActivityFilterOtherOption.ALL)
          }
        />
      )}
      {activityDurations?.map((minutes) => (
        <FilterChip
          key={minutes}
          label={`Activity Duration: ${formatDuration(minutes)}`}
          onClose={() =>
            onActivityDurationsChanged(
              activityDurations.filter((other) => other !== minutes),
            )
          }
        />
      ))}
      {travelDurations?.map((minutes) => (
        <FilterChip
          key={minutes}
          label={`Travel Involved: ${formatDuration(minutes)}`}
          onClose={() =>
            onTravelDurationsChanged(
              travelDurations.filter((other) => other !== minutes),
            )
          }
        />
      ))}
      {audienceTypes && audienceTypes.length > 0 && (
        <FilterChip
          label={getMultiSelectLabel(
            'Audience',
            audienceTypes,
            activityAudienceList,
          )}
          onClose={() => onAudienceTypeFilterChanged([])}
        />
      )}
      {activityTypes && activityTypes.length > 0 && (
        <FilterChip
          label={getMultiSelectLabel(
            'Activity',
            activityTypes,
            activityTypeKindList,
          )}
          onClose={() => onActivityTypeFilterChanged([])}
        />
      )}
      {deliverableTypes && deliverableTypes.length > 0 && (
        <FilterChip
          label={getMultiSelectLabel(
            'Deliverable',
            deliverableTypes,
            activityDeliverableKindList,
          )}
          onClose={() => onDeliverableTypeFilterChanged([])}
        />
      )}
      {technicalAssistanceMode && (
        <FilterChip
          label={`TA Mode: ${TechnicalAssistanceModeDisplayString[technicalAssistanceMode]}`}
          onClose={() =>
            onTechnicalAssistanceModeChanged(ActivityFilterOtherOption.ALL)
          }
        />
      )}
      {collaboratorIds && collaboratorIds?.length && (
        <FilterChip
          label={
            collaboratorIds.length === 1
              ? `Collaborator: ${formatUserById(
                  collaboratorIds[0],
                  appUserList,
                )}`
              : `${collaboratorIds.length} Collaborators`
          }
          onClose={() =>
            onTechnicalAssistanceModeChanged(ActivityFilterOtherOption.ALL)
          }
        />
      )}
      {attendeeIds && attendeeIds?.length && (
        <FilterChip
          label={
            attendeeIds.length === 1
              ? `Attendee: ${formatUserById(attendeeIds[0], appUserList)}`
              : `${attendeeIds.length} Attendees`
          }
          onClose={() =>
            onTechnicalAssistanceModeChanged(ActivityFilterOtherOption.ALL)
          }
        />
      )}
      {typeof activityInQuarterlyReport === 'boolean' && (
        <FilterChip
          label={
            activityInQuarterlyReport
              ? 'Marked for Quarterly Report'
              : 'Not Marked for Quarterly Reqport'
          }
          onClose={() =>
            onActivityInQuarterlyReporFilterChangedt(
              ActivityFilterOtherOption.ALL,
            )
          }
        />
      )}
      {typeof commentsInQuarterlyReport === 'boolean' && (
        <FilterChip
          label={
            commentsInQuarterlyReport
              ? 'Comment in Quarterly Report'
              : 'Comment Not in Quarterly Reqport'
          }
          onClose={() =>
            onCommentsInQuarterlyReportFilterChanged(
              ActivityFilterOtherOption.ALL,
            )
          }
        />
      )}
      {typeof hasComments === 'boolean' && (
        <FilterChip
          label={`Has Comments: ${hasComments ? 'Yes' : 'No'}`}
          onClose={() =>
            onHasCommentsFilterChanged(ActivityFilterOtherOption.ALL)
          }
        />
      )}
      {typeof hasFiles === 'boolean' && (
        <FilterChip
          label={`Has Files: ${hasFiles ? 'Yes' : 'No'}`}
          onClose={() => onHasFilesFilterChanged(ActivityFilterOtherOption.ALL)}
        />
      )}
      {typeof followUp === 'boolean' && (
        <FilterChip
          label={followUp ? 'Follow-up' : 'No Follow-up'}
          onClose={() => onFollowUpFilterChanged(ActivityFilterOtherOption.ALL)}
        />
      )}
    </Row>
  );
};
