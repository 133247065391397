import React, { FC, useCallback } from 'react';
import { ActivityAttendee } from 'shared/lib/types/ActivityAttendee';
import {
  DropdownCheckboxInput,
  DropdownCheckboxInputProps,
} from 'components/DropdownCheckboxInput/DropdownCheckboxInput';
import { Column } from 'components/Column/Column';
import { FormFieldTitle } from 'components/FormFieldTitle/FormFieldTitle';

interface Props
  extends Pick<DropdownCheckboxInputProps, 'options' | 'selected'> {
  className?: string;
  onChange(attendees: Pick<ActivityAttendee, 'userId'>[]): void;
}

export const ActivityAttendeeInput: FC<Props> = ({
  className = '',
  onChange,
  ...props
}) => {
  const handleAttendeeDropdownChange = useCallback(
    (userIds: number[]) => {
      onChange(userIds.map((userId) => ({ userId })));
    },
    [onChange],
  );
  return (
    <Column className={className}>
      <FormFieldTitle
        className="mb-2"
        label="Inter-regional Attendees"
        infoText={
          <div>
            An inter-regional attendee is someone who participated in your
            event, but was not a Collaborator. Adding an inter-regional attendee
            will <i>not</i> make this activity appear in attendee’s activity
            list.
          </div>
        }
      />
      <DropdownCheckboxInput
        placeholder="Select attendees"
        onChange={handleAttendeeDropdownChange}
        {...props}
      />
    </Column>
  );
};
