import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityFormHeading } from 'components/ActivityFormHeading/ActivityFormHeading';

interface Props extends ComponentPropsWithoutRef<'div'> {
  heading: string;
}
export const ActivityFormStepWrapper: FC<Props> = ({
  heading,
  children,
  ...props
}) => (
  <div {...props}>
    <ActivityFormHeading className="mb-6">{heading}</ActivityFormHeading>
    {children}
  </div>
);
