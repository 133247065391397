import { Column } from 'components/Column/Column';
import { NumberCircle } from 'components/NumberCircle/NumberCircle';
import { Row } from 'components/Row/Row';
import React, { ComponentPropsWithoutRef, FC } from 'react';

interface ListItem {
  id: number | string;
  value: string;
}
interface Props extends ComponentPropsWithoutRef<'div'> {
  label: string;
  list: ListItem[];
}
export const ListWithCounter: FC<Props> = ({ label, list, ...props }) => (
  <Column {...props}>
    <Row className="items-center">
      <NumberCircle numberValue={list.length} className="bg-blue-400" />
      <div className="ml-2.5 font-sf-pro-semibold text-gray-500">{label}</div>
    </Row>
    {list.map((listItem) => {
      return (
        <div
          key={listItem.id}
          className="ml-9 font-sf-pro-medium text-gray-700 leading-5"
        >
          {listItem.value}
        </div>
      );
    })}
  </Column>
);
