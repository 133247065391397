import { Button } from 'components/Button/Button';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';
import { TextInput } from 'components/TextInput/TextInput';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
interface Props {
  email: string;
  onEmailChange: (value: string) => void;
  onSubmit: () => void;
}
export const ForgotPasswordForm: FC<Props> = ({
  email,
  onEmailChange,
  onSubmit,
}) => (
  <Column className="mt-11">
    <TextInput
      value={email}
      label="Email"
      onChange={(e) => onEmailChange(e.target.value)}
    />
    <Row className="justify-between mt-14">
      <Link
        className="btn-green-outline px-2.5 rounded-r-none text-sm font-sf-pro-medium"
        to="/login"
      >
        Back to Login
      </Link>
      <Button onClick={onSubmit}>Submit</Button>
    </Row>
  </Column>
);
