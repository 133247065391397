import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useState,
} from 'react';
import { ListGroup } from 'components/ListGroup/ListGroup';
import { RoundShareButton } from 'components/RoundShareButton/RoundShareButton';
import './ShareActivityMenu.css';

interface Props extends ComponentPropsWithoutRef<'div'> {
  active?: boolean;
  onPrintClick?(): void;
  onEmailClick?(): void;
}

export const ShareActivityMenu: FC<Props> = ({
  onPrintClick,
  onEmailClick,
  className = '',
  active,
  ...rest
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClickOutside = useCallback(() => setMenuOpen(false), []);
  return (
    <div className={`${className} relative`} {...rest}>
      <RoundShareButton
        active={menuOpen || active}
        onClick={() => setMenuOpen(true)}
      />
      {menuOpen && (
        <ListGroup
          onClickOutside={handleClickOutside}
          className="absolute top-0 left-18 z-50 activity-share-list-group"
        >
          {onEmailClick && (
            <li
              onClick={() => {
                setMenuOpen(false);
                onEmailClick();
              }}
            >
              Email
            </li>
          )}
          {onPrintClick && (
            <li
              onClick={() => {
                setMenuOpen(false);
                onPrintClick();
              }}
            >
              Print
            </li>
          )}
        </ListGroup>
      )}
    </div>
  );
};
