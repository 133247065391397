import React, { ComponentPropsWithoutRef, FC, FormEvent } from 'react';
import { CloseableCard } from 'components/CloseableCard/CloseableCard';
import { TextInput } from 'components/TextInput/TextInput';
import { Button } from 'components/Button/Button';
import { Row } from 'components/Row/Row';
import './EmailActivityFormModal.css';

interface Props extends ComponentPropsWithoutRef<'div'> {
  onClose(): void;
  onSubmit(e: FormEvent): void;
  email: string;
  onEmailChange(email: string): void;
}
export const EmailActivityFormModal: FC<Props> = ({
  email,
  onEmailChange,
  onClose,
  onSubmit,
  ...props
}) => (
  <CloseableCard onClose={onClose} {...props}>
    <div className="card-header">Email Activity</div>
    <div>Enter Recipient Info</div>
    <form onSubmit={onSubmit}>
      <Row className="mb-2">
        <TextInput
          label="Email Address"
          containerClassName="input-width modal-email-input mt-9"
          className="bg-blue-400 text-white"
          value={email}
          onChange={(e) => onEmailChange(e.target.value)}
        />
        <Button
          className="bg-white text-blue-400 h-12 self-end ml-6"
          type="submit"
        >
          Send Activity
        </Button>
      </Row>
    </form>
  </CloseableCard>
);
