import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';
import { xIconDark } from 'assets/images';
import './FilterChip.css';

interface Props extends ComponentPropsWithoutRef<'div'> {
  label?: string;
  onClose(): void;
}

export const FilterChip: FC<Props> = ({ label, onClose, ...props }) => (
  <Row {...props} className="filter-chip items-center justify-between">
    <div className="font-sf-pro-medium text-xs">{label}</div>
    <button className="h-7 pl-3 pr-2" onClick={onClose}>
      <img src={xIconDark} alt="Close Icon" className="w-2.5 h-2.5" />
    </button>
  </Row>
);
