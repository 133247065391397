import React, { FC, useCallback, useState } from 'react';
import { PageSegmentHeadingIcons } from 'enums/PageSegmentHeadingIcons';
import { formatUserEditRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { User } from 'shared/lib/types/User';
import formatName from 'shared/lib/utils/formatName';
import { UserManagementForm } from 'components/UserManagementForm/UserManagementForm';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { useHistory } from 'react-router';
import { UsersManagementPage } from 'pages/UsersManagementPage/UsersManagementPage';
import { noop } from 'shared/lib/utils/noop';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getUserById } from 'api/user/getUserById';
import { Button } from 'components/Button/Button';
import { resendInviteToken } from 'api/auth/resendInviteToken';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';

interface Props {
  userId: number;
}

export const UserDetail: FC<Props> = ({ userId }) => {
  const [user, setUser] = useState<User>();
  const { snackbar } = useSnackbarContext();
  const history = useHistory();

  const handleResendInvitationClick = useCallback(async () => {
    if (user) {
      try {
        snackbar.show({
          severity: SnackbarSeverityKind.SPINNER,
          text: 'Sending new invitation. Please wait...',
        });
        await resendInviteToken(user?.email);
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'Invitation sent.',
        });
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: 'Unable to resend invitation. Please try again.',
        });
      }
    }
  }, [snackbar, user]);

  const handleIconClick = useCallback(() => {
    if (user) {
      history.push(formatUserEditRoute(user.id));
    }
  }, [history, user]);

  useAsyncEffect(
    async (isCancelled) => {
      const user = await getUserById(userId);
      if (!isCancelled() && user) {
        setUser(user);
      }
    },
    [userId],
  );

  return (
    <UsersManagementPage userId={userId}>
      {user && (
        <>
          <PageSegmentHeading
            icon={PageSegmentHeadingIcons.PENCIL}
            onIconClick={handleIconClick}
          >
            {formatName(user)}
          </PageSegmentHeading>
          <UserManagementForm user={user} onSubmit={noop} readOnly />
          {user.pending && (
            <div className="mt-8 w-full flex justify-end">
              <Button onClick={handleResendInvitationClick}>
                Resend Invitation
              </Button>
            </div>
          )}
        </>
      )}
    </UsersManagementPage>
  );
};
