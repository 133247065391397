import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityDuration } from 'shared/lib/constants/activity/ActivityDuration';
import { ActivityTravelDuration } from 'shared/lib/constants/activity/ActivityTravelDuration';
import {
  TechnicalAssistanceMode,
  TechnicalAssistanceModeDisplayString,
} from 'shared/lib/constants/activity/TechnicalAssistanceMode';
import { ActivityAudienceType } from 'shared/lib/types/ActivityAudienceType';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { Region } from 'shared/lib/types/Region';
import { formatDuration } from 'shared/lib/utils/formatDuration';
import { ActivityFilter } from 'types/ActivityFilter';
import { User } from 'shared/lib/types/User';
import { ActivityFilterFormReturnValues } from 'hooks/useActivityFilterForm';
import { ToggleInput } from 'components/ToggleInput/ToggleInput';
import { Button } from 'components/Button/Button';
import { Row } from 'components/Row/Row';
import { DropdownInput } from 'components/DropdownInput/DropdownInput';
import { DropdownCheckboxInput } from 'components/DropdownCheckboxInput/DropdownCheckboxInput';
import { ActivityFilterOtherOption } from 'enums/ActivityFilterOtherOption';
import { DropdownOption } from 'types/Dropdown';
import { formatUserNameAndRegion } from 'utils/formatUserNameAndRegion';

interface Props
  extends ComponentPropsWithoutRef<'div'>,
    ActivityFilter,
    Omit<ActivityFilterFormReturnValues, 'activityFilter'> {
  regionList: Region[];
  activityAudienceList: ActivityAudienceType[];
  activityTypeKindList: ActivityTypeKind[];
  activityDeliverableKindList: ActivityDeliverableKind[];
  appUserList: User[];
  canViewEverything?: boolean;
  onSave(): void;
}

function getBooleanOptions(
  trueLabel: string,
  falseLabel: string,
): DropdownOption<boolean | ActivityFilterOtherOption.ALL>[] {
  return [
    { id: ActivityFilterOtherOption.ALL, value: "Don't filter" },
    { id: true, value: trueLabel },
    { id: false, value: falseLabel },
  ];
}

export const ActivityListFilterForm: FC<Props> = ({
  statewide,
  regionId,
  countyId,
  schoolDistrictId,
  activityDurations,
  travelDurations,
  activityTypes,
  audienceTypes,
  deliverableTypes,
  collaboratorIds,
  attendeeIds,
  technicalAssistanceMode,
  activityInQuarterlyReport,
  commentsInQuarterlyReport,
  hasComments,
  hasFiles,
  followUp,
  onStatewideFilterChanged,
  onRegionFilterChanged,
  onCountyFilterChanged,
  onSchoolSchoolDistrictFilterChanged,
  onActivityDurationsChanged,
  onTravelDurationsChanged,
  onActivityTypeFilterChanged,
  onAudienceTypeFilterChanged,
  onDeliverableTypeFilterChanged,
  onTechnicalAssistanceModeChanged,
  onCollaboratorFilterChanged,
  onAttendeeFilterChanged,
  onActivityInQuarterlyReporFilterChangedt,
  onCommentsInQuarterlyReportFilterChanged,
  onHasCommentsFilterChanged,
  onHasFilesFilterChanged,
  onFollowUpFilterChanged,
  regionList,
  filteredCounties,
  filteredSchoolDistricts,
  activityAudienceList,
  activityTypeKindList,
  activityDeliverableKindList,
  appUserList,
  onSave,
  canViewEverything = false,
  ...props
}) => {
  const appUserOptions: DropdownOption<number>[] = appUserList.map((user) => ({
    id: user.id,
    value: formatUserNameAndRegion(user),
  }));

  return (
    <div {...props}>
      <ToggleInput
        labelClassName="ml-2.5"
        containerClassName="mt-6 mb-9 w-32 flex-row-reverse"
        label="Statewide"
        checked={statewide}
        onChange={onStatewideFilterChanged}
      />
      <Row className="w-full flex-wrap justify-between">
        {canViewEverything && (
          <DropdownInput
            className="w-92/2 mb-6"
            label="Region"
            placeholder="Choose Region"
            disabled={statewide}
            // `as const` is required so the type doesn't include the whole enum
            selected={regionId ?? (ActivityFilterOtherOption.ALL as const)}
            options={[
              { id: ActivityFilterOtherOption.ALL, value: 'All Regions' },
              ...regionList.map(({ id, name }) => ({ id, value: name })),
            ]}
            onChange={({ id }) => onRegionFilterChanged(id)}
          />
        )}
        <DropdownInput
          className="w-92/2 mb-6"
          label="County"
          placeholder="Choose County"
          disabled={statewide}
          selected={countyId ?? (ActivityFilterOtherOption.ALL as const)}
          options={[
            {
              id: ActivityFilterOtherOption.ALL,
              value: 'All Counties',
            },
            ...filteredCounties.map(({ id, name }) => ({
              id,
              value: name,
            })),
          ]}
          onChange={({ id }) => onCountyFilterChanged(id)}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="School District"
          placeholder="Choose School District"
          disabled={statewide}
          selected={
            schoolDistrictId ?? (ActivityFilterOtherOption.ALL as const)
          }
          options={[
            {
              id: ActivityFilterOtherOption.ALL,
              value: 'All School Districts',
            },
            ...filteredSchoolDistricts.map(({ id, name }) => ({
              id,
              value: name,
            })),
          ]}
          onChange={({ id }) => onSchoolSchoolDistrictFilterChanged(id)}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Activity Duration"
          placeholder="All Activity Durations"
          selected={activityDurations ?? undefined}
          options={ActivityDuration.map((minutes) => ({
            id: minutes,
            value: formatDuration(minutes),
          }))}
          onChange={onActivityDurationsChanged}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Travel Involved"
          placeholder="All Travel Durations"
          selected={travelDurations ?? undefined}
          options={ActivityTravelDuration.map((minutes) => ({
            id: minutes,
            value: formatDuration(minutes),
          }))}
          onChange={onTravelDurationsChanged}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Activity Type"
          placeholder="All Activity Types"
          selected={activityTypes ?? undefined}
          options={[
            ...activityTypeKindList.map(({ id, name }) => ({
              id,
              value: name,
            })),
            {
              id: ActivityFilterOtherOption.OTHER,
              value: 'Other Activity Type',
            },
          ]}
          onChange={onActivityTypeFilterChanged}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Audience"
          placeholder="All Audiences"
          selected={audienceTypes ?? undefined}
          options={[
            ...activityAudienceList.map(({ id, name }) => ({
              id,
              value: name,
            })),
            { id: ActivityFilterOtherOption.OTHER, value: 'Other Audience' },
          ]}
          onChange={onAudienceTypeFilterChanged}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Deliverables"
          placeholder="All Deliverables"
          selected={deliverableTypes ?? undefined}
          options={[
            ...activityDeliverableKindList.map(({ id, name }) => ({
              id,
              value: name,
            })),
            { id: ActivityFilterOtherOption.OTHER, value: 'Other Deliverable' },
          ]}
          onChange={onDeliverableTypeFilterChanged}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Technical Assistance Mode"
          placeholder="Choose Technical Assistance Mode"
          selected={
            technicalAssistanceMode ?? (ActivityFilterOtherOption.ALL as const)
          }
          options={[
            { id: ActivityFilterOtherOption.ALL, value: 'All TA Modes' },
            ...Object.values(TechnicalAssistanceMode).map((taMode) => ({
              id: taMode,
              value: TechnicalAssistanceModeDisplayString[taMode],
            })),
          ]}
          onChange={({ id }) => onTechnicalAssistanceModeChanged(id)}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Collaborators"
          placeholder="All Collaborators"
          selected={collaboratorIds ?? undefined}
          options={appUserOptions}
          onChange={onCollaboratorFilterChanged}
        />
        <DropdownCheckboxInput
          className="w-92/2 mb-6"
          label="Inter-Regional Attendees"
          placeholder="All Attendees"
          selected={attendeeIds ?? undefined}
          options={appUserOptions}
          onChange={onAttendeeFilterChanged}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Activity Marked for Quarterly Report"
          placeholder="Choose Filter Option"
          selected={
            activityInQuarterlyReport ??
            (ActivityFilterOtherOption.ALL as const)
          }
          options={getBooleanOptions(
            'Marked for Quarterly Report',
            'Not Marked for Quarterly Report',
          )}
          onChange={({ id }) => onActivityInQuarterlyReporFilterChangedt(id)}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Activity Comment Included in Quarterly Report"
          placeholder="Choose Filter Option"
          selected={
            commentsInQuarterlyReport ??
            (ActivityFilterOtherOption.ALL as const)
          }
          options={getBooleanOptions(
            'Comment Included in Quarterly Report',
            'Comment Not Included in Quarterly Report',
          )}
          onChange={({ id }) => onCommentsInQuarterlyReportFilterChanged(id)}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Has Activity Comments"
          placeholder="Choose Filter Option"
          selected={hasComments ?? (ActivityFilterOtherOption.ALL as const)}
          options={getBooleanOptions('Has Comments', 'Does Not Have Comments')}
          onChange={({ id }) => onHasCommentsFilterChanged(id)}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Has Files"
          placeholder="Choose Filter Option"
          selected={hasFiles ?? (ActivityFilterOtherOption.ALL as const)}
          options={getBooleanOptions('Has One Or More Files', 'Has No Files')}
          onChange={({ id }) => onHasFilesFilterChanged(id)}
        />
        <DropdownInput
          className="w-92/2 mb-6"
          label="Follow-up to Activity"
          placeholder="Choose Filter Option"
          selected={followUp ?? (ActivityFilterOtherOption.ALL as const)}
          options={getBooleanOptions('Follow-up', 'No Follow-up')}
          onChange={({ id }) => onFollowUpFilterChanged(id)}
        />
      </Row>
      <Button className="mt-8" onClick={onSave}>
        Save
      </Button>
    </div>
  );
};
