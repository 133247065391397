import React, { ComponentPropsWithoutRef, FC, Fragment, useMemo } from 'react';
import range from 'lodash/range';
import { Quarter } from 'shared/lib/constants/quarter/Quarter';
import { YearQuarter } from 'shared/lib/types/YearQuarter';
import { formatYearQuarterDateRange } from 'shared/lib/utils/formatYearQuarterDateRange';
import { SelectInput } from 'components/SelectInput/SelectInput';

interface Props extends Omit<ComponentPropsWithoutRef<'select'>, 'value'> {
  value: YearQuarter;
  earliestQuarter1Year: number;
  latestQuarter1Year: number;
  onYearQuarterChange(value: YearQuarter): void;
  isDateRangeAlignedWithQuarter?: boolean;
}

const quarters = Object.values(Quarter);

function yearQuarterToValue({ quarter1Year, quarter }: YearQuarter) {
  return JSON.stringify([quarter1Year, quarter]);
}

function valueToYearQuarter(value: string): YearQuarter {
  const [quarter1Year, quarter] = JSON.parse(value) as [number, Quarter];
  return { quarter1Year, quarter };
}

export const QuarterSelectInput: FC<Props> = ({
  earliestQuarter1Year,
  latestQuarter1Year,
  value,
  onYearQuarterChange,
  isDateRangeAlignedWithQuarter = true,
  ...rest
}) => {
  const quarter1Years = useMemo(() => {
    return earliestQuarter1Year === latestQuarter1Year
      ? [latestQuarter1Year]
      : range(earliestQuarter1Year, latestQuarter1Year + 1);
  }, [earliestQuarter1Year, latestQuarter1Year]);

  return (
    <SelectInput
      name="quarter"
      value={yearQuarterToValue(value)}
      onChange={(event) =>
        onYearQuarterChange(valueToYearQuarter(event.target.value))
      }
      grayOut={!isDateRangeAlignedWithQuarter}
      {...rest}
    >
      {quarter1Years.map((quarter1Year) =>
        quarters.map((quarter) => {
          const yearQuarter: YearQuarter = { quarter1Year, quarter };
          const optionValue = yearQuarterToValue(yearQuarter);
          const dateRange = formatYearQuarterDateRange(yearQuarter);
          const label = `${quarter}: ${dateRange}`;
          // Chrome won't fire onChange when selecting the already-selected
          // option, but we need the event to fire if the selected quarter isn't
          // aligned with the start/end filter dates so we can reset the dates.
          // To enable this, we'll hide the selected/unaligned option and add a
          // new option with the same value.
          return !isDateRangeAlignedWithQuarter &&
            quarter1Year === value.quarter1Year &&
            quarter === value.quarter ? (
            <Fragment key={optionValue}>
              <option value={optionValue} hidden>
                {label}
              </option>
              <option value={optionValue}>{label}</option>
            </Fragment>
          ) : (
            <option key={optionValue} value={optionValue}>
              {label}
            </option>
          );
        }),
      )}
    </SelectInput>
  );
};
