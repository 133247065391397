import React, {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { usePopper } from 'react-popper';
import { infoIcon } from 'assets/images';
import './InfoTooltip.css';

export interface InfoTooltipProps extends ComponentPropsWithoutRef<'div'> {
  position?: 'top' | 'bottom';
  text: ReactNode;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  position = 'bottom',
  text,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 22] } },
    ],
  });

  const handleShowTooltipChange = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const handleHideTooltipChange = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div {...props}>
      <div
        ref={setReferenceElement}
        className="cursor-pointer"
        onMouseOver={handleShowTooltipChange}
        onMouseOut={handleHideTooltipChange}
      >
        <img src={infoIcon} alt="Info Icon" className="w-full h-full" />
      </div>
      {showTooltip && (
        <div
          className="info-tooltip inline-block font-sf-pro-medium"
          role="tooltip"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {text}
          <div id="arrow" ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </div>
  );
};
