import {
  ComponentPropsWithoutRef,
  FC,
  Fragment,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Activity } from 'shared/lib/types/Activity';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Row } from 'components/Row/Row';
import { County } from 'shared/lib/types/County';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { getObjectPropertyValueFrequency } from 'shared/lib/utils/getObjectPropertyValueFrequency';
import { removeDuplicateRecordsFromArray } from 'shared/lib/utils/removeDuplicateRecordsFromArray';
import { ActivitySiteVisitChartData } from 'types/report/ActivityReport';
import { CircularActionButton } from 'components/CircularActionButton/CircularActionButton';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import { calculatePercentage } from 'shared/lib/utils/calculatePercentage';
import { formatPercentValue } from 'shared/lib/utils/formatPercentValue';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activityList: Activity[];
}

export const ActivitySiteVisitsChart: FC<Props> = ({
  activityList,
  ...rest
}) => {
  const [showAll, setShowAll] = useState(false);
  const [chartData, totalSiteVisits] = useMemo<
    [ActivitySiteVisitChartData, number]
  >(() => {
    const counties = removeDuplicateRecordsFromArray({
      data: activityList
        .flatMap((activity) => activity.counties)
        .map((activityCounty) => activityCounty?.county as County),
      key: 'name',
    });
    const allSchoolDistricts = activityList
      .flatMap((activity) => activity.schoolDistricts ?? [])
      .map(({ schoolDistrict }) => schoolDistrict as SchoolDistrict);

    // Map countyId to count of entire-county activities
    const entireCountyCounts = activityList.reduce<Map<number, number>>(
      (map, activity) => {
        activity.counties?.forEach(({ county }) => {
          if (
            county &&
            activity.schoolDistricts &&
            activity.schoolDistricts.every(
              ({ schoolDistrict }) => schoolDistrict?.countyId !== county.id,
            )
          ) {
            map.set(county.id, (map.get(county.id) ?? 0) + 1);
          }
        });
        return map;
      },
      new Map(),
    );

    const countySchoolDistricts = counties.map((county) => ({
      county,
      schoolDistricts: allSchoolDistricts.filter(
        ({ countyId }) => countyId === county.id,
      ),
    }));

    const countyStats = countySchoolDistricts.map(
      ({ county, schoolDistricts }) => {
        const entireCountyActivities = entireCountyCounts.get(county.id) ?? 0;
        return {
          countyName: county.name,
          visitTotal: schoolDistricts.length + entireCountyActivities,
          schoolDistricts: [
            ...getObjectPropertyValueFrequency({
              data: schoolDistricts,
              key: 'name',
            }),
            ...(entireCountyActivities > 0
              ? [{ value: 'Entire County', frequency: entireCountyActivities }]
              : []),
          ],
        };
      },
    );
    const totalVisits = countyStats.reduce(
      (sum, { visitTotal }) => sum + visitTotal,
      0,
    );
    return [countyStats, totalVisits];
  }, [activityList]);

  const visibleChartData = useMemo(() => {
    return showAll ? chartData : chartData.slice(0, 3);
  }, [chartData, showAll]);

  return (
    <div {...rest}>
      <div className="w-full">
        <div className="text-center mb-3">
          <label className="font-semibold">
            Total Site Visits: {totalSiteVisits}
          </label>
        </div>
        {visibleChartData.length ? (
          <div className="grid grid-cols-2-label-max-content gap-x-6 gap-y-0.5 mr-20">
            {visibleChartData.map(
              ({ countyName, visitTotal, schoolDistricts }, i) => (
                <Fragment key={countyName}>
                  <div
                    className={twMerge(
                      'break-words mb-1.5 text-sm',
                      i > 0 && 'mt-8',
                    )}
                  >
                    {countyName}
                  </div>
                  <ProgressBar
                    percent={100}
                    label={visitTotal}
                    className={twMerge('bg-tnf-green mb-1.5', i > 0 && 'mt-8')}
                  />
                  {schoolDistricts.map(({ value, frequency }) => (
                    <>
                      <div key={value} className="text-sm">
                        {value}
                      </div>
                      <ProgressBar
                        key={value}
                        percent={Math.ceil((frequency / visitTotal) * 100)}
                        label={`${frequency} (${formatPercentValue(
                          calculatePercentage(frequency, visitTotal),
                        )})`}
                        className="bg-tnf-green-200"
                      />
                    </>
                  ))}
                </Fragment>
              ),
            )}
          </div>
        ) : (
          <div>No data available</div>
        )}
        {!showAll && chartData.length > 3 && (
          <Row className="items-center mt-8">
            <CircularActionButton
              className="w-3.5 h-3.5"
              onClick={() => setShowAll(true)}
              icon={CircularActionIconKind.PLUS_DARK}
            />
            <div className="font-sf-pro-semibold text-xs ml-2.5">show all</div>
          </Row>
        )}
      </div>
    </div>
  );
};
