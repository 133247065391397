import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityCardBadge } from 'components/ActivityCardBadge/ActivityCardBadge';

export const ActivityUnfinishedBadge: FC<ComponentPropsWithoutRef<'div'>> = (
  props,
) => (
  <ActivityCardBadge
    label="Unfinished"
    badgeLabel="U"
    badgeClassName="bg-black"
    className="text-black"
    {...props}
  />
);
