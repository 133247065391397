import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';
import { Column } from 'components/Column/Column';
import { RadioInput } from 'components/RadioInput/RadioInput';
import { Row } from 'components/Row/Row';
import { TechnicalAssistanceMode } from 'shared/lib/constants/activity/TechnicalAssistanceMode';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  selected: TechnicalAssistanceMode | null;
  onChange(value: TechnicalAssistanceMode): void;
}

export const TechnicalAssistanceRadioInput: FC<Props> = ({
  selected,
  onChange,
  ...props
}) => {
  const handleRadioInputChange = useCallback(
    (e) => {
      const value = e.target.value;

      onChange(value);
    },
    [onChange],
  );

  return (
    <Column {...props}>
      <ActivityFormInputLabel className="mb-2">
        Technical Assitance Mode
      </ActivityFormInputLabel>
      <Row>
        <RadioInput
          label="On-Site"
          value={TechnicalAssistanceMode.ON_SITE}
          checked={selected === TechnicalAssistanceMode.ON_SITE}
          onChange={handleRadioInputChange}
        />
        <RadioInput
          label="Electronic (email, phone, video call)"
          className="ml-6"
          value={TechnicalAssistanceMode.ELECTRONIC}
          checked={selected === TechnicalAssistanceMode.ELECTRONIC}
          onChange={handleRadioInputChange}
        />
      </Row>
    </Column>
  );
};
