import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Column } from 'components/Column/Column';
import { personFigureIcon } from 'assets/images';
import { formatPercentValue } from 'shared/lib/utils/formatPercentValue';

interface Props extends ComponentPropsWithoutRef<'div'> {
  audienceTypeName: string;
  quantity: number;
  opacity?: number;
  percentage?: number;
}

export const AudiencePerson: FC<Props> = ({
  audienceTypeName,
  quantity,
  opacity = 1,
  percentage,
  className = '',
  ...rest
}) => (
  <Column
    className={`text-center text-gray-500 items-center ${className}`}
    {...rest}
  >
    <div className="text-sm text-gray-500">
      {quantity} {percentage && `(${formatPercentValue(percentage)})`}
    </div>
    <img src={personFigureIcon} alt="Person Icon" style={{ opacity }} />
    <div className="text-sm break-words leading-tight">{audienceTypeName}</div>
  </Column>
);
