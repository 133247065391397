import React, { FC } from 'react';
import { activeReportIcon } from 'assets/images';
import { inactiveReportIcon } from 'assets/images';
import { NavigationItem } from 'components/NavigationItem/NavigationItem';
import { useHistory, useLocation } from 'react-router-dom';
import { reportRoute } from 'shared/lib/constants/routes/commonRoutes';

export const ReportNavigationItem: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const active = location.pathname === reportRoute;
  return (
    <NavigationItem
      imageSrc={active ? activeReportIcon : inactiveReportIcon}
      imageAlt="Report Icon"
      label="Report"
      onClick={() => {
        history.push(reportRoute);
      }}
    />
  );
};
