import React, { ComponentPropsWithoutRef, FC } from 'react';
import { userRoleDisplayNameMappings } from 'constants/userRoleDisplayNameMappings';
import { UserRole } from 'shared/lib/constants/user/UserRole';
import { PendingInvitationLabel } from 'components/PendingInvitationLabel/PendingInvitationLabel';

interface Props extends ComponentPropsWithoutRef<'div'> {
  name: string;
  role: UserRole;
  onClick?: () => void;
  active?: boolean;
  pending?: boolean;
}

export const UserCard: FC<Props> = ({
  name,
  role,
  onClick,
  className = '',
  style,
  active,
  pending,
}) => (
  <button
    className={`card ${className} ${active ? 'border border-gray-600' : ''}`}
    onClick={onClick}
    style={style}
  >
    <div className="card-body pb-8">
      {name}
      {pending && (
        <div className="items-center absolute top-1 right-3">
          <PendingInvitationLabel />
        </div>
      )}
    </div>
    <div className="card-footer">{userRoleDisplayNameMappings.get(role)}</div>
  </button>
);
