import React, { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { DonutChartData } from 'types/report/DonutChart';

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: DonutChartData;
}

export const DonutChart: FC<Props> = ({ data, className = '', ...rest }) => {
  useEffect(() => {
    // Create chart instance
    am4core.useTheme(am4themes_animated);
    const chart = am4core.create('donut-chart', am4charts.PieChart);
    chart.data = data;

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'name';
    pieSeries.alignLabels = false;
    pieSeries.slices.template.propertyFields.fill = 'color';
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.interactionsEnabled = false;

    chart.radius = am4core.percent(100);

    // Hide donut labels
    function hideSmall(ev: any) {
      ev.target.hide();
    }
    pieSeries.ticks.template.events.on('ready', hideSmall);
    pieSeries.ticks.template.events.on('visibilitychanged', hideSmall);
    pieSeries.labels.template.events.on('ready', hideSmall);
    pieSeries.labels.template.events.on('visibilitychanged', hideSmall);

    // Set inner radius
    chart.innerRadius = am4core.percent(65);

    // Initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div className={`donut-chart ${className}`} {...rest} />;
};
