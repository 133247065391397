import { apiAxios } from 'api/apiAxios';

interface Request {
  email: string;
  token: string;
  password: string;
}
export function resetPassword(request: Request) {
  return apiAxios.post('/auth/reset-password', request);
}
