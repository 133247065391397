import React, { FC } from 'react';
import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { formatDateShort } from 'shared/lib/utils/formatDateShort';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';
import { ActivityCollaboratorBadge } from 'components/ActivityCollaboratorBadge/ActivityCollaboratorBadge';
import { ActivityQuarterlyReportBadge } from 'components/ActivityQuarterlyReportBadge/ActivityQuarterlyReportBadge';
import { ActivityUnfinishedBadge } from 'components/ActivityUnfinishedBadge/ActivityUnfinishedBadge';
import './ActivityCard.css';

interface Props {
  active?: boolean;
  title: string;
  activityType?: string[];
  date: string;
  areaInvolved: AreaInvolved | null;
  region?: string;
  counties?: string[];
  schoolDistricts?: string[];
  markedForQuarterlyReport?: boolean;
  collaborator?: boolean;
  unfinished?: boolean;
  className?: string;
  onClick(): void;
}

export const ActivityCard: FC<Props> = ({
  active,
  title,
  activityType = [],
  date,
  areaInvolved,
  region,
  counties = [],
  schoolDistricts = [],
  markedForQuarterlyReport,
  collaborator,
  unfinished,
  className,
  onClick,
}) => (
  <button
    className={`card w-full ${className} ${
      active ? 'border border-gray-600' : ''
    }`}
    onClick={onClick}
  >
    <div className="card-body pb-5 w-full">
      {(markedForQuarterlyReport || collaborator || unfinished) && (
        <Row className="flex-wrap mb-3 badge-row">
          {collaborator && <ActivityCollaboratorBadge />}
          {markedForQuarterlyReport && <ActivityQuarterlyReportBadge />}
          {unfinished && <ActivityUnfinishedBadge />}
        </Row>
      )}
      <Row className="justify-between">
        <Column className="leading-4">
          <div>{title}</div>
          <div className="font-sf-pro-medium text-xs pr-3">
            {activityType.join(', ')}
          </div>
        </Column>
        <div className="font-sf-pro-medium text-xs flex-shrink-0">
          {formatDateShort(date)}
        </div>
      </Row>
    </div>

    <div className="card-footer">
      {areaInvolved === AreaInvolved.REGION && 'Region'}
      {areaInvolved === AreaInvolved.STATEWIDE && 'Statewide'}
      {areaInvolved === AreaInvolved.COUNTY && (
        <Row className="justify-between">
          <Column className="card-footer-col">{region ?? '--'}</Column>
          <Column className="card-footer-col">
            {counties.length ? (
              <>
                {counties.map((county, i) => (
                  <div key={county}>
                    <div>{county}</div>
                    {counties.length > 1 && i !== counties.length - 1 && (
                      <hr className="card-footer-col-devider" />
                    )}
                  </div>
                ))}
              </>
            ) : (
              '--'
            )}
          </Column>
          <Column className="card-footer-col">
            {schoolDistricts.length ? (
              <>
                {schoolDistricts.map((schoolDistrict, i) => (
                  <div key={schoolDistrict}>
                    <div>{schoolDistrict}</div>
                    {schoolDistricts.length > 1 &&
                      i !== schoolDistricts.length - 1 && (
                        <hr className="card-footer-col-devider" />
                      )}
                  </div>
                ))}
              </>
            ) : (
              '--'
            )}
          </Column>
        </Row>
      )}
    </div>
  </button>
);
