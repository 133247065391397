import React, { ComponentPropsWithoutRef, FC } from 'react';
import './SelectInput.css';

interface Props extends ComponentPropsWithoutRef<'select'> {
  grayOut?: boolean;
}

export const SelectInput: FC<Props> = ({
  children,
  className,
  grayOut = false,
  ...rest
}) => (
  <select
    className={`select-input font-sf-pro-semibold ${
      grayOut ? 'text-gray-500' : 'text-gray-800'
    } ${className}`}
    {...rest}
  >
    {children}
  </select>
);
