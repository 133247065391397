import React, { FC } from 'react';
import {
  ActivityAppBarProps,
  ActivityFormAppBar,
} from 'components/ActivityFormAppBar/ActivityFormAppBar';

export const ActivityFormWrapper: FC<ActivityAppBarProps> = ({
  headerLabel,
  onSaveProgressClick,
  onCancelClick,
  children,
  ...props
}) => {
  return (
    <div className="h-screen-full" {...props}>
      <ActivityFormAppBar
        headerLabel={headerLabel}
        onSaveProgressClick={onSaveProgressClick}
        onCancelClick={onCancelClick}
      />
      <div className="px-14 mt-18">{children}</div>
    </div>
  );
};
