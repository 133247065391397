import React, { FC } from 'react';
import { dateObjectToISODate } from 'shared/lib/utils/formatDate';
import { ActivityCollaboratorsInput } from 'components/ActivityCollaboratorsInput/ActivityCollaboratorsInput';
import { ActivityFollowUpRadioInput } from 'components/ActivityFollowUpRadioInput/ActivityFollowUpRadioInput';
import { DatePickerInput } from 'components/DatePickerInput/DatePickerInput';
import { Row } from 'components/Row/Row';
import { TechnicalAssistanceRadioInput } from 'components/TechnicalAssistanceRadioInput/TechnicalAssistanceRadioInput';
import { TextInput } from 'components/TextInput/TextInput';
import { Column } from 'components/Column/Column';
import { ActivityDurationDropdown } from 'components/ActivityDurationDropdown/ActivityDurationDropdown';
import { ActivityTravelInvolvedDropdown } from 'components/ActivityTravelInvolvedDropdown/ActivityTravelInvolvedDropdown';
import { QuarterlyReportToggleInput } from 'components/QuarterlyReportToggleInput/QuarterlyReportToggleInput';
import { ActivityOverviewFormProps } from 'types/ActivityOverviewFormProps';

export const ActivityOverviewForm: FC<ActivityOverviewFormProps> = ({
  title,
  entryDate,
  duration,
  travelInvolved,
  followUp,
  technicalAssistanceMode,
  collaborators,
  appUserOptions,
  markedForQuarterlyReport,
  onTitleChanged,
  onDurationChanged,
  onEntryDateChanged,
  onTravelInvolvedChanged,
  onFollowUpChanged,
  onCollaboratorsChanged,
  onTechnicalAssistanceModeChanged,
  onMarkedForQuarterlyReportChanged,
}) => (
  <div>
    <Row className="activity-form-row justify-between">
      <Column className="activity-form-col">
        <TextInput
          label="Activity Title"
          value={title}
          onChange={(e) => onTitleChanged(e.target.value)}
        />
        <ActivityDurationDropdown
          className="mt-9"
          selected={duration ?? undefined}
          onChange={onDurationChanged}
        />
      </Column>
      <Column className="activity-form-col">
        <div className="absolute right-0 -top-14">
          <QuarterlyReportToggleInput
            selected={markedForQuarterlyReport}
            onChange={onMarkedForQuarterlyReportChanged}
          />
        </div>
        <DatePickerInput
          label="Entry Date"
          selected={entryDate}
          onChange={(dateObject) =>
            onEntryDateChanged(dateObjectToISODate(dateObject))
          }
        />
        <ActivityTravelInvolvedDropdown
          className="mt-9"
          selected={travelInvolved ?? undefined}
          onChange={onTravelInvolvedChanged}
        />
      </Column>
    </Row>
    <ActivityFollowUpRadioInput
      className="mt-9"
      selected={followUp}
      onChange={onFollowUpChanged}
    />
    <TechnicalAssistanceRadioInput
      selected={technicalAssistanceMode}
      className="mt-9"
      onChange={onTechnicalAssistanceModeChanged}
    />

    <Column className="activity-form-col mt-9">
      <ActivityCollaboratorsInput
        options={appUserOptions}
        selected={collaborators?.map((collaborator) => collaborator.userId)}
        onChange={onCollaboratorsChanged}
      />
    </Column>
  </div>
);
