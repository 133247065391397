import React, { ComponentPropsWithoutRef, FC } from 'react';

export const ActivityFormHeading: FC<ComponentPropsWithoutRef<'h1'>> = ({
  children,
  className = '',
}) => (
  <h1 className={`font-sf-pro-light text-blue-400 text-4.5xl ${className}`}>
    {children}
  </h1>
);
