import { useState, useCallback, useEffect } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityType } from 'shared/lib/types/ActivityType';
import { FillableActivityTypeFormFields } from 'types/FillableActivityTypeFormFields';

const defaultActivityTypeFields: FillableActivityTypeFormFields = {
  activityType: undefined,
  otherActivityType: null,
};

export interface ActivityTypeFormReturnValues {
  activityTypeFields: FillableActivityTypeFormFields;
  onActivityTypeChanged(activityType: ActivityType[]): void;
  onOtherActivityTypeChanged(otherActivityType: string): void;
}

export function useActivityTypeForm(
  activity?: Activity,
): ActivityTypeFormReturnValues {
  const [activityTypeFields, setActivityTypeFields] = useState<
    FillableActivityTypeFormFields
  >(defaultActivityTypeFields);

  const onActivityTypeChanged = useCallback((activityType: ActivityType[]) => {
    setActivityTypeFields((prevState) => ({
      ...prevState,
      activityType,
    }));
  }, []);

  const onOtherActivityTypeChanged = useCallback(
    (otherActivityType: string) => {
      setActivityTypeFields((prevState) => ({
        ...prevState,
        otherActivityType,
      }));
    },
    [],
  );

  useEffect(() => {
    if (activity) {
      setActivityTypeFields({
        activityType: activity.activityType,
        otherActivityType: activity.otherActivityType,
      });
    }
  }, [activity]);

  return {
    activityTypeFields,
    onActivityTypeChanged,
    onOtherActivityTypeChanged,
  };
}
