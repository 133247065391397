import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityFile } from 'shared/lib/types/ActivityFile';
import { FileDropzone } from 'components/FileDropzone/FileDropzone';
import { Column } from 'components/Column/Column';
import { RemoveableDropdownSelectItem } from 'components/RemoveableDropdownSelectItem/RemoveableDropdownSelectItem';
import { circleUploadIcon } from 'assets/images';
import { ActivityFormInputLabel } from 'components/ActivityFormInputLabel/ActivityFormInputLabel';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  files: File[];
  existingFiles?: ActivityFile[];
  onExistingFileRemoveClick(activityFile: ActivityFile): void;
  onChange(file: File[]): void;
  onRemoveFile(file: File): void;
}

export const ActivityFileUploadInput: FC<Props> = ({
  files,
  existingFiles,
  onExistingFileRemoveClick,
  onChange,
  onRemoveFile,
  ...props
}) => (
  <div {...props}>
    <FileDropzone
      onDrop={onChange}
      className="flex flex-row justify-center items-center bg-gray-50 pt-6 pb-8 cursor-pointer"
      dragActiveClassname="border-2 border-dashed border-gray-500"
    >
      <Column className="text-center items-center">
        <div className="font-sf-pro-semibold text-gray-900">Upload Files</div>
        <img
          src={circleUploadIcon}
          alt="Upload Icon"
          className="w-12 h-12 mt-3.5"
        />
      </Column>
    </FileDropzone>
    <Column className="activity-form-col mt-4">
      {files.map((file, i) => (
        <RemoveableDropdownSelectItem
          className="mb-1.5"
          index={i + 1}
          key={file.name}
          label={file.name}
          onRemove={() => onRemoveFile(file)}
        />
      ))}
      {existingFiles && existingFiles.length > 0 && (
        <>
          <ActivityFormInputLabel className="mb-2">
            Existing File(s)
          </ActivityFormInputLabel>
          {existingFiles?.map((existingFile, i) => (
            <RemoveableDropdownSelectItem
              className="mb-1.5"
              index={i + 1}
              key={existingFile.key}
              label={existingFile.filename}
              onRemove={() => onExistingFileRemoveClick(existingFile)}
            />
          ))}
        </>
      )}
    </Column>
  </div>
);
