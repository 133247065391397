import { useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { RegionContextValue } from 'contexts/regionContext';
import { Region } from 'shared/lib/types/Region';
import { getRegions } from 'api/region/getRegions';
import { getCounties } from 'api/county/getCounties';
import { getSchoolDistricts } from 'api/schoolDistrict/getSchoolDistricts';
import { County } from 'shared/lib/types/County';
import { SchoolDistrict } from 'shared/lib/types/SchoolDistrict';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

export function useRegion(): RegionContextValue {
  const [loading, setLoading] = useState(false);
  const [regionList, setRegionList] = useState<Region[]>([]);
  const [countyList, setCountyList] = useState<County[]>([]);
  const [schoolDistrictList, setSchoolDistrictList] = useState<
    SchoolDistrict[]
  >([]);
  const { snackbar } = useSnackbarContext();

  useAsyncEffect(async (isCancelled) => {
    setLoading(true);
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });

    try {
      const [regions, counties, schoolDistricts] = await Promise.all([
        getRegions(),
        getCounties(),
        getSchoolDistricts(),
      ]);
      if (!isCancelled()) {
        setRegionList(regions ?? []);
        setCountyList(counties ?? []);
        setSchoolDistrictList(schoolDistricts ?? []);
        snackbar.hide();
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  return {
    loading,
    regionList,
    countyList,
    schoolDistrictList,
  };
}
