import React, { ComponentPropsWithoutRef, FC } from 'react';
import { tickIcon } from 'assets/images';
import './Stepper.css';

interface Step {
  title: string;
  key: number;
  onClick?(): void;
}

interface Props extends ComponentPropsWithoutRef<'div'> {
  steps: Step[];
  currentStep: number;
}

export const Stepper: FC<Props> = ({ steps, currentStep, className }) => (
  <>
    {steps.map((step, index) => {
      const stepIndex = index + 1;
      return (
        <div
          key={step.key}
          className={`step ${
            currentStep >= stepIndex ? 'step-highlight' : ''
          } ${className}`}
        >
          <div>
            {step.onClick ? (
              <button className="title" onClick={step.onClick}>
                {step.title}
              </button>
            ) : (
              <label className="title">{step.title}</label>
            )}
          </div>
          <div>
            <div className="circle">
              {stepIndex < currentStep ? (
                <img
                  src={tickIcon}
                  alt="Checkmark"
                  className="step-complete-check"
                />
              ) : (
                stepIndex
              )}
            </div>
          </div>
        </div>
      );
    })}
  </>
);
