import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityDetailSegment } from 'components/ActivityDetailSegment/ActivityDetailSegment';
import { Activity } from 'shared/lib/types/Activity';
import { ReadOnlyNumberField } from 'components/ReadOnlyNumberField/ReadOnlyNumberField';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';

export type ActivityAudienceDetailProps = ComponentPropsWithoutRef<'div'> &
  Pick<Activity, 'audience' | 'otherAudienceType'>;

export const ActivityAudienceDetail: FC<ActivityAudienceDetailProps> = ({
  audience,
  otherAudienceType,
  ...props
}) => (
  <ActivityDetailSegment heading="Activity Audience" {...props}>
    <Row className="flex-wrap">
      {audience?.map((audienceItem) => (
        <Column className="w-2/4" key={audienceItem.id}>
          <ReadOnlyNumberField
            className="mt-1.5"
            label={audienceItem.audienceType?.name}
            quantity={audienceItem.quantity}
          />
        </Column>
      ))}
      {otherAudienceType && (
        <Column className="justify-center w-2/4 h-9 mt-1.5 detail-text">
          {otherAudienceType}
        </Column>
      )}
    </Row>
  </ActivityDetailSegment>
);
