import { useCallback, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { ActivityFormContextValue } from 'contexts/activityFormContext';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { User } from 'shared/lib/types/User';
import { ActivityTypeKind } from 'shared/lib/types/ActivityTypeKind';
import { ActivityDeliverableKind } from 'shared/lib/types/ActivityDeliverableKind';
import { ActivityAudienceCategory } from 'shared/lib/types/ActivityAudienceCategory';
import { getUsers } from 'api/user/getUsers';
import { getActivityTypes } from 'api/activityType/getActivityTypes';
import { useUserContext } from 'contexts/userContext';
import { getActivityAudienceCategories } from 'api/activityAudience/getActivityAudienceCategories';
import { getActivityDeliverables } from 'api/activiteDeliverable/getActivityDeliverables';
import { getCountiesFromRegionId } from 'api/county/getCountiesFromRegionId';
import { County } from 'shared/lib/types/County';
import { ActivityFormStep } from 'enums/ActivityFormStep';
import { filterVisibleUsers } from 'utils/filterVisibleUsers';
import { sortUsersByRegion } from 'utils/sortUsersByRegion';
import getErrorMessage from 'utils/getErrorMessage';

export function useActivityForm(): ActivityFormContextValue {
  const [currentStep, setCurrentStep] = useState(ActivityFormStep.OVERVIEW);
  const [appUsers, setAppUsers] = useState<User[]>([]);
  const [activityTypeKindList, setActivityTypeKindList] = useState<
    ActivityTypeKind[]
  >([]);
  const [activityAudienceCategories, setActivityAudienceCategories] = useState<
    ActivityAudienceCategory[]
  >([]);
  const [
    activityDeliverableTypeKindList,
    setActivityDeliverableTypeKindList,
  ] = useState<ActivityDeliverableKind[]>([]);
  const [regionalCounties, setRegionalCounties] = useState<County[]>([]);

  const { user } = useUserContext();
  const { snackbar } = useSnackbarContext();

  const onCurrentStepChanged = useCallback((step: ActivityFormStep) => {
    setCurrentStep(step);
  }, []);

  useAsyncEffect(async (isCancelled) => {
    if (user) {
      try {
        const [
          activityTypes,
          activityAudienceCategories,
          deliverableTypes,
          regionalCounties,
          appUsers,
        ] = await Promise.all([
          getActivityTypes(),
          getActivityAudienceCategories(),
          getActivityDeliverables(),
          getCountiesFromRegionId(user.regionId),
          getUsers(),
        ]);

        if (!isCancelled()) {
          setAppUsers(sortUsersByRegion(filterVisibleUsers(appUsers)));
          setActivityTypeKindList(activityTypes);
          setActivityAudienceCategories(activityAudienceCategories);
          setActivityDeliverableTypeKindList(deliverableTypes);
          setRegionalCounties(regionalCounties ?? []);
        }
      } catch (e) {
        if (!isCancelled()) {
          snackbar.show({
            severity: SnackbarSeverityKind.ERROR,
            text: getErrorMessage(e),
          });
        }
      }
    }
  }, []);

  return {
    currentStep,
    appUsers,
    regionalCounties,
    activityTypeKindList,
    activityDeliverableTypeKindList,
    activityAudienceCategories,
    onCurrentStepChanged,
  };
}
