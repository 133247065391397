import React, { FC, useCallback, useState, useEffect } from 'react';
import { ActivityFormStepWrapper } from 'components/ActivityFormStepWrapper/ActivityFormStepWrapper';
import { ActivityCommentsAndFilesForm } from 'components/ActivityCommentsAndFilesForm/ActivityCommentsAndFilesForm';
import { ActivityCommentsAndFilesFormProps } from 'types/ActivityCommentsAndFilesFormProps';
import { deleteActivityFile } from 'api/activity/deleteActivityFile';
import { ActivityFile } from 'shared/lib/types/ActivityFile';
import { DeleteExistingFileConfirmationModal } from 'components/DeleteExistingFileConfirmationModal/DeleteExistingFileConfirmationModal';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

export const ActivityFormStep6: FC<ActivityCommentsAndFilesFormProps> = ({
  existingFiles,
  ...rest
}) => {
  const [existingFileList, setExistingFileList] = useState<ActivityFile[]>(
    existingFiles ?? [],
  );
  const [existingFileToDelete, setExistingFileToDelete] = useState<
    ActivityFile
  >();
  const [showDeleteExistingFileModal, setShowDeleteExistingModal] = useState(
    false,
  );
  const { snackbar } = useSnackbarContext();

  const handleDeleteExistingFileUpload = useCallback(async () => {
    try {
      snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
      if (existingFileToDelete) {
        await deleteActivityFile(
          existingFileToDelete.activityId,
          existingFileToDelete.id,
        );
        setExistingFileList((prevState) =>
          prevState.filter(
            (activityFile) => activityFile.id !== existingFileToDelete.id,
          ),
        );
        snackbar.show({
          severity: SnackbarSeverityKind.SUCCESS,
          text: 'File Deleted Successfully!',
        });
      }
    } catch (e) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(e),
      });
    } finally {
      setExistingFileToDelete(undefined);
      setShowDeleteExistingModal(false);
    }
  }, [existingFileToDelete, snackbar]);

  const handleExistingFileRemoveClick = useCallback(
    async (activityFile: ActivityFile) => {
      setExistingFileToDelete(activityFile);
      setShowDeleteExistingModal(true);
    },
    [],
  );

  useEffect(() => {
    if (existingFiles) {
      setExistingFileList(existingFiles);
    }
  }, [existingFiles]);

  return (
    <ActivityFormStepWrapper heading="Comments + Files">
      {showDeleteExistingFileModal && (
        <DeleteExistingFileConfirmationModal
          className="mb-4"
          filename={existingFileToDelete?.filename ?? ''}
          onCancel={() => setShowDeleteExistingModal(false)}
          onConfirmDelete={handleDeleteExistingFileUpload}
          onClose={() => setShowDeleteExistingModal(false)}
        />
      )}
      <ActivityCommentsAndFilesForm
        onExistingFileRemoveClick={handleExistingFileRemoveClick}
        existingFiles={existingFileList}
        {...rest}
      />
    </ActivityFormStepWrapper>
  );
};
