import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { CheckboxRadioInput } from 'components/CheckboxRadioInput/CheckboxRadioInput';
import { RadioInput } from 'components/RadioInput/RadioInput';

interface Props extends ComponentPropsWithoutRef<'input'> {
  label: string;
  containerClassName?: string;
  type?: 'radio' | 'checkbox';
  infoText?: string;
}

export const RadioInputWithInfo: FC<Props> = ({
  infoText,
  containerClassName = '',
  type,
  ...props
}) => (
  <Row className={`items-center ${containerClassName}`}>
    {type === 'radio' ? (
      <RadioInput {...props} />
    ) : (
      <CheckboxRadioInput {...props} />
    )}
    {infoText && (
      <InfoTooltip text={infoText} className="w-5 h-5 ml-2.5 flex-shrink-0" />
    )}
  </Row>
);
