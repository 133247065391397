import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props extends ComponentPropsWithoutRef<'div'> {
  percent: number;
  label: ReactNode;
}

export const ProgressBar: FC<Props> = ({
  percent,
  label,
  className,
  ...rest
}) => (
  <div
    {...rest}
    className={twMerge('relative flex items-center h-4', className)}
    style={{ width: `${percent}%` }}
  >
    <div className="absolute left-full ml-1.5 text-sm whitespace-nowrap">
      {label}
    </div>
  </div>
);
