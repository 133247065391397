import React, { FC, useCallback, useState } from 'react';
import { PasswordResetForm } from 'components/PasswordResetForm/PasswordResetForm';
import { PublicPageWrapper } from 'components/PublicPageWrapper/PublicPageWrapper';
import { SemiBoldHeader } from 'components/SemiBoldHeader/SemiBoldHeader';
import { PasswordResetConfirmation } from 'components/PasswordResetConfirmation/PasswordResetConfirmation';
import { resetPassword } from 'api/reset-password/resetPassword';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

export interface FormValues {
  email: string;
  password: string;
  token: string;
}

export const PasswordResetPage: FC = (props) => {
  const [passwordResetConfirmation, setPasswordResetConfirmation] = useState(
    false,
  );
  const { snackbar } = useSnackbarContext();

  const handleSubmit = useCallback(
    async (formValues) => {
      snackbar.show({
        severity: SnackbarSeverityKind.SPINNER,
        text: 'Processing...',
      });
      try {
        await resetPassword(formValues);
        setPasswordResetConfirmation(true);
        snackbar.hide();
      } catch (e) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    },
    [snackbar],
  );

  return (
    <PublicPageWrapper {...props}>
      {passwordResetConfirmation ? (
        <PasswordResetConfirmation />
      ) : (
        <>
          <SemiBoldHeader text="Reset Password" className="mb-12" />
          <PasswordResetForm onSubmit={handleSubmit} />
        </>
      )}
    </PublicPageWrapper>
  );
};
