import React, { ComponentPropsWithoutRef, FC } from 'react';
import { minutesToHours } from 'shared/lib/utils/minutesToHours';
import { Activity } from 'shared/lib/types/Activity';
import { Row } from 'components/Row/Row';
import { Column } from 'components/Column/Column';

interface Props extends ComponentPropsWithoutRef<'div'> {
  activityList: Activity[];
}

export const ActivityDurationSummary: FC<Props> = ({
  activityList,
  className = '',
  ...rest
}) => {
  const activityDurationMinutes = activityList.reduce(
    (sum, { duration }) => (duration ? sum + duration : sum),
    0,
  );
  const averageActivityDurationMinutes =
    activityDurationMinutes / activityList.length;

  const hours = Math.floor(minutesToHours(activityDurationMinutes));
  const minutes = +(activityDurationMinutes % 60);
  const averageHours = Math.floor(
    minutesToHours(averageActivityDurationMinutes),
  );
  const averageMinutes = Math.floor(+(averageActivityDurationMinutes % 60));
  return (
    <div
      className={`text-2xl font-sf-pro-light text-gray-900 ${className}`}
      {...rest}
    >
      {hours || minutes ? (
        <Column className="print:flex-row print:items-center print:justify-around print:my-6">
          <Column>
            <div>Total Time</div>
            <Row className="text-tnf-green text-5xl font-sf-pro-light mt-1.5">
              {hours > 0 && <div>{hours} hr</div>}
              {minutes > 0 && (
                <div className={`${hours > 0 ? 'ml-5' : ''}`}>
                  {minutes} min
                </div>
              )}
            </Row>
          </Column>
          <Column className="mt-15 print:mt-0">
            <div>Average Time Per Activity</div>
            <Row className="text-tnf-green text-5xl font-sf-pro-light mt-1.5">
              {averageHours > 0 && <div>{averageHours} hr</div>}
              {averageMinutes > 0 && (
                <div className={averageHours > 0 ? 'ml-5' : ''}>
                  {averageMinutes} min
                </div>
              )}
            </Row>
          </Column>
        </Column>
      ) : (
        <div>No activity duration data available</div>
      )}
    </div>
  );
};
