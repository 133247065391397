import React, { FC } from 'react';
import { pencilIcon, trashIcon } from 'assets/images';
import { Column } from 'components/Column/Column';
import { Row } from 'components/Row/Row';
import { PageSegmentHeadingIcons } from 'enums/PageSegmentHeadingIcons';

interface Props {
  icon?: PageSegmentHeadingIcons;
  onIconClick?: () => void;
  headingClassname?: string;
}

const ICONS = new Map<string, string>([
  [PageSegmentHeadingIcons.TRASH, trashIcon],
  [PageSegmentHeadingIcons.PENCIL, pencilIcon],
]);

export const PageSegmentHeading: FC<Props> = ({
  icon,
  onIconClick,
  headingClassname = '',
  children,
}) => (
  <Column>
    <Row className="items-center">
      <div className={`blue-heading-light mb-1 ${headingClassname}`}>
        {children}
      </div>
      {icon && (
        <button className="ml-6" onClick={onIconClick}>
          <img src={ICONS.get(icon)} alt="Icon" className="h-6 w-auto" />
        </button>
      )}
    </Row>
    <div className="h-2.5 w-100 bg-gray-100 rounded-sm"></div>
  </Column>
);
