import { Column } from 'components/Column/Column';
import React, { FC } from 'react';
interface Props {
  imageSrc: string;
  imageAlt: string;
  label: string;
  onClick: () => void;
}
export const NavigationItem: FC<Props> = ({
  imageSrc,
  imageAlt,
  label,
  onClick,
}) => {
  return (
    <Column onClick={onClick} className="cursor-pointer">
      <div className="text-center uppercase font-sf-pro-medium text-sm text-gray-800 mb-1.5">
        {label}
      </div>
      <div>
        <img className="w-15 h-auto" src={imageSrc} alt={imageAlt} />
      </div>
    </Column>
  );
};
