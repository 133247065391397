import React, { FC } from 'react';
import { SemiBoldHeader } from 'components/SemiBoldHeader/SemiBoldHeader';
import { Link } from 'react-router-dom';

export const ForgotPasswordEmailConfirmation: FC = (props) => (
  <div {...props}>
    <SemiBoldHeader text="Check Your Email" />
    <div className="subheading-sm mb-12">
      We have sent an email with instructions for recovering your password.
    </div>
    <Link
      className="btn-green px-3 py-3 text-sm font-sf-pro-medium"
      to="/login"
    >
      Return to login
    </Link>
  </div>
);
