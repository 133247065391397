import React from 'react';
import {
  createActivityRoute,
  updateActivityRoute,
} from 'shared/lib/constants/routes/activityRoutes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CreateActivityPage } from 'pages/CreateActivityPage/CreateActivityPage';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { UpdateActivityPage } from 'pages/UpdateActivityPage/UpdateActivityPage';
import { homeRoute } from 'shared/lib/constants/routes/commonRoutes';

export function ActivityRoutes() {
  return (
    <Switch>
      <Route exact path={createActivityRoute}>
        <CreateActivityPage />
      </Route>
      <Route path={updateActivityRoute}>
        {({ match }) => {
          if (!match) {
            return null;
          }

          const activityId = +match.params.id;
          if (Number.isNaN(activityId)) {
            return <Redirect to={activityListRoute} />;
          }
          return <UpdateActivityPage activityId={activityId} />;
        }}
      </Route>
      <Redirect to={homeRoute} />
    </Switch>
  );
}
