import React, { FC, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getObjectPropertyValueFrequency } from 'shared/lib/utils/getObjectPropertyValueFrequency';
import { getUserActivities } from 'api/activity/getUserActivities';
import {
  OregonCountyMap,
  CountyMapActivityData,
} from 'components/OregonCountyMap/OregonCountyMap';
import amchartOregonCountyKeys from 'constants/amchartsOregonCountyKeys';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { useLocation } from 'react-router-dom';
import { SnackbarProps } from 'components/Snackbar/Snackbar';
import getErrorMessage from 'utils/getErrorMessage';

type DashboardLocationState = {
  snackbarProps: SnackbarProps;
};

export const DashboardPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const [oregonMapData, setOregonMapData] = useState<CountyMapActivityData[]>(
    [],
  );
  const { snackbar } = useSnackbarContext();
  const location = useLocation<DashboardLocationState | undefined>();

  useAsyncEffect(async (isCancelled) => {
    setLoading(true);
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });

    try {
      const activities = await getUserActivities();

      if (!isCancelled()) {
        if (activities) {
          const activityCountyFrequency = getObjectPropertyValueFrequency({
            data: activities
              .flatMap(({ counties }) => counties)
              .map((activityCounty) => ({
                countyName: activityCounty?.county?.name,
              })),
            key: 'countyName',
          });
          setOregonMapData(
            activityCountyFrequency.map(({ value, frequency }) => {
              const key = value?.toLocaleLowerCase().replaceAll(' ', '') ?? '';
              return {
                countyId:
                  key in amchartOregonCountyKeys
                    ? amchartOregonCountyKeys[key]
                    : '',
                value: frequency,
              };
            }),
          );
        }

        // Snackbar props, which might be populated when coming from activity
        // form pages after saving and returning home
        if (location.state?.snackbarProps?.text) {
          snackbar.show({ ...location.state.snackbarProps });
          window.history.replaceState({}, '');
        } else {
          snackbar.hide();
        }
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  return loading ? null : <OregonCountyMap activityData={oregonMapData} />;
};
