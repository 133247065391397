import React, { ComponentPropsWithoutRef, FC } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'onDrop'> {
  onDrop(files: File[]): void;
  dragActiveClassname?: string;
}

export const FileDropzone: FC<Props> = ({
  onDrop,
  className = '',
  dragActiveClassname = '',
  children,
  ...props
}) => {
  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div
      {...props}
      {...getRootProps({
        className: `dropzone ${className} ${
          isDragActive && dragActiveClassname
        }`,
      })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
};
