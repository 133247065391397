import { Column } from 'components/Column/Column';
import React, { ComponentPropsWithoutRef, FC } from 'react';

interface TextInputProps extends ComponentPropsWithoutRef<'input'> {
  type?: 'text' | 'password' | 'tel';
  label?: string;
  containerClassName?: string;
  readOnly?: boolean;
}

const editableStyles = 'border-b-2 border-gray-500';

export const TextInput: FC<TextInputProps> = ({
  type = 'text',
  className = '',
  containerClassName = '',
  label = '',
  readOnly,
  ...rest
}) => (
  <Column className={containerClassName}>
    <div className="pb-1 input-label">{label}</div>
    <input
      type={type}
      className={`placeholder-black h-8 w-full text-base font-sf-pro-medium disabled:bg-white rounded-none px-px ${
        !readOnly ? editableStyles : ''
      } ${className}`}
      disabled={readOnly}
      {...rest}
    />
  </Column>
);
