import React, { ComponentPropsWithRef, FC } from 'react';
import { CircularActionIconKind } from 'enums/CircularActionIconKind';
import {
  circleDownloadIcon,
  circleFilterActiveIcon,
  circleFilterIcon,
  circlePlusGreenIcon,
  circlePlusIcon,
  circleShareActiveIcon,
  circleShareIcon,
  circleXActiveIcon,
  circleXGreenIcon,
  downChevronRoundIcon,
  circlePlusDarkIcon,
} from 'assets/images';
interface Props extends ComponentPropsWithRef<'img'> {
  icon: CircularActionIconKind;
  className?: string;
}

interface ImageProps {
  src: string;
  alt: string;
}

const iconsMap = new Map<CircularActionIconKind, ImageProps>([
  [
    CircularActionIconKind.X_ACTIVE,
    { src: circleXActiveIcon, alt: 'Circular X Icon Active' },
  ],
  [
    CircularActionIconKind.PLUS,
    { src: circlePlusIcon, alt: 'Circular Plus Icon' },
  ],
  [
    CircularActionIconKind.PLUS_GREEN,
    { src: circlePlusGreenIcon, alt: 'Circular Plus Icon Green' },
  ],
  [
    CircularActionIconKind.PLUS_DARK,
    { src: circlePlusDarkIcon, alt: 'Circular Plus Icon Dark' },
  ],
  [
    CircularActionIconKind.X_GREEN,
    { src: circleXGreenIcon, alt: 'Circular X Icon Green' },
  ],
  [
    CircularActionIconKind.DOWN_CHEVRON_ROUND,
    { src: downChevronRoundIcon, alt: 'Down Chevron Round' },
  ],
  [
    CircularActionIconKind.FILTER,
    { src: circleFilterIcon, alt: 'Filter Inactive' },
  ],
  [
    CircularActionIconKind.FILTER_ACTIVE,
    { src: circleFilterActiveIcon, alt: 'Filter Active' },
  ],
  [
    CircularActionIconKind.SHARE,
    { src: circleShareIcon, alt: 'Share Inactive' },
  ],
  [
    CircularActionIconKind.SHARE_ACTIVE,
    { src: circleShareActiveIcon, alt: 'Share Active' },
  ],
  [
    CircularActionIconKind.DOWNLOAD,
    { src: circleDownloadIcon, alt: 'Download Icon' },
  ],
]);

export const CircularActionButtonIcon: FC<Props> = ({ icon, className }) => {
  const imageSrc = iconsMap.get(icon)?.src;
  const imageAlt = iconsMap.get(icon)?.alt;

  return (
    <img
      src={imageSrc}
      alt={imageAlt}
      className={`w-full h-full ${className}`}
    />
  );
};
