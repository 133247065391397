import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Column } from 'components/Column/Column';
import { UserCard } from 'components/UserCard/UserCard';
import { User } from 'shared/lib/types/User';
import { formatUserDetailRoute } from 'shared/lib/constants/routes/administratorRoutes';
import formatName from 'shared/lib/utils/formatName';
import { useHistory } from 'react-router';

interface Props extends ComponentPropsWithoutRef<'div'> {
  users: User[];
  activeUserId?: number;
}

export const UserManagementCardList: FC<Props> = ({
  users = [],
  activeUserId,
  className = '',
}) => {
  const history = useHistory();

  return (
    <>
      {users.length ? (
        <Column className={`${className} overflow-auto h-full `}>
          {users.map((user) => {
            const { id, role, firstName, lastName, pending } = user;
            return (
              <UserCard
                key={id}
                role={role}
                pending={pending}
                className="mb-2.5"
                name={formatName({firstName, lastName})}
                active={activeUserId === id}
                onClick={() => {
                  history.push(formatUserDetailRoute(id));
                }}
              />
            );
          })}
        </Column>
      ) : (
        <div className="text-gray-800 font-sf-pro-light text-base">
          No users found.
        </div>
      )}
    </>
  );
};
