import { useState, useCallback, useEffect } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityAudience } from 'shared/lib/types/ActivityAudience';
import { FillableActivityAudienceFormFields } from 'types/FillableActivityAudienceFormFields';

const defaultActivityAudienceFields: FillableActivityAudienceFormFields = {
  audience: [],
  otherAudienceType: null,
};

export interface ActivityAudienceFormReturnValues {
  activityAudienceFields: FillableActivityAudienceFormFields;
  onActivityAudienceChanged(audience: ActivityAudience[]): void;
  onOtherActivityAudienceChanged(otherAudienceType: string): void;
}

export function useActivityAudienceForm(
  activity?: Activity,
): ActivityAudienceFormReturnValues {
  const [activityAudienceFields, setactivityAudienceFields] = useState<
    FillableActivityAudienceFormFields
  >(defaultActivityAudienceFields);

  const onActivityAudienceChanged = useCallback(
    (audience: ActivityAudience[]) => {
      setactivityAudienceFields((prevState) => ({
        ...prevState,
        audience,
      }));
    },
    [],
  );

  const onOtherActivityAudienceChanged = useCallback(
    (otherAudienceType: string) => {
      setactivityAudienceFields((prevState) => ({
        ...prevState,
        otherAudienceType,
      }));
    },
    [],
  );

  useEffect(() => {
    if (activity) {
      setactivityAudienceFields({
        audience: activity.audience,
        otherAudienceType: activity.otherAudienceType,
      });
    }
  }, [activity]);

  return {
    activityAudienceFields,
    onActivityAudienceChanged,
    onOtherActivityAudienceChanged,
  };
}
