import { AreaInvolved } from 'shared/lib/constants/activity/AreaInvolved';
import { Activity } from 'shared/lib/types/Activity';
import { ActivityFilterOtherOption } from 'enums/ActivityFilterOtherOption';
import { ActivityFilter } from 'types/ActivityFilter';

export function filterActivities(
  activities: Activity[],
  {
    statewide,
    regionId,
    countyId,
    schoolDistrictId,
    activityDurations,
    travelDurations,
    activityTypes,
    deliverableTypes,
    audienceTypes,
    technicalAssistanceMode,
    collaboratorIds,
    attendeeIds,
    activityInQuarterlyReport,
    commentsInQuarterlyReport,
    hasComments,
    hasFiles,
    followUp,
  }: ActivityFilter,
) {
  if (
    statewide &&
    (regionId !== null || countyId !== null || schoolDistrictId !== null)
  ) {
    if (process.env.NODE_ENV !== 'test') {
      console.error(
        'Invalid activity filter: A statewide filter may not be combined with a region, county, or school district filter.',
        { statewide, regionId, countyId, schoolDistrictId },
      );
    }
    return [];
  }

  return activities.filter((activity) => {
    // Statewide filter
    if (statewide && activity.areaInvolved !== AreaInvolved.STATEWIDE) {
      return false;
    }

    // Region filter
    if (regionId !== null) {
      if (
        activity.areaInvolved === AreaInvolved.COUNTY &&
        (!activity.counties?.length ||
          !activity.counties.some(
            ({ county }) => county?.regionId === regionId,
          ))
      ) {
        return false;
      }

      if (
        activity.areaInvolved === AreaInvolved.REGION &&
        activity.user?.user?.regionId !== regionId
      ) {
        return false;
      }

      // No condition for AreaInvolved.STATEWIDE -- No region should be set for
      // a statewide activity, and even if one is, statewide takes preidence.
    }

    // County filter
    if (
      countyId !== null &&
      (!activity.counties?.length ||
        !activity.counties.some((county) => county.countyId === countyId))
    ) {
      return false;
    }

    // School district filter
    if (
      schoolDistrictId !== null &&
      (!activity.schoolDistricts?.length ||
        !activity.schoolDistricts.some(
          (district) => district.schoolDistrictId === schoolDistrictId,
        ))
    ) {
      return false;
    }

    // Activity duration filter
    if (
      activityDurations?.length &&
      (activity.duration === null ||
        !activityDurations.includes(activity.duration))
    ) {
      return false;
    }

    // Travel duration (Travel Involved) filter
    if (
      travelDurations?.length &&
      (activity.travelInvolved === null ||
        !travelDurations.includes(activity.travelInvolved))
    ) {
      return false;
    }

    // Activity type filter
    if (activityTypes?.length) {
      const hasMatchingActivityTypes =
        !!activity.activityType?.length &&
        activity.activityType.some(({ activityTypeKindId }) =>
          activityTypes.includes(activityTypeKindId),
        );
      const hasMatchingOtherActivityType =
        !!activity.otherActivityType &&
        activityTypes.includes(ActivityFilterOtherOption.OTHER);

      if (!hasMatchingActivityTypes && !hasMatchingOtherActivityType) {
        return false;
      }
    }

    // Deliverable type filter
    if (deliverableTypes?.length) {
      const hasMatchingDeliverableTypes =
        !!activity.activityDeliverables?.length &&
        activity.activityDeliverables.some(({ activityDeliverableKindId }) =>
          deliverableTypes.includes(activityDeliverableKindId),
        );
      const hasMatchingOtherDeliverableType =
        !!activity.otherActivityDeliverable &&
        deliverableTypes.includes(ActivityFilterOtherOption.OTHER);

      if (!hasMatchingDeliverableTypes && !hasMatchingOtherDeliverableType) {
        return false;
      }
    }

    // Audience type filter
    if (audienceTypes?.length) {
      const hasMatchingAudienceTypes =
        !!activity.audience?.length &&
        activity.audience.some(({ audienceTypeId }) =>
          audienceTypes.includes(audienceTypeId),
        );
      const hasMatchingOtherAudienceType =
        !!activity.otherAudienceType &&
        audienceTypes.includes(ActivityFilterOtherOption.OTHER);

      if (!hasMatchingAudienceTypes && !hasMatchingOtherAudienceType) {
        return false;
      }
    }

    // Technical Assistance Mode filter
    if (
      technicalAssistanceMode &&
      activity.technicalAssistanceMode !== technicalAssistanceMode
    ) {
      return false;
    }

    // Collaborator filter
    if (
      collaboratorIds?.length &&
      (!activity.collaborators?.length ||
        !activity.collaborators.some(({ userId }) =>
          collaboratorIds.includes(userId),
        ))
    ) {
      return false;
    }

    // Attendee filter
    if (
      attendeeIds?.length &&
      (!activity.attendees?.length ||
        !activity.attendees.some(({ userId }) => attendeeIds.includes(userId)))
    ) {
      return false;
    }

    // Activity Marked for Quarterly Report filter
    if (
      typeof activityInQuarterlyReport === 'boolean' &&
      activity.markedForQuarterlyReport !== activityInQuarterlyReport
    ) {
      return false;
    }

    // Comments Included in Quarterly Report filter
    if (
      typeof commentsInQuarterlyReport === 'boolean' &&
      activity.showCommentsInQuarterlyReport !== commentsInQuarterlyReport
    ) {
      return false;
    }

    // Has Comments filter
    if (
      typeof hasComments === 'boolean' &&
      Boolean(activity.comments) !== hasComments
    ) {
      return false;
    }

    // Has Files filter
    if (
      typeof hasFiles === 'boolean' &&
      (activity.files === undefined || // undefined means files weren't queried
        Boolean(activity.files.length) !== hasFiles)
    ) {
      return false;
    }

    // Follow-up filter
    if (typeof followUp === 'boolean' && activity.followUp !== followUp) {
      return false;
    }

    return true;
  });
}
