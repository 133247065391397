import React, { FC, useCallback } from 'react';
import {
  DropdownInput,
  DropdownInputProps,
} from 'components/DropdownInput/DropdownInput';
import { ActivityTravelDuration } from 'shared/lib/constants/activity/ActivityTravelDuration';
import { formatDuration } from 'shared/lib/utils/formatDuration';

interface Props extends Omit<DropdownInputProps, 'onChange' | 'options'> {
  onChange(minutes: number): void;
}

const durationOptions = ActivityTravelDuration.map((minutes) => ({
  id: minutes,
  value: formatDuration(minutes),
}));

export const ActivityTravelInvolvedDropdown: FC<Props> = ({
  onChange,
  ...props
}) => {
  const handleDropdownChange = useCallback(
    (value) => {
      onChange(value.id);
    },
    [onChange],
  );
  return (
    <DropdownInput
      label="Travel Involved"
      placeholder="Choose Time"
      onChange={handleDropdownChange}
      options={durationOptions}
      {...props}
    />
  );
};
