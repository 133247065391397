import React, { FC, useState, useCallback } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { activityListRoute } from 'shared/lib/constants/routes/activityListRoutes';
import { getActivityFromId } from 'api/activity/getActivityFromId';
import { getCountiesInActivityRegion } from 'api/activity/getCountiesInActivityRegion';
import getErrorMessage from 'utils/getErrorMessage';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { ActivityForm } from 'components/ActivityForm/ActivityForm';
import { useActivityFormSubmit } from 'hooks/useActivityFormSubmit';
import { FillableActivityFormFields } from 'types/FillableActivityFormFields';
import { useActivityFormContext } from 'contexts/activityFormContext';
import { ActivityFormStep } from 'enums/ActivityFormStep';
import { SnackbarProps } from 'components/Snackbar/Snackbar';
import { County } from 'shared/lib/types/County';

interface Props {
  activityId: number;
}

export const UpdateActivityPage: FC<Props> = ({ activityId }) => {
  const [activity, setActivity] = useState<Activity>();
  const [
    activityCreatorRegionalCounties,
    setActivityCreatorRegionalCounties,
  ] = useState<County[]>([]);

  const { onUpdateActivity } = useActivityFormSubmit();
  const { snackbar } = useSnackbarContext();
  const { onCurrentStepChanged } = useActivityFormContext();
  const history = useHistory();

  const handleUpdateActivityAndReturnToList = useCallback(
    async (formFields: FillableActivityFormFields) => {
      const updatedActivity = await onUpdateActivity(activityId, formFields);
      if (updatedActivity) {
        onCurrentStepChanged(ActivityFormStep.OVERVIEW);
        history.push({
          pathname: activityListRoute,
          state: {
            snackbarProps: {
              severity: SnackbarSeverityKind.SUCCESS,
              text: 'Activity Updated Successfully!',
            } as SnackbarProps,
          },
        });
      }
    },
    [activityId, history, onCurrentStepChanged, onUpdateActivity],
  );

  const handleUpdateActivityAndContinue = useCallback(
    async (formFields: FillableActivityFormFields) => {
      const activity = await onUpdateActivity(activityId, formFields);
      if (activity) {
        setActivity(activity);
      }
    },
    [activityId, onUpdateActivity],
  );

  useAsyncEffect(async (isCancelled) => {
    snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
    try {
      const [userActivity, activityRegionCounties] = await Promise.all([
        getActivityFromId(activityId),
        getCountiesInActivityRegion(activityId),
      ]);
      if (!isCancelled()) {
        if (userActivity === null) {
          snackbar.hide();
          history.push(activityListRoute);
        } else {
          setActivity(userActivity);
          setActivityCreatorRegionalCounties(activityRegionCounties);
          snackbar.hide();
        }
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    }
  }, []);

  return (
    <div>
      {activity && activityCreatorRegionalCounties.length > 0 && (
        <ActivityForm
          appBarHeaderLabel="Update Activity"
          activity={activity}
          regionalCounties={activityCreatorRegionalCounties}
          onSubmitAndReturnToList={handleUpdateActivityAndReturnToList}
          onSubmitAndContinue={handleUpdateActivityAndContinue}
        />
      )}
    </div>
  );
};
