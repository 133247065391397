import React, { FC } from 'react';
import { Row } from 'components/Row/Row';
import { envelopeIcon } from 'assets/images';

export const PendingInvitationLabel: FC = (props) => (
  <Row className="items-center">
    <img src={envelopeIcon} alt="Envelope icon" className="w-4 h-auto mr-1" />
    <div>pending</div>
  </Row>
);
