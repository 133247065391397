import { UserRole } from 'shared/lib/constants/user/UserRole';

export const userRoleDisplayNameMappings = new Map<UserRole, string>([
  [UserRole.NON_COLLABORATOR_SUPER_ADMIN, 'Super Admin (non-collaborator)'],
  [
    UserRole.NON_COLLABORATOR_SUPER_ADMIN_READ_ONLY,
    'Super Admin (non-collaborator, read-only)',
  ],
  [UserRole.PRE_ETS_SUPPORT_SPECIALIST, 'Pre-ETS Support Specialist'],
  [UserRole.SUPER_ADMIN, 'Super Admin'],
  [UserRole.TNF, 'TNF'],
]);
