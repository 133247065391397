import { ActivityFormContext } from 'contexts/activityFormContext';
import { useActivityForm } from 'hooks/useActivityForm';
import { ActivityListPage } from 'pages/ActivityListPage/ActivityListPage';
import { DashboardPage } from 'pages/DashboardPage/DashboardPage';
import { ProfileManagementPage } from 'pages/ProfileManagementPage/ProfileManagementPage';
import { ReportPage } from 'pages/ReportPage/ReportPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  homeRoute,
  reportRoute,
  profileRoute,
} from 'shared/lib/constants/routes/commonRoutes';
import {
  activityListRoute,
  activityDetailRoute,
} from 'shared/lib/constants/routes/activityListRoutes';
import { ActivityRoutes } from './ActivityRoutes';

export function CommonRoutes() {
  const { appUsers, ...restOfContextValues } = useActivityForm();
  return (
    <Switch>
      <Route exact path={homeRoute}>
        <DashboardPage />
      </Route>
      <Route path={activityDetailRoute}>
        {({ match }) => {
          if (!match) {
            return null;
          }

          const activityId = +match.params.id;
          if (Number.isNaN(activityId)) {
            return <Redirect to={activityListRoute} />;
          }
          return <ActivityListPage activityId={activityId} />;
        }}
      </Route>
      <Route exact path={activityListRoute}>
        <ActivityListPage />
      </Route>
      <Route exact path={reportRoute}>
        <ReportPage />
      </Route>
      <Route exact path={profileRoute}>
        <ProfileManagementPage />
      </Route>
      <ActivityFormContext.Provider
        value={{ appUsers, ...restOfContextValues }}
      >
        <ActivityRoutes />
      </ActivityFormContext.Provider>

      <Redirect to={homeRoute} />
    </Switch>
  );
}
