import React, { ComponentPropsWithoutRef, FC } from 'react';

export const ActivityFormInputLabel: FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
  className = '',
}) => (
  <div className={`text-blue-400 font-sf-pro-semibold text-lg ${className}`}>
    {children}
  </div>
);
