import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ActivityCardBadge } from 'components/ActivityCardBadge/ActivityCardBadge';

export const ActivityCollaboratorBadge: FC<ComponentPropsWithoutRef<'div'>> = (
  props,
) => (
  <ActivityCardBadge
    label="Collaborator"
    badgeLabel="C"
    badgeClassName="bg-blue-400"
    className="text-blue-400"
    {...props}
  />
);
