import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useState,
} from 'react';
import { MeatballsButton } from 'components/MeatballsButton/MeatballsButton';
import { ListGroup } from 'components/ListGroup/ListGroup';
import './ActivityDetailMeatballsMenu.css';
import { useUserContext } from 'contexts/userContext';
import { isViewOnly } from 'shared/lib/utils/isSuperAdmin';

interface Props extends ComponentPropsWithoutRef<'div'> {
  active?: boolean;
  onDeleteActivityClick(): void;
  onEditActivityClick(): void;
}

export const ActivityDetailMeatballsMenu: FC<Props> = ({
  active,
  onDeleteActivityClick,
  onEditActivityClick,
  ...rest
}) => {
  const { user } = useUserContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClickOutside = useCallback(() => setMenuOpen(false), []);
  const handleDeleteActivityClick = useCallback(() => {
    setMenuOpen(false);
    onDeleteActivityClick();
  }, [onDeleteActivityClick]);

  const handleEditActivityClick = useCallback(() => {
    setMenuOpen(false);
    onEditActivityClick();
  }, [onEditActivityClick]);
  return (
    <div {...rest}>
      <MeatballsButton
        active={menuOpen || active}
        disabled={isViewOnly(user)}
        onClick={() => setMenuOpen(true)}
      />
      {menuOpen && (
        <ListGroup
          onClickOutside={handleClickOutside}
          className="absolute mt-1 right-0 z-50 meatballs-list-group"
        >
          <li onClick={handleDeleteActivityClick}>Delete Activity</li>
          <li onClick={handleEditActivityClick}>Edit Activity</li>
        </ListGroup>
      )}
    </div>
  );
};
