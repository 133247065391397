import React, { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { Notification } from 'shared/lib/types/Notification';
import { getUserNotifications } from 'api/notification/getUserNotifications';
import { markNotificationAsRead } from 'api/notification/markNotificationAsRead';
import { AppBar } from 'components/AppBar/AppBar';
import { Navigation } from 'components/Navigation/Navigation';
import { NotificationAlertBadge } from 'components/NotificationAlertBadge/NotificationAlertBadge';
import { ActivityCollaboratorNotificationModal } from 'components/ActivityCollaboratorNotificationModal/ActivityCollaboratorNotificationModal';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import getErrorMessage from 'utils/getErrorMessage';

export const AuthenticatedAppBar: FC = () => {
  const location = useLocation();
  const { snackbar } = useSnackbarContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const handleCloseNotificationModal = useCallback(async () => {
    setShowNotificationModal(false);
    setNotifications([]);

    try {
      await Promise.all(
        notifications.map(({ id }) => markNotificationAsRead(id)),
      );
    } catch (e) {
      snackbar.show({
        severity: SnackbarSeverityKind.ERROR,
        text: getErrorMessage(e),
      });
    }
  }, [snackbar, notifications]);

  useAsyncEffect(async (isCancelled) => {
    try {
      const fetchedNotifications = await getUserNotifications();
      if (fetchedNotifications && !isCancelled()) {
        setNotifications(
          fetchedNotifications.filter(
            (notification) => notification.activityCollaboratorNotification,
          ),
        );
      }
    } catch (e) {
      if (!isCancelled()) {
        snackbar.show({
          severity: SnackbarSeverityKind.ERROR,
          text: getErrorMessage(e),
        });
      }
    }
  }, [location.pathname]);

  return (
    <AppBar>
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-52 z-50">
        {notifications.length > 0 && !showNotificationModal && (
          <NotificationAlertBadge
            className="w-full"
            notificationCount={notifications.length}
            onClick={() => setShowNotificationModal(true)}
          />
        )}
        {showNotificationModal && (
          <ActivityCollaboratorNotificationModal
            className="absolute right-0"
            notificationList={notifications}
            onClose={handleCloseNotificationModal}
          />
        )}
      </div>
      <Navigation />
    </AppBar>
  );
};
