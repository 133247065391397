import React, { ComponentPropsWithoutRef, FC } from 'react';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';

interface Props extends ComponentPropsWithoutRef<'div'> {
  heading: string;
  tooltipText?: string;
}

export const ActivityDetailSegment: FC<Props> = ({
  heading,
  tooltipText,
  children,
  ...props
}) => (
  <div {...props}>
    <PageSegmentHeading headingClassname="flex items-center">
      {heading}
      {tooltipText && (
        <InfoTooltip
          text={<p className="leading-normal">{tooltipText}</p>}
          className="print:hidden ml-2"
        />
      )}
    </PageSegmentHeading>
    <div>{children}</div>
  </div>
);
