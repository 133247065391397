import { useState, useCallback, useEffect } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { removeDuplicateRecordsFromArray } from 'shared/lib/utils/removeDuplicateRecordsFromArray';
import { FillableActivityCommentsAndFilesFormFields } from 'types/FillableActivityCommentsAndFilesFormFields';

const defaultActivityDeliverableFields: FillableActivityCommentsAndFilesFormFields = {
  comments: null,
  showCommentsInQuarterlyReport: false,
  files: [],
};

export interface ActivityCommentsAndFilesFormReturnValues {
  activityCommentsAndFilesFields: FillableActivityCommentsAndFilesFormFields;
  onCommentsChanged(commments: string): void;
  onShowCommentsInQuarterlyReportChanged(showComments: boolean): void;
  onFilesChanged(files: File[]): void;
  onFileRemoved(file: File): void;
}

export function useActivityCommentsAndFilesForm(
  activity?: Activity,
): ActivityCommentsAndFilesFormReturnValues {
  const [
    activityCommentsAndFilesFields,
    setActivityCommentsAndFilesFields,
  ] = useState<FillableActivityCommentsAndFilesFormFields>(
    defaultActivityDeliverableFields,
  );

  const onCommentsChanged = useCallback((comments: string) => {
    setActivityCommentsAndFilesFields((prevState) => ({
      ...prevState,
      comments,
    }));
  }, []);

  const onShowCommentsInQuarterlyReportChanged = useCallback(
    (showCommentsInQuarterlyReport: boolean) => {
      setActivityCommentsAndFilesFields((prevState) => ({
        ...prevState,
        showCommentsInQuarterlyReport,
      }));
    },
    [],
  );

  const onFilesChanged = useCallback(
    (files: File[]) => {
      setActivityCommentsAndFilesFields((prevState) => ({
        ...prevState,
        files: removeDuplicateRecordsFromArray({
          data: [...activityCommentsAndFilesFields.files, ...files],
          key: 'name',
        }),
      }));
    },
    [activityCommentsAndFilesFields.files],
  );

  const onFileRemoved = useCallback((file: File) => {
    setActivityCommentsAndFilesFields((prevState) => ({
      ...prevState,
      files: prevState.files.filter(
        (currentFile) => currentFile.name !== file.name,
      ),
    }));
  }, []);

  useEffect(() => {
    if (activity) {
      setActivityCommentsAndFilesFields({
        comments: activity.comments,
        showCommentsInQuarterlyReport: activity.showCommentsInQuarterlyReport,
        files: [],
      });
    }
  }, [activity]);

  return {
    activityCommentsAndFilesFields,
    onCommentsChanged,
    onShowCommentsInQuarterlyReportChanged,
    onFilesChanged,
    onFileRemoved,
  };
}
