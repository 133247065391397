import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { Activity } from 'shared/lib/types/Activity';
import { formatActivityAreaInvolved } from 'shared/lib/utils/formatActivityAreaInvolved';

export interface ActivityReportCommentsProps
  extends ComponentPropsWithoutRef<'div'> {
  activityList: Activity[];
}

export const ActivityReportComments: FC<ActivityReportCommentsProps> = ({
  activityList,
  ...rest
}) => {
  const filteredActivities = useMemo(
    () =>
      activityList.filter(
        (activity) =>
          activity.showCommentsInQuarterlyReport && activity.comments,
      ),
    [activityList],
  );
  return (
    <div {...rest}>
      {filteredActivities.length > 0
        ? filteredActivities.map((activity) => (
            <div key={activity.id}>
              <b>{formatActivityAreaInvolved(activity)}:</b> {activity.comments}
            </div>
          ))
        : '(no comments yet)'}
    </div>
  );
};
