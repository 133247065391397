import { apiAxios } from 'api/apiAxios';
import { FillableActivityFormFields } from 'types/FillableActivityFormFields';

export async function updateActivity(
  activityId: number,
  request: FillableActivityFormFields,
) {
  const response = await apiAxios.patch(`/activity/${activityId}`, request);
  return response.data;
}
