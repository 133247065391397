import React, { ComponentPropsWithoutRef, FC } from 'react';
import { ButtonGroup } from 'components/ButtonGroup/ButtonGroup';

interface Props extends ComponentPropsWithoutRef<'div'> {
  prevLabel?: string;
  nextLabel?: string;
  onNext(): void;
  onPrevious(): void;
}

export const StepperButtonGroup: FC<Props> = ({
  prevLabel,
  nextLabel,
  onNext,
  onPrevious,
  className = '',
  ...props
}) => (
  <ButtonGroup
    className={`border border-tnf-green ml-auto mt-24 mb-4 ${className}`}
    {...props}
  >
    <button onClick={onPrevious} className="text-tnf-green">
      {prevLabel ?? 'Back'}
    </button>
    <button onClick={onNext} className="bg-tnf-green text-white">
      {nextLabel ?? 'Next'}
    </button>
  </ButtonGroup>
);
