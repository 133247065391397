import React, { FC, useCallback } from 'react';
import { ActivityCounty } from 'shared/lib/types/ActivityCounty';
import {
  DropdownCheckboxInput,
  DropdownCheckboxInputProps,
} from 'components/DropdownCheckboxInput/DropdownCheckboxInput';

interface Props
  extends Omit<
    DropdownCheckboxInputProps,
    'label' | 'placeholder' | 'onChange'
  > {
  onChange(counties: Pick<ActivityCounty, 'countyId'>[]): void;
}

export const ActivityCountyInput: FC<Props> = ({ onChange, ...props }) => {
  const handleCountyDropdownChange = useCallback(
    (countyIds: number[]) => {
      onChange(countyIds.map((countyId) => ({ countyId })));
    },
    [onChange],
  );
  return (
    <DropdownCheckboxInput
      label="Counties"
      placeholder="Choose Counties"
      onChange={handleCountyDropdownChange}
      {...props}
    />
  );
};
