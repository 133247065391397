import React, { FC } from 'react';
import { inactiveMapIcon } from 'assets/images';
import { activeMapIcon } from 'assets/images';
import { NavigationItem } from 'components/NavigationItem/NavigationItem';
import { useHistory, useLocation } from 'react-router-dom';
import { homeRoute } from 'shared/lib/constants/routes/commonRoutes';

export const MapNavigationItem: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const active = location.pathname === homeRoute;

  return (
    <NavigationItem
      imageSrc={active ? activeMapIcon : inactiveMapIcon}
      imageAlt="Map Icon"
      label="Map"
      onClick={() => {
        history.push(homeRoute);
      }}
    />
  );
};
