import { apiAxios } from 'api/apiAxios';
import { ReportSummary } from 'shared/lib/types/ReportSummary';

/**
 * Create if one doesn't exist for this user+year+quarter.
 * Update if one does exist for this user+year+quarter.
 * Delete (or don't create) and return null if summary text is empty.
 */
export async function saveReportSummary(
  reportSummary: Pick<ReportSummary, 'quarter1Year' | 'quarter' | 'summary'>,
): Promise<ReportSummary | null> {
  const res = await apiAxios.post('/reportSummaries', reportSummary);
  return res?.data.reportSummary ?? null;
}
