import { apiAxios } from 'api/apiAxios';
import { User } from 'shared/lib/types/User';

interface Request extends Omit<User, 'role' | 'region'> {
  region?: string;
  role?: string;
}

export async function updateTnfUser(userId: number, request: Request) {
  const response = await apiAxios.patch(`/tnf/${userId}`, request);
  return response.data;
}
