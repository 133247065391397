import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from 'components/Row/Row';

export const ButtonGroup: FC<ComponentPropsWithoutRef<'div'>> = ({
  children,
  className = '',
  ...rest
}) => (
  <Row className={`btn-group ${className}`} {...rest}>
    {children}
  </Row>
);
