import React, { FC, useCallback, useState } from 'react';
import { UserRole } from 'shared/lib/constants/user/UserRole';
import { User } from 'shared/lib/types/User';
import { isSuperAdmin } from 'shared/lib/utils/isSuperAdmin';
import { Row } from 'components/Row/Row';
import { UserProfileForm } from 'components/UserProfileForm/UserProfileForm';
import { PageSegmentHeading } from 'components/PageSegmentHeading/PageSegmentHeading';
import { PageSegmentHeadingIcons } from 'enums/PageSegmentHeadingIcons';
import { useUserContext } from 'contexts/userContext';
import { updateTnfUser } from 'api/tnf/updateTnfUser';
import { updatePreEtsSpecialistUser } from 'api/pre-ets-specialist/updatePreEtsSpecialistUser';
import { updateSuperAdminUser } from 'api/admin/updateSuperAdminUser';
import getErrorMessage from 'utils/getErrorMessage';
import { useSnackbarContext } from 'contexts/snackbarContext';
import { SnackbarSeverityKind } from 'enums/SnackbarSeverityKind';
import { FillableUserFormProps } from 'types/FillableUserFormProps';

export const ProfileManagementPage: FC = () => {
  const [editingProfile, setEditingProfile] = useState(false);
  const { user, refreshUser } = useUserContext();
  const { snackbar } = useSnackbarContext();

  const handleEditIconClick = useCallback(() => {
    setEditingProfile(true);
  }, []);

  const handleSubmit = useCallback(
    async (userData: FillableUserFormProps) => {
      if (user) {
        snackbar.show({ severity: SnackbarSeverityKind.SPINNER });
        const { firstName, lastName, email } = userData;
        const adminRequestData = userData as User;
        const nonAdminRequestData = { firstName, lastName, email } as Omit<
          User,
          'role' | 'region'
        >;

        try {
          if (user.role === UserRole.TNF) {
            await updateTnfUser(user.id, nonAdminRequestData);
          }

          if (user.role === UserRole.PRE_ETS_SUPPORT_SPECIALIST) {
            await updatePreEtsSpecialistUser(user.id, nonAdminRequestData);
          }

          if (isSuperAdmin(user)) {
            await updateSuperAdminUser(user.id, adminRequestData);
          }
          snackbar.show({
            severity: SnackbarSeverityKind.SUCCESS,
            text: 'Profile updated successfuly!',
          });
          refreshUser();
        } catch (e) {
          snackbar.show({
            severity: SnackbarSeverityKind.ERROR,
            text: getErrorMessage(e),
          });
        } finally {
          setEditingProfile(false);
        }
      }
    },
    [refreshUser, snackbar, user],
  );

  const pageSegementHeadingIcon = !editingProfile
    ? PageSegmentHeadingIcons.PENCIL
    : undefined;

  return (
    <Row className="w-100 justify-between mt-34">
      <div className="text-2xl font-sf-pro-light text-gray-900">My Profile</div>
      <div className="w-8/12">
        <PageSegmentHeading
          icon={pageSegementHeadingIcon}
          onIconClick={handleEditIconClick}
        >
          {user?.firstName} {user?.lastName}
        </PageSegmentHeading>
        <UserProfileForm
          user={user}
          onSubmit={handleSubmit}
          readOnly={!editingProfile}
        />
      </div>
    </Row>
  );
};
